// Dans Messaging.jsx
import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom'; // Ajout de cet import
import { Helmet } from 'react-helmet';
import { AppContext } from '../../contexts/AppContext';
import { useMessage } from '../../contexts/MessageContext';
import ConversationList from '../../components/messaging/ConversationList';
import Chat from '../../components/messaging/Chat';
import NewConversationModal from '../../components/messaging/NewConversationModal';
import "./Messaging.css";
import { BASE_URL } from '../../utils/constants';

const Messaging = () => {
  const [isNewConversationModalOpen, setIsNewConversationModalOpen] = useState(false);
  const [searchTermForModal, setSearchTermForModal] = useState(''); // Ajout de cet état
  const [isMessagingMounted, setIsMessagingMounted] = useState(false);
  const [activeConversation, setActiveConversation] = useState(null);
  const { setActivePageForHeader, connectedUser } = useContext(AppContext);
  const { socket, messages, setMessages } = useMessage();
  const [conversations, setConversations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const activeConversationId = location.state?.activeConversationId;
  const [isMounted, setIsMounted] = useState(false);


  useEffect(() => {
    if(connectedUser) {
      if (!connectedUser.agree_terms) {
        // redirect to dashboard
        window.location.href = `${process.env.NODE_ENV === 'production' ? BASE_URL : 'http://localhost:3000'}/accept-terms`;
      }
    }
}, [connectedUser]);
  
  
    useEffect(() => {
            window.scrollTo(0, 0);
            document.body.setAttribute('data-page', 'messaging');
            setActivePageForHeader("messaging");
        
            return () => {
              document.body.removeAttribute('data-page');
              setActivePageForHeader("");
            }
          }, [setActivePageForHeader]);

  // Fonction pour récupérer les informations utilisateur de manière sécurisée
  const getUserInfo = () => {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    
    if (!token || !userId) {
      throw new Error('Informations de connexion manquantes');
    }

    return { token, userId };
  };

  useEffect(() => {
    setIsMessagingMounted(true);
    
    document.body.setAttribute('data-page', 'messaging');
    setActivePageForHeader("messaging");

    // Fetch conversations
    const fetchConversations = async () => {
        try {
          setIsLoading(true);
          const { token } = getUserInfo();
      
          // Ajouter un petit délai pour laisser le temps à la conversation d'être créée
          await new Promise(resolve => setTimeout(resolve, 500));
      
          const response = await fetch('/api/messages/conversations', {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          
          if (!response.ok) {
            throw new Error(`HTTP error: ${response.status}`);
          }
          
          const data = await response.json();
          console.log("Conversations récupérées:", data); // Ajout de log pour debug
          setConversations(Array.isArray(data) ? data : []);
      
          // Si on a un activeConversationId, sélectionner automatiquement la conversation
          if (activeConversationId) {
            console.log("Recherche de la conversation:", activeConversationId); // Log pour debug
            const activeConv = data.find(conv => conv.id === activeConversationId);
            if (activeConv) {
              setActiveConversation(activeConv);
            }
          }
          
          setError(null);
        } catch (error) {
          console.error('Erreur lors de la récupération des conversations:', error);
          setError(error.message);
        } finally {
          setIsLoading(false);
        }
      };

    fetchConversations();  // <-- Appel direct de la fonction

    return () => {
        setIsMessagingMounted(false);
        document.body.removeAttribute('data-page');
        setActivePageForHeader("");
    }
}, [setActivePageForHeader, activeConversationId]); 

  // Dans Messaging.jsx
  useEffect(() => {
    if (socket && activeConversation) {
      // Récupérer les messages de la conversation
      const fetchMessages = async () => {
        try {
          const { token } = getUserInfo();
          const response = await fetch(`/api/messages/conversations/${activeConversation.id}/messages`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          
          const data = await response.json();
          setMessages(Array.isArray(data) ? data : []);
        } catch (error) {
          console.error('Erreur lors de la récupération des messages:', error);
          setMessages([]);
        }
      };

      fetchMessages();
      socket.emit('join-conversation', activeConversation.id);

      return () => {
        socket.emit('leave-conversation', activeConversation.id);
      };
    }
  }, [socket, activeConversation]);

  const handleSelectConversation = async (conversation) => {
    setActiveConversation(conversation);
    setMessages([]); // Vider les messages précédents
  };

  /* const handleSendMessage = (content) => {
    if (socket && activeConversation) {
      try {
        const { userId } = getUserInfo();
        
        const messageData = {
          conversation_id: activeConversation.id,
          sender_id: userId,
          content: content
        };

        socket.emit('send-message', messageData);
      } catch (error) {
        console.error('Erreur lors de l\'envoi du message:', error);
        // Gérer l'erreur (peut-être afficher un message à l'utilisateur)
      }
    }
  }; */

  const handleBack = () => {
    setActiveConversation(null);
  };

  const handleNewConversation = (searchTerm = '') => {
    setSearchTermForModal(searchTerm);
    setIsNewConversationModalOpen(true);
  };

  const handleCreateConversation = async (selectedUser) => {
    try {
      const { token } = getUserInfo();
      
      // Vérifier d'abord si une conversation existe déjà
      const existingConversation = conversations.find(conv => {
        // Vérifier si le nom de la conversation correspond à l'utilisateur sélectionné
        const matchesName = conv.name === (selectedUser.business_name || `${selectedUser.first_name} ${selectedUser.last_name}`);
        return matchesName;
      });
  
      if (existingConversation) {
        // Si la conversation existe, on l'active simplement
        console.log('Conversation existante trouvée:', existingConversation);
        setActiveConversation(existingConversation);
        setIsNewConversationModalOpen(false);
        return;
      }
  
      // Si aucune conversation n'existe, en créer une nouvelle
      const createResponse = await fetch('/api/messages/conversations', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          title: selectedUser.business_name || `${selectedUser.first_name} ${selectedUser.last_name}`,
          participants: [selectedUser.id]
        })
      });
  
      if (!createResponse.ok) {
        throw new Error('Erreur lors de la création de la conversation');
      }
  
      const newConversation = await createResponse.json();
      setConversations(prev => [newConversation, ...prev]);
      setActiveConversation(newConversation);
      setIsNewConversationModalOpen(false);
  
    } catch (error) {
      console.error('Erreur lors de la création de la conversation:', error);
      alert('Erreur lors de la création de la conversation');
    }
  };

  // Gestion des états de chargement et d'erreur
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-gray-500">Chargement des conversations...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen text-red-500">
        <div className="text-center">
          <p className="text-xl mb-4">Impossible de charger les conversations</p>
          <p className="text-sm text-gray-600">{error}</p>
          <button 
            onClick={() => window.location.reload()}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Réessayer
          </button>
        </div>
      </div>
    );
  }

  // Si aucune conversation n'existe
  /* if (conversations.length === 0) {
    return (
      <div className="flex justify-center items-center h-screen text-gray-500">
        <div className="text-center">
          <p className="text-xl mb-4">Aucune conversation</p>
          <p className="text-sm">Vous n'avez pas encore de conversations</p>
        </div>
      </div>
    );
  } */

  return (
    <>
      <Helmet>
        <title>Messagerie | PlaniBeauty</title>
        <meta name="description" content="Gérez vos conversations avec vos contacts PlaniBeauty." />
        <link rel="canonical" href="https://www.planibeauty.fr/messaging" />
        <meta property="og:title" content="Messagerie PlaniBeauty" />
        <meta property="og:description" content="Restez en contact avec vos contacts PlaniBeauty." />
      </Helmet>
      <div className={`messaging ${isMessagingMounted ? "mounted" : ""}`}>
        <div className={`messaging__container ${activeConversation ? 'conversation-open' : ''}`}>
        <div className="messaging__sidebar">
                <ConversationList
                    conversations={conversations}
                    setConversations={setConversations}
                    activeConversation={activeConversation}
                    setActiveConversation={setActiveConversation}
                    onSelectConversation={handleSelectConversation}
                    onNewConversation={handleNewConversation} // S'assurer que cette prop est bien passée
                />
            </div>
          <div className="messaging__main">
          <Chat 
            conversation={activeConversation}  // ✅ Correct
            messages={messages}
            setMessages={setMessages}
            onBack={handleBack}
          />
          </div>
        </div>
      </div>
      <NewConversationModal 
        isOpen={isNewConversationModalOpen}
        onClose={() => setIsNewConversationModalOpen(false)}
        onCreateConversation={handleCreateConversation}
        searchTerm={searchTermForModal}
      />
    </>
  );
};

export default Messaging;