import "./Users.css";

import { useContext, useEffect, useState } from "react";
import { dashboardContext } from "../Dashboard";
import UserData from "../../../components/dashboard/UserData";
import MySpinner from "../../../components/loader/Spinner";

const Users = () => {
    const [loading, setLoading] = useState(true);
    const {datas, isLoading, activePage, setActivePage } = useContext(dashboardContext);
    const [filter, setFilter] = useState(activePage);
    
    const [filteredData, setFilteredData] = useState(datas);
    const [searchText, setSearchText] = useState("");

    const handleFilterChange = (e) => {
        e.preventDefault();
        setFilter(e.target.value);
        setActivePage(e.target.value);
    }

    useEffect(() => {
        setLoading(true);  // On commence par afficher le chargement
        // Simulation d'une récupération des données avec un délai
        setTimeout(() => {
            setLoading(false);  // Une fois les données chargées, on désactive le chargement
        }, 100);  // Temps de chargement simulé
    }, [datas, isLoading]);
    
    useEffect(() => {
        // Filtrage basé sur la recherche et les filtres
        let data = datas;

        if (searchText) {
            data = data.filter((item) =>
                Object.values(item)
                    .join(" ")
                    .toLowerCase()
                    .includes(searchText.toLowerCase())
            );
        }

        setFilteredData(data);
    }, [datas, searchText]);

    return (
        <main className="min-h-[72vh]">
            {/* Barre de recherche */}
            <div className="flex justify-between items-center p-3">

                <input
                    type="text"
                    placeholder="Rechercher par mots clés"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    className="bg-gray-200 border border-gray-200 rounded p-2 min-w-80"
                />
                {['all', 'indépendant', 'établissement'].includes(activePage) &&
                    <select value={filter} onChange={handleFilterChange} className="py-2 px-3 rounded">
                        <option value="all">Tout</option>
                        <option value="indépendant">Indépendant</option>
                        <option value="établissement">Etablissement</option>
                    </select>
                }
            </div>
            
            {loading ? (
                <div className="w-full">
                    <MySpinner height={"60vh"} />
                </div>
            ) : (
                (Array.isArray(filteredData)) &&
                <UserData datas={filteredData} />
            )}
        </main>
    );
};

export default Users;
