import React, { useEffect, useState } from 'react'
import trendingApi from '../../api/trending.api'
import Swal from 'sweetalert2'
import RenderStars from './RenderStars'

const formatDate = (date) => {
    return `Semaine du ${new Date(date).toLocaleDateString("fr-FR", {
        day: "2-digit",
        month: "long",
        year: "numeric"
    })}`
}

const HomeSetting = () => {
    const [loading, setLoading] = useState(true)
    const [trending, setTrending] = useState([])
    const [trendingData, setTrendingData] = useState({})

    useEffect(() => {
        const fetchTrending = async () => {
            try {
                const result = await trendingApi.getAllTrending()
                const uniqueWeeks = [...new Set(result.data.map(r => r.week_start))]

                uniqueWeeks.sort((a, b) => new Date(b) - new Date(a))

                setTrending(uniqueWeeks)

                const weekData = {}
                for (let week of uniqueWeeks) {
                    try {
                        const res = await trendingApi.getTrendingByWeek(week)
                        weekData[week] = res.data
                    } catch (err) {
                        console.error(`Erreur lors de la récupération des données pour la semaine ${week}:`, err)
                        weekData[week] = []
                    }
                }

                setTrendingData(weekData)
                setLoading(false)
            } catch (error) {
                Swal.fire("Erreur", error.message, "error")
                setLoading(false)
            }
        }

        fetchTrending()
    }, [])

    if (loading) {
        return <div>Chargement...</div>
    }

    return (
        <div className="px-4">
            <h1 className="text-2xl">Historique des tendances</h1>

            {trending.length > 0 && (
                trending.map((week, key) => (
                    <section key={key} >
                        <h3 className="text-lg border-b-4 mt-4 pb-2">{formatDate(week)}</h3>
                        <section className="flex items-center flex-wrap gap-4 mt-4 justify-center">
                            {trendingData[week]?.length > 0 ? (
                                trendingData[week].map((tbw, index) => (
                                    <div
                                        key={index}
                                        className="flex flex-col gap-2 px-3 py-3 w-1/6 border shadow rounded"
                                    >
                                        {tbw.business_name}

                                        <RenderStars star_count={tbw.average_rating} />
                                    </div>
                                ))
                            ) : (
                                <div>Aucune donnée disponible pour cette semaine.</div>
                            )}
                        </section>
                    </section>
                ))
            )}
        </div>
    )
}

export default HomeSetting
