import { Table } from "react-bootstrap";
import testeurApi from "../../../api/testeur.api";
import MySpinner from "../../../components/loader/Spinner";
import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import AddTesteurModal from "./AddTesteurModal";
import Swal from "sweetalert2";
import { showSuccessMessage } from "../../../utils/messageHelper";

const AllTesteur = () => {

    const [loading, setLoading] = useState(true);
    const [testeurs, setTesteurs] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [reloadTrigger, setReloadTrigger] = useState(false);

    const fetchTesteurs = async () => {
        setLoading(true);
        const res = await testeurApi.getAll(localStorage.getItem('token'));
        setTesteurs(res.data || []);
        setLoading(false);
    }

    useEffect(() => {
        fetchTesteurs();
    }, []);

    useEffect(() => {
        fetchTesteurs();
    }, [reloadTrigger]);

    const handleDeleteTesteur = async (id) => {
        Swal.fire({
            icon: "question",
            title: "Voulez vous supprimer le testeur?",
            showCancelButton: true, 
            showConfirmButton: true,
            confirmButtonText: "Oui, confirmer",
            cancelButtonText: "Annuler",
            confirmButtonColor: "#bd193b"
        }).then(async (result) => {
            if (result.isConfirmed) {
                await testeurApi.delete(localStorage.getItem('token'), id);
                showSuccessMessage("Testeur supprimé avec succès!");
                setReloadTrigger(prev => !prev);
            }
        });
    }

    return (
        <main className="min-h-[72vh]">
            {loading ? (
                <div className="w-full">
                    <MySpinner height={"60vh"} />
                </div>
            ) : (
                <div>
                    <div className="p-6 bg-gray-100 rounded-lg">
                        <div className="flex justify-between items-center gap-2">
                            <h2 className="text-2xl font-bold text-gray-800 mb-6">Liste des testeurs</h2>
                            <button
                                onClick={() => setShowModal(true)}
                                className="px-4 py-2 text-white bg-[#bd193b] flex items-center gap-2 rounded shadow"
                            >
                                <FaPlus />
                                Ajouter un testeur
                            </button>
                        </div>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Nom et prénoms</th>
                                    <th>Email</th>
                                    <th>Téléphone</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {testeurs.length > 0 && testeurs.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.id}</td>
                                        <td>{item.first_name}{' '}{item.last_name}</td>
                                        <td>{item.email}</td>
                                        <td>{item.phone}</td>
                                        <td><button className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600" onClick={() => handleDeleteTesteur(item.id)}>Supprimer</button></td>
                                    </tr>
                                ))}

                                {testeurs.length === 0 && <tr><td colSpan={5} className="text-center">Aucun enregistrement à afficher!</td></tr>}
                            </tbody>
                        </Table>
                    </div>
                    {/* Modal pour créer ou modifier une offre */}
                    {showModal && 
                    <AddTesteurModal showModal={showModal} setShowModal={setShowModal} testeurs={testeurs} setReloadTrigger={setReloadTrigger} /> }
                </div>
            )}
        </main>
    );
}

export default AllTesteur;