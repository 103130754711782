// utils/colorUtils.js
export const generateColorFromString = (str) => {
    const colors = [
      '#1E293B', '#1E3A8A', '#065F46', '#312E81', '#831843',
      '#3730A3', '#9A3412', '#064E3B', '#831843', '#581C87',
    ];
    
    let hash = 0;
    if (!str) return colors[0];
    
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    
    return colors[Math.abs(hash) % colors.length];
  };
  