// payment.api.js
import { API_URL } from "../utils/constants";

const paymentApi = {
    createPaymentIntent: async (token, paymentData) => {
        try {
            const response = await fetch(`${API_URL}/payment/create-payment-intent`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(paymentData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de la création du paiement');
            }

            return await response.json();
        } catch (error) {
            console.error('Erreur dans createPaymentIntent:', error);
            throw error;
        }
    },

    saveAppointment: async (token, appointmentData) => {
        try {
            const response = await fetch(`${API_URL}/payment/save-appointment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(appointmentData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de la sauvegarde du rendez-vous');
            }

            return await response.json();
        } catch (error) {
            console.error('Erreur dans saveAppointment:', error);
            throw error;
        }
    },

    capturePayment: async (token, paymentIntentId, appointment_id) => {
        try {
            const response = await fetch(`${API_URL}/payment/capture-payment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ paymentIntentId: paymentIntentId, appointment_id: appointment_id })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de la capture du paiement');
            }

            return await response.json();
        } catch (error) {
            console.error('Erreur dans capturePayment:', error);
            throw error;
        }
    },

    cancelPayment: async (token, paymentIntentId, appointment_id) => {
        try {
            const response = await fetch(`${API_URL}/payment/cancel-payment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ paymentIntentId: paymentIntentId, appointment_id: appointment_id })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de l\'annulation du paiement');
            }

            return await response.json();
        } catch (error) {
            console.error('Erreur dans cancelPayment:', error);
            throw error;
        }
    }

    // refundPayment: async (token, data) => {
    //     try {
    //         const response = await fetch(`${API_URL}/payment/refund-payment`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': `Bearer ${token}`
    //             },
    //             body: JSON.stringify(data)
    //         });

    //         if (!response.ok) {
    //             const errorData = await response.json();
    //             throw new Error(errorData.message || 'Erreur lors du remboursement!');
    //         }

    //         return await response.json();
    //     } catch (error) {
    //         console.error('Erreur durant le remboursement:', error);
    //         throw error;
    //     }
    // },

}

export default paymentApi;