import React from "react"
import { format } from "date-fns";
import { fr } from "date-fns/locale";

const AbonnementStatus = ({ profileData, setShowHistoryModal, loading, abonnement, abonnementAuto, handleChangeAbonnementAutoStatus }) => {
    return <React.Fragment>
        {!profileData.is_first_login && <div className="flex justify-end gap-2">
            <button
                onClick={() => setShowHistoryModal(true)}
                className="mt-4 border border-gray-300 bg-gray-50 px-4 py-2 rounded hover:bg-gray-300"
            >
                Voir les historiques d'abonnement
            </button>
        </div>}
        <div className="mt-4">
            <h5>Etat de votre abonnement</h5>
            {loading ? (
                <div className="flex justify-center items-center">
                    <div className="loader border-t-4 border-blue-500 w-8 h-8 rounded-full animate-spin"></div>
                </div>
            ) : abonnement ? (
                <div>
                    <h6>Offre actuel : {abonnement.offre_name} pour {abonnement.price_per_month} € / mois</h6>
                    {(new Date(abonnement.end_date) >= new Date()) ?
                        <div className="flex gap-2 justify-between items-center text-green-500">
                            <div>Abonnement actif jusqu'au {format(new Date(abonnement.end_date), 'd MMMM yyyy', { locale: fr })}, votre abonnement sera renouveller automatiquement!</div>
                            {/*<button
                                    onClick={handleChangeOfferClick}
                                    className='mt-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600'
                                >Changer d'offre</button>*/}
                            <div>
                                {abonnementAuto ?
                                    <button onClick={() => handleChangeAbonnementAutoStatus(false)} className='btn btn-danger'>Annuler l'abonnement automatique</button> :
                                    <button onClick={() => handleChangeAbonnementAutoStatus(true)} className='btn btn-primary'>Activer l'abonnement automatique</button>
                                }
                            </div>
                        </div>
                        :
                        <p className="text-red-500">Votre abonnement a expiré le {format(new Date(abonnement.end_date), 'd MMMM yyyy', { locale: fr })}</p>
                    }
                </div>
            ) : (
                <p className="text-red-500">Aucun abonnement actif</p>
            )}
        </div>
    </React.Fragment>
}


export default AbonnementStatus;