// AppContext.jsx
import React, { createContext, useEffect, useState } from 'react';
// import { io } from "socket.io-client";
import userApi from '../api/user.api';
import { tokenValid } from '../utils/functions';
import Loader from '../components/loader/Loader';
import notificationApi from '../api/notification.api';




export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);   
    const [activePageForHeader, setActivePageForHeader] = useState('home')

    const token = localStorage.getItem('token');

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const [connectedUser, setConnectedUser] = useState(null);
    const [notificationChangeTrigger, setNotificationChangeTrigger] = useState(false);


    const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);

    const fetchUser = async () => {
        let user = await userApi.getProfile(token);
        setConnectedUser(user);
    };

    const fetchUnreadNotificationCount = async () => {
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');
        const count = await notificationApi.countBeauticianUnreadNotification(userId, token);
        setUnreadNotificationCount(count.data);
    }

    useEffect(() => {
        const checkToken = async () => {
            setIsLoading(true);

            const valid = await tokenValid(token);
            setIsLoggedIn(valid);
            if (valid) {
                await fetchUser();
                await fetchUnreadNotificationCount();
            }
            setIsLoading(false);
        };

        checkToken();

    }, []);

    useEffect(() => {
        fetchUnreadNotificationCount();
    }, [notificationChangeTrigger]);

    // Valeur du contexte
    const contextValue = {
        isLoading,
        activePageForHeader,
        setActivePageForHeader,
        isLoggedIn,
        setIsLoggedIn,
        connectedUser,
        setConnectedUser,
        unreadNotificationCount,
        setNotificationChangeTrigger
    };

    return (
        <AppContext.Provider value={contextValue}>
            <Loader isLoading={isLoading} />
            {!isLoading && children}
        </AppContext.Provider>
    );
};

export default AppContextProvider;