import React, { useContext, useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import {
  Calendar as CalendarIcon,
  Clock,
} from 'lucide-react';
import './BeauticianAgenda.css';
import ShowInfoClientModal from './agenda/ShowInfoClientModal';
import appointmentApi from '../../../api/appointement.api';
import { BeauticianPortalContext } from '../../../context/BeauticianPortalContext';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import Swal from 'sweetalert2';
import { sendEmailBookingStatusToClient } from '../../../utils/mailServices';
import ModernCalendar from './agenda/ModernCalendar';
import { useSearchParams } from 'react-router-dom';
import { formatTime, getPaymentTypeString } from '../../../utils/functions';
import PlanifyAppointmentModal from './agenda/PlanifyAppointmentModal';
import paymentApi from '../../../api/payment.api';
import { showErrorMessage } from '../../../utils/messageHelper';
import beauticianApi from '../../../api/beautician.api';

const BeauticianAgenda = () => {
  const [activePage, setActivePage] = useState('my_agenda');
  const [confirmedAppointment, setConfirmedAppointment] = useState([]);
  const [pendingAppointment, setPendingAppointment] = useState([]);
  const { profileData, isParentLoading } = useContext(BeauticianPortalContext);
  const [changeTrigger, setChangeTrigger] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPlanifyAppointmentModal, setShowPlanifyAppointmentModal] = useState(false);

  const [activeAppointment, setActiveAppointment] = useState({});
  const [showAppointmentModification, setShowAppointmentModification] = useState(false);

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showEventModal, setShowEventModal] = useState(false);


  const handleEventClick = (event) => {
    const formattedEvent = {
      extendedProps: {
        first_name: event.first_name,
        last_name: event.last_name,
        email: event.email,
        phone: event.phone,
        services: event.services,
        payment_type: event.payment_type,
        total_prices: event.total_prices,
        payed: event.payed,
        user_id: event.user_id,
        appointment_date: new Date(event.appointment_date),
        is_planified_appointment: event.is_planified_appointment,
        appointment_number: event.appointment_number,
        old_appointment_id: event.appointment_id,
        appointment_id: event.id, // Ajout important de l'ID
        payment_intent: event.payment_intent, // Ajout important
        start_time: event.start_time,
        end_time: event.end_time
      },
      start: new Date(event.appointment_date),
      end: new Date(event.appointment_date)
    };
      
    setSelectedEvent(formattedEvent);
    setShowEventModal(true);
  };

  useEffect(() => {
    if (profileData) {
      fetchValidAppointment();
      let page = searchParams.get('page');
      if (page && page === 'pending') {
        setActivePage('booking_pending');
      }
    }
  }, [isParentLoading, changeTrigger]);

  // Ajoutez cette fonction avec les autres states au début du composant
  const handleCloseEventModal = () => {
    setShowEventModal(false);
    setSelectedEvent(null);
  };


  const fetchValidAppointment = async () => {
    try {
      const result = await appointmentApi.listAppointmentsValidByBeauticianJoinUserAndServices(
        localStorage.getItem('token'), 
        profileData.beautician_id
      );
      
      const confirmedAppt = [];
      const pendingAppt = [];
      
      if (result && Array.isArray(result)) {
        result.forEach((element) => {
          // Ne pas inclure les rendez-vous annulés
          if (element.status === "cancelled") return;
          
          if (element.status === "pending") {
            pendingAppt.push(element);
          } else if (element.status === "confirmed") {
            confirmedAppt.push(element);
          }
        });
      }
      
      const formatedConfirmedAppt = formatConfirmedAppointment(confirmedAppt);
      setConfirmedAppointment(formatedConfirmedAppt);
      setPendingAppointment(pendingAppt);
    } catch (error) {
      console.error("Erreur lors de la récupération des rendez-vous:", error);
    }
  };

  const formatConfirmedAppointment = (confirmedAppt) => {
    const formatedConfirmedAppt = [];
    confirmedAppt.forEach((element) => {
      let start = new Date(element.appointment_date).setHours(element.start_time.split(":")[0], element.start_time.split(":")[1]);
      let end = new Date(element.appointment_date).setHours(element.end_time.split(":")[0], element.end_time.split(":")[1]);
      const formatedElement = {
        id: element.id,
        appointment_id: element.id,
        title: `${element.first_name}`,
        user_id: element.user_id,
        appointment_date: element.appointment_date,
        start_time: element.start_time,
        end_time: element.end_time,
        start: start,
        end: end,
        status: element.status,
        total_prices: element.total_prices,
        payed: element.payed,
        payment_type: element.payment_type,
        payment_intent: element.payment_intent,
        first_name: element.first_name,
        last_name: element.last_name,
        email: element.email,
        phone: element.phone,
        services: element.services,
        is_planified_appointment: element.is_planified_appointment,
        appointment_number: element.appointment_number,
        old_appointment_id: element.appointment_id // l'id du rendez-vous parent si c'est un rendez vous planifié
      }
      formatedConfirmedAppt.push(formatedElement);
    })
    return formatedConfirmedAppt;
  }

  const handleConfirmAppointment = (appointment) => {
    setActiveAppointment(appointment);
    setShowAppointmentModification(true);
  }

  const handleRejectAppointment = (appointment) => {
    Swal.fire({
      showCancelButton: true,
      showConfirmButton: true,
      title: 'Voulez vous rejeter ce rendez-vous?',
      text: "L'action est irreversible",
      confirmButtonText: "Oui, rejeter",
      cancelButtonText: "Non",
      icon: "question"
    }).then(async (result) => {
      if (result.isConfirmed) {

        //si payment par carte bancaire, on cancel le payement
        if (appointment.payment_type !== "onsite") {

          // Conversion de la date de création en objet Date
          const createdAt = new Date(appointment.created_at);
          const now = new Date();

          // Calcul de la différence en jours
          const differenceInMs = now - createdAt;
          const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);

          // Vérification si la différence ne dépasse pas 6 jours
          if (differenceInDays <= 6) {

            const cancel_payment = await paymentApi.cancelPayment(localStorage.getItem('token'), appointment.payment_intent, appointment.id);
            if (!cancel_payment.success) {
              showErrorMessage("Une erreur s'est produite lors de l'annulation du payment!");
              return;
            }

          } else {
            await appointmentApi.cancelExpiredAppointment(localStorage.getItem('token'), appointment.id);
          }
        } else {
          await appointmentApi.updateAppointmentStatus(localStorage.getItem('token'), appointment.id, { status: "cancelled" });
        }
        sendEmailBookingStatusToClient(
          {
            email: appointment.email,
            first_name: appointment.first_name,
            last_name: appointment.last_name,
          },
          `${profileData.first_name} ${profileData.last_name}`,
          appointment.appointment_date,
          formatTime(appointment.start_time),
          formatTime(appointment.end_time),
          'cancel',
          profileData.slug
        );
        Swal.fire("Informations", 'Le rendez-vous a été rejeté avec succès!', 'success').then(() => setChangeTrigger(prev => !prev));
      }
    })
  }

  const EditAppointmentModal = ({ appointment }) => {
    const [duration, setDuration] = useState(getDuration());
    const [address, setAddress] = useState("");
    const [oldAddress, setOldAddress] = useState("");
    const [editAddress, setEditAddress] = useState(false);
    const [isDurationChanged, setIsDurationChanged] = useState(false);

    const getAddress = async() => {
      if(!appointment.beautician_id) {
        return
      }

      try {
        const beautician = await beauticianApi.getBeauticianById(appointment.beautician_id);

        setOldAddress(beautician.data.address);
      }
      catch(err) {
        Swal.fire("Une erreur est survenue", err.message, "error");
      }
    }

    const handleDurationChange = (e) => {
      setDuration(e.target.value);
      setIsDurationChanged(parseInt(getDuration()) !== parseInt(e.target.value))
    };

    function getDuration(end_time="") {
      if ("start_time" in appointment) {
        const formatTime = time => {
          const [hours, minutes, seconds] = time.split(':').map(Number);
          return new Date(0, 0, 0, hours, minutes, seconds);
        };

        const end = formatTime(end_time || appointment.end_time);
        const start = formatTime(appointment.start_time);

        const durationMs = end - start;

        return durationMs / (1000 * 60);
      }
      return 0;
    }

    function calculateNewEndTime(startTime, duration) {
      const [hours, minutes] = startTime.split(':').map(Number);
      const startDate = new Date(0, 0, 0, hours, minutes);
      const newEndDate = new Date(startDate.getTime() + duration * 60 * 1000);

      const format = num => String(num).padStart(2, '0');
      return `${format(newEndDate.getHours())}:${format(newEndDate.getMinutes())}:00`;
    }

    function confirmChange() {
      if (editAddress && !address) {
        Swal.fire("Information manquante", "L'adresse éxacte est requise", "error")
        return
      }
      const newEndTime = calculateNewEndTime(appointment.start_time, duration);

      Swal.fire({
        showCancelButton: true,
        showConfirmButton: true,
        title: 'Voulez-vous confirmer ce rendez-vous?',
        text: "L'action est irréversible",
        confirmButtonText: "Oui, confirmer",
        cancelButtonText: "Non",
        icon: 'question'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const appointementDurationUpdate = await appointmentApi.updateAppointmentDuration(
            localStorage.getItem("token"),
            appointment.id,
            newEndTime
          )

          if (appointementDurationUpdate.success) {

            // Vérification si le paiement est par carte bancaire
            if (appointment.payment_type !== "onsite") {
              // Conversion de la date de création en objet Date
              const createdAt = new Date(appointment.created_at);
              const now = new Date();

              // Calcul de la différence en jours
              const differenceInMs = now - createdAt;
              const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);

              // Vérification si la différence dépasse 6 jours
              if (differenceInDays > 6) {
                showErrorMessage("Le délai de capture du paiement est dépassé, vous ne pouvez plus accepter ce rendez-vous !");
                await appointmentApi.cancelExpiredAppointment(localStorage.getItem('token'), appointment.id);
                return;
              }

              // Capture du paiement
              try {
                const capturePayment = await paymentApi.capturePayment(localStorage.getItem('token'), appointment.payment_intent, appointment.id);
                if (!capturePayment.success) {
                  showErrorMessage("Une erreur s'est produite lors de la capture du paiement !");
                  return;
                }
              } catch (error) {
                console.error("Erreur lors de la capture du paiement :", error);
                showErrorMessage("Une erreur s'est produite lors de la capture du paiement !");
                return;
              }
            } else {

              await appointmentApi.updateAppointmentStatus(
                localStorage.getItem('token'),
                appointment.id,
                { status: "confirmed" }
              );

            }

            sendEmailBookingStatusToClient(
              {
                first_name: appointment.first_name,
                last_name: appointment.last_name,
              },
              `${profileData.business_name}`,
              appointment.appointment_date,
              formatTime(appointment.start_time),
              formatTime(newEndTime),
              'confirmation',
              '',
              '',
              false,
              profileData,
              {
                isAddressChanged: editAddress,
                newAddress: address,
                isDurationChanged,
                oldDuration: getDuration(),
                newDuration: duration
              }
            );

            Swal.fire("Informations", 'Le rendez-vous a été confirmé avec succès!', 'success')
              .then(() => {
                setChangeTrigger(prev => !prev);
                setShowAppointmentModification(false);
              });
          }
        }
      });
    }

    useEffect(() => {
      getAddress()
    }, [])

    return (
      <Modal
        centered
        show={showAppointmentModification}
        onHide={() => setShowAppointmentModification(false)}
      >
        <Modal.Header>
          <h4>Confirmation de rendez-vous</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="flex gap-2">
            <span>Durée du rendez-vous: </span>
            <input
              type="number"
              value={duration}
              onChange={handleDurationChange}
              className="px-2 py-1 border rounded"
            />
            <span>minutes</span>
          </div>
          <div className="mt-4 flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <input
                type="radio"
                id="takeOldAdress"
                name="changeAddress"
                checked={!editAddress}
                onChange={() => setEditAddress(false)}
              />
              <label htmlFor="takeOldAdress">Garder l'adresse actuelle</label>
            </div>
            
            <span className={`px-3 text-gray-500`}>{oldAddress}</span>

            <div className="flex items-center gap-2">
              <input
                type="radio"
                id="editAdress"
                name="changeAddress"
                checked={editAddress}
                onChange={() => setEditAddress(true)}
              />
              <label htmlFor="editAdress">Entrer l'addresse exacte</label>
            </div>
            { editAddress &&
            <input
              type="text"
              placeholder="Addresse exacte"
              className="px-2 py-2 border"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='success'
            onClick={() => confirmChange()}
          >
            Confirmer
          </Button>
          <Button
            variant="secondary"
            onClick={() => setShowAppointmentModification(false)}
          >
            Annuler
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const AppointmentCard = ({ appointment }) => {

    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    return <div className={`appointment-card ${appointment.status}`}>
      <div className="appointment-time">{activePage === 'booking_pending' && format(appointment.appointment_date, "EEEE d MMMM yyyy", { locale: fr })} à {appointment.start_time.slice(0, 5)}</div>
      <div className="appointment-info">
        <div className="client-name">{appointment.first_name} {appointment.last_name}</div>
        {/*<div className="service-details">
          {appointment.services.map((service, subIndex) => (
            <div key={subIndex}>
              {service.subcategory_name} • {service.subcategory_duration} min
            </div>
          ))}
        </div>*/}
      </div>
      <div className="appointment-status">
        {appointment.status === 'confirmed' ? 'Confirmé' : 'En attente'}
      </div>
      <div className="flex gap-2">
        <button className='py-2 px-3 bg-gray-200 hover:bg-gray-300 rounded text-gray-900' onClick={handleShowModal} >
          Détails rendez-vous
        </button>
        {activePage === "booking_pending" && (<>
          <button onClick={() => handleConfirmAppointment(appointment)} className="appointment-card-btn confirm">
            Confirmer
          </button>
          <button onClick={() => handleRejectAppointment(appointment)} className="appointment-card-btn reject">
            Rejeter
          </button>
        </>)
        }
        {activePage === "my_agenda" &&
          <button className="appointment-card-btn reject">
            Annuler
          </button>
        }
      </div>
      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Informations du client</Modal.Title>
        </Modal.Header>
        <Modal.Body className='overflow-y-auto'>
          <div className="mb-3">
            <strong className='text-primary'>A propos du client</strong>
            <div>
              <strong>Nom :</strong> {appointment?.first_name} {appointment?.last_name}
            </div>
            <div>
              <strong>Email :</strong> {appointment?.email}
            </div>
            <div>
              <strong>Téléphone :</strong> {appointment?.phone}
            </div>
          </div>
          <div className="mb-3">
            <strong className="text-primary">Date :</strong>
            <p>
              {format(new Date(appointment.appointment_date), 'EEEE d MMMM yyyy', {locale: fr})}
            </p>
          </div>
          <div className="mb-3">
            <strong className="text-primary">Heure :</strong>
            <p>
              {formatTime(appointment.start_time)}{' '}
              -{' '}
              {formatTime(appointment.end_time)}
            </p>
          </div>
          <div className="mb-3">
            <strong className="text-primary">Services :</strong>
            {appointment.services && (
              <ul>
                {appointment.services.map((service, index) => (<React.Fragment key={index}>
                  <li key={index}>
                    {service.subcategory_name} - {service.subcategory_price}€{' '}
                    ({service.subcategory_duration} min)
                  </li>
                </React.Fragment>
                )
                )}
              </ul>
            )}
          </div>
          <div className="mb-3">
            <strong className="text-primary">A propos du payment</strong>
            <p>
              Type de paiement :{' '}
              {getPaymentTypeString(appointment.payment_type)}
              <br />
              Total à payer : {appointment.total_prices} €
              <br />
              Payé : {appointment.payed} €
              <br />
              Reste :{' '}
              {(
                Number(appointment.total_prices) -
                Number(appointment.payed)
              ).toFixed(2)}{' '}
              €
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  };


  const handleNavigationClick = (e, link) => {
    e.preventDefault();
    setActivePage(link);
  }

  const handlePlanifyAppointmentClick = () => {
    setShowPlanifyAppointmentModal(true);
  }

  const handleClosePlanifyAppointmentModal = () => {
    setShowPlanifyAppointmentModal(false);
  }


  return (
    <div className="agenda-container">
      {/* En-tête de l'agenda */}
      <div className="agenda-header">
        <div className='flex flex-wrap items-center'>
          {activePage === 'my_agenda' ?
            <h4>Mon Agenda</h4> :
            <h4>Réservations en attentes</h4>
          }
        </div>

        <div className='flex justify-center items-center gap-2 flex-wrap'>
          <button className='flex justify-center gap-2 items-center px-3 py-2 bg-gray-600 rounded hover:bg-gray-700 text-white' onClick={handlePlanifyAppointmentClick}><CalendarIcon size={16} /> Planifier un rendez-vous</button>
          {activePage !== "my_agenda" ?
            <button className='flex justify-center gap-2 items-center px-3 py-2 bg-gray-600 rounded hover:bg-gray-700 text-white' onClick={(e) => handleNavigationClick(e, 'my_agenda')}><CalendarIcon size={16} /> Mon agenda</button> :
            <button className='flex justify-center gap-2 items-center px-3 py-2 bg-gray-600 rounded hover:bg-gray-700 text-white' onClick={(e) => handleNavigationClick(e, 'booking_pending')}>
              <Clock size={16} /> Réservation en attentes
              <span className="bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                {pendingAppointment.length}
              </span>
            </button>

          }
        </div>
      </div>

      {/* Vue de l'agenda */}
      <div className="agenda-view">

        {/* Zone des rendez-vous */}
        <div className="appointments-container">

          <div className="appointments-layer">
            {(pendingAppointment.length === 0 && activePage === "booking_pending") && (
              <div className="empty-state">Aucun rendez-vous en attente.</div>
            )}
            {activePage === "booking_pending" && pendingAppointment
              .map((appointment, index) => (
                <AppointmentCard key={index} appointment={appointment} />
              ))}
            {activePage === "my_agenda" && (
              <ModernCalendar 
                events={confirmedAppointment}
                onEventClick={handleEventClick}
              />
            )}
            {showEventModal && selectedEvent && (
              <ShowInfoClientModal
                showModal={showEventModal}
                handleCloseModal={handleCloseEventModal}
                selectedEvent={{
                  ...selectedEvent,
                  start: new Date(selectedEvent.extendedProps.appointment_date), // Créer un nouvel objet Date
                  end: new Date(selectedEvent.extendedProps.appointment_date),   // Créer un nouvel objet Date
                  extendedProps: {
                    ...selectedEvent.extendedProps,
                    appointment_date: new Date(selectedEvent.extendedProps.appointment_date), // S'assurer que c'est un objet Date
                    start_time: selectedEvent.extendedProps.start_time,
                    end_time: selectedEvent.extendedProps.end_time
                  }
                }}
              />
            )}
          </div>
        </div>
      </div>

      {/* Modal manuel appointment */}
      <EditAppointmentModal appointment={activeAppointment} />
      <PlanifyAppointmentModal showModal={showPlanifyAppointmentModal} handleCloseModal={handleClosePlanifyAppointmentModal} />
    </div>
  );
};

export default BeauticianAgenda;