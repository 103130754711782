import { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import PasswordField from '../components/common/PasswordField';

const ResetPassword = () => {
    const [passwords, setPasswords] = useState({
        newPassword: '',
        confirmPassword: ''
    });
    const [errors, setErrors] = useState({
        newPassword: '',
        confirmPassword: ''
    });
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('success');
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const token = searchParams.get('token');

    const validatePasswords = () => {
        const newErrors = {
            newPassword: '',
            confirmPassword: ''
        };

        if (passwords.newPassword.length < 8) {
            newErrors.newPassword = 'Le mot de passe doit contenir au moins 8 caractères';
        }

        if (!/[A-Z]/.test(passwords.newPassword)) {
            newErrors.newPassword = 'Le mot de passe doit contenir au moins une majuscule';
        }

        if (!/[0-9]/.test(passwords.newPassword)) {
            newErrors.newPassword = 'Le mot de passe doit contenir au moins un chiffre';
        }

        if (passwords.newPassword !== passwords.confirmPassword) {
            newErrors.confirmPassword = 'Les mots de passe ne correspondent pas';
        }

        setErrors(newErrors);
        return !newErrors.newPassword && !newErrors.confirmPassword;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Vérifier si le token existe
        if (!token) {
            setMessage('Token de réinitialisation manquant ou invalide');
            setMessageType('error');
            return;
        }

        // Validation des mots de passe
        if (!validatePasswords()) {
            return;
        }

        setIsLoading(true);
        setMessage('');

        try {
            const response = await fetch('/api/users/reset-password', {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    token, 
                    newPassword: passwords.newPassword 
                }),
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Une erreur est survenue');
            }

            // Message de succès
            setMessageType('success');
            setMessage(data.message || 'Mot de passe réinitialisé avec succès');
            setPasswords({ newPassword: '', confirmPassword: '' });

            // Rediriger après 3 secondes
            setTimeout(() => {
                navigate('/?status=new-user'); // Redirige vers la page d'accueil
            }, 3000);
            
        } catch (error) {
            console.error('Erreur:', error);
            setMessageType('error');
            setMessage(error.message || 'Une erreur est survenue lors de la réinitialisation du mot de passe');
        } finally {
            setIsLoading(false);
        }
    };

    const handlePasswordChange = (field) => (e) => {
        setPasswords(prev => ({
            ...prev,
            [field]: e.target.value
        }));
        setErrors(prev => ({
            ...prev,
            [field]: ''
        }));
    };

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <div className="flex justify-center">
                    <img
                        src="/logoRouge.png"
                        alt="Logo"
                        className="h-16 w-16"
                    />
                </div>
                
                <form onSubmit={handleSubmit} className="mt-8 space-y-6">
                    <div className="text-center">
                        <h2 className="text-3xl font-bold text-gray-900">
                            Réinitialiser le mot de passe
                        </h2>
                        <p className="mt-2 text-sm text-gray-600">
                            Choisissez votre nouveau mot de passe
                        </p>
                    </div>

                    <div className="space-y-4">
                        <PasswordField
                            label="Nouveau mot de passe"
                            value={passwords.newPassword}
                            onChange={handlePasswordChange('newPassword')}
                            placeholder="Entrez votre nouveau mot de passe"
                            error={errors.newPassword}
                            disabled={isLoading}
                            required
                        />
                        {errors.newPassword && (
                            <p className="text-sm text-red-600">{errors.newPassword}</p>
                        )}
                        
                        <PasswordField
                            label="Confirmation du mot de passe"
                            value={passwords.confirmPassword}
                            onChange={handlePasswordChange('confirmPassword')}
                            placeholder="Confirmez votre nouveau mot de passe"
                            error={errors.confirmPassword}
                            disabled={isLoading}
                            required
                        />
                        {errors.confirmPassword && (
                            <p className="text-sm text-red-600">{errors.confirmPassword}</p>
                        )}
                    </div>

                    <div>
                        <button
                            type="submit"
                            disabled={isLoading}
                            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            {isLoading ? (
                                <>
                                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    Réinitialisation en cours...
                                </>
                            ) : (
                                'Réinitialiser le mot de passe'
                            )}
                        </button>
                    </div>

                    {message && (
                        <div className={`rounded-md ${messageType === 'success' ? 'bg-green-50' : 'bg-red-50'} p-4`}>
                            <p className={`text-sm ${messageType === 'success' ? 'text-green-700' : 'text-red-700'}`}>
                                {message}
                            </p>
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};

export default ResetPassword;
