// src/components/messaging/UploadProgress.jsx
import React from 'react';

const UploadProgress = ({ progress }) => {
  return (
    <div className="relative mt-1">
      <div className="h-1 w-full bg-gray-200 rounded-full overflow-hidden">
        <div 
          className="h-full bg-rose-500 transition-all duration-300 ease-out"
          style={{ width: `${progress}%` }}
        />
      </div>
      <span className="text-xs text-gray-500 absolute right-0 -top-4">
        {`${progress}%`}
      </span>
    </div>
  );
};

export default UploadProgress;