import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { API_URL } from "../utils/constants";

const EmailConfirmation = () => {
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);
    const [isButtonLoading, setIsButtonLoading] = useState(false); // Nouvel état pour le bouton
    const [searchParams] = useSearchParams();

    const hasProcessed = useRef(false);

    useEffect(() => {
        const token = searchParams.get("token");

        const confirmRegistration = async () => {
            try {
                // Vérification avec useRef
                if (hasProcessed.current) return;

                hasProcessed.current = true;

                const response = await fetch(`${API_URL}/users/confirm-user-registration?token=${encodeURIComponent(token)}`);
                const data = await response.json();

                if (data.success) {
                    setMessage("Votre compte a été confirmé avec succès! Vous allez être redirigé vers la page d'accueil.");
                    
                    setTimeout(() => {window.location.href = "/?status=new-user";}, 3000); // Redirige après 3 secondes si succès
                } else {
                    setError(data.message || "Une erreur est survenue lors de la confirmation.");
                }
            } catch (err) {
                setError(err.message || "Impossible de confirmer votre compte pour le moment.");
            } finally {
                setLoading(false);
            }
        };

        if (token) {
            confirmRegistration();
        } else {
            setError("Token de confirmation manquant.");
            setLoading(false);
        }
    }, []);


    const handleResendEmailVerificationClick = async (e) => {
        e.preventDefault();

        try {
            setIsButtonLoading(true); // Début du chargement pour le bouton

            const token = searchParams.get("token");

            if (!token) {
                setError("Aucun token disponible pour renvoyer l'email.");
                return;
            }

            const response = await fetch(`${API_URL}/users/resend-email-verification`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token }),
            });

            const data = await response.json();

            if (data.success) {
                setMessage(data.message);
                
                setTimeout(() => {window.location.href = "/";}, 3000); // Redirige après 3 secondes si succès
            } else {
                setError(data.message || "Une erreur est survenue lors de la confirmation.");
            }
        } catch (err) {
            setError(err.message || "Impossible de confirmer votre compte pour le moment.");
        } finally {
            setIsButtonLoading(false); // Fin du chargement pour le bouton
        }
    };

    return (
        <div className="p-6">
            {loading ? (
                <p className="text-blue-600 text-center font-medium">Chargement...</p>
            ) : error ? (<>
                <p className="text-red-600 text-center font-medium">{error}</p>
                <p className="text-center">
                    <button
                        className={`px-3 py-2 rounded ${isButtonLoading ? "bg-gray-500" : "bg-blue-600"} text-white hover:bg-blue-700`}
                        onClick={handleResendEmailVerificationClick}
                        disabled={isButtonLoading} // Désactive le bouton pendant le chargement
                    >
                        {isButtonLoading ? "Chargement..." : "Renvoyer un email de confirmation"}
                    </button>
                </p>
                </>
            ) : (
                <p className="text-green-600 text-center font-medium">{message}</p>
            )}
        </div>
    );
};

export default EmailConfirmation;