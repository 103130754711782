import { API_URL } from "../utils/constants";

export const beauticiansSubCategoriesApi = {

    saveBeauticiansSubCategories: async (data, token) => {
        try {
            const response = await fetch(`${API_URL}/beauticians-subcategories/create-or-update`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la création du service');
        }

    },

    getByBauticianId: async (id, token) => {
        try {
            const response = await fetch(`${API_URL}/beauticians-subcategories/find-by-beautician-id/${id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la récupération du préstation: ' + error.message);
        }
    },

    updateBeauticianSubcategoryDisplayOrder: async(token, beautician_id, subcategory_id, display_order) => {
        try {
            const response = await fetch(`${API_URL}/beauticians-subcategories/update-display-order`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    beautician_id,
                    subcategory_id,
                    display_order
                })
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la mise à jours des préstations: ' + error.message);
        }
    },

    saveImageBeauticianSubcategory: async (id, token, images) => {
        try {
            const formData = new FormData();
            
            // Ajout de l'ID dans les données
            formData.append('beautician_subcategory_id', id);
            
            images.forEach((image, index) => {
                formData.append(`files`, image.file);
            });
    
            // Requête fetch avec le FormData
            const response = await fetch(`${API_URL}/beauticians-subcategories/save-uploaded-image`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            });
    
            // Traitement de la réponse
            if (!response.ok) {
                throw new Error(`Erreur serveur: ${response.statusText}`);
            }
    
            return await response.json();
        } catch (error) {
            console.error(error);
            throw new Error('Erreur lors de l\'enregistrement des images');
        }
    },

    deletePortfolioImageById: async (id, token) => {
        try {
            const response = await fetch(`${API_URL}/beauticians-subcategories/portfolio-image/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la suppression de l\'image: ' + error.message);
        }
    },

    deleteBeauticianCategoryById : async (id, token) => {
        try {
            const response = await fetch(`${API_URL}/beauticians-subcategories/delete/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            
            const data = await response.json();
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la suppression du préstation: ' + error.message);
        }
    }
    
}