import React, { useState } from "react";
import { addMonths, format, startOfToday } from "date-fns";

const CustomCalendar = ({
  selectedDate,
  setSelectedDate,
  dispoHebdo,
  blockedDates
}) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const today = startOfToday();
  const maxNavigationMonth = addMonths(today, 4);

  // Générer les jours du mois actuel
  const generateDaysInMonth = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);

    const days = [];
    const startingDayOfWeek = firstDay.getDay();

    // Ajouter des jours vides pour aligner le début du mois
    for (let i = 0; i < startingDayOfWeek; i++) days.push(null);

    // Ajouter les jours réels du mois
    for (let day = 1; day <= lastDay.getDate(); day++) {
      days.push(new Date(year, month, day));
    }

    return days;
  };

  // Mappe le numéro du jour de la semaine à sa disponibilité hebdomadaire
  const dayOfWeekToField = (dayIndex) => {
    const fields = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    return fields[dayIndex];
  };

  // Vérifie si un jour est désactivé
  const isDayDisabled = (day) => {
    if (!day || day < today) return true; // Passé ou date invalide

    const dayOfWeek = day.getDay();
    const availabilityField = dayOfWeekToField(dayOfWeek);

    // Non disponible selon le planning hebdomadaire
    if (!dispoHebdo.includes(availabilityField)) return true;

    // Vérification des dates bloquées
    const isBlockedDate = blockedDates.some(
      (blockedDate) =>
        new Date(blockedDate).toDateString() === day.toDateString()
    );
    if (isBlockedDate) return true;
  };

  // Gérer la sélection d'une date
  const handleDateSelect = (day) => {
    if (!isDayDisabled(day)) setSelectedDate(day);
  };

  // Passer au mois précédent
  const goToPreviousMonth = () => {
    const previousMonth = addMonths(currentMonth, -1);
    if (previousMonth >= today) setCurrentMonth(previousMonth);
  };

  // Passer au mois suivant
  const goToNextMonth = () => {
    const nextMonth = addMonths(currentMonth, 1);
    if (nextMonth <= maxNavigationMonth) setCurrentMonth(nextMonth);
  };

  const daysInMonth = generateDaysInMonth(currentMonth);

  return (
    <div className="bg-white rounded-xl shadow-2xl overflow-hidden md:w-[500px] mx-auto">
      <div className="bg-gradient-to-r from-rose-600 to-rose-800 text-white p-4">
        <div className="flex justify-between items-center">
          <button
            onClick={goToPreviousMonth}
            className="p-2 rounded-full hover:bg-white/20 active:bg-white/30 transition-all duration-300"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          <h2 className="text-xl font-semibold">{format(currentMonth, "MMMM yyyy")}</h2>
          <button
            onClick={goToNextMonth}
            disabled={currentMonth >= maxNavigationMonth}
            className={`
              p-2 rounded-full transition-all duration-300
              ${currentMonth >= maxNavigationMonth
                ? "opacity-30 cursor-not-allowed"
                : "hover:bg-white/20 active:bg-white/30"}
            `}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>
      </div>

      <div className="grid grid-cols-7 gap-1 md:p-4 p-1 text-center bg-gray-50">
        {["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"].map((day) => (
          <div key={day} className="font-bold text-xs text-gray-500 uppercase">{day}</div>
        ))}

        {daysInMonth.map((day, index) => (
          <div
            key={day ? day.toDateString() : `empty-${index}`}
            className={`
              p-2 rounded-lg transition-all duration-200
              ${!day ? "bg-transparent" : ""}
              ${day && isDayDisabled(day) ? "bg-gray-300 text-gray-500 cursor-not-allowed" : ""}
              ${day && selectedDate && day.toDateString() === selectedDate.toDateString() ? "bg-rose-700 text-white" : ""}
              ${day && !isDayDisabled(day) ? "hover:bg-rose-600 hover:text-white hover:shadow-md cursor-pointer" : ""}
            `}
            onClick={() => day && handleDateSelect(day)}
          >
            {day ? day.getDate() : ""}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomCalendar;
