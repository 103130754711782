import React, { useEffect, useContext } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet';
import './Abonnements.css';
import tarifs from '../../assets/fonctionnalités/tarifs.png';
import tarif1 from '../../assets/tarifs/1.png';
import tarif2 from '../../assets/tarifs/2.png';
import { AppContext } from '../../contexts/AppContext';
import { useNavigate } from 'react-router-dom';

const Tarifs = () => {
  const { setActivePageForHeader } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.setAttribute('data-page', 'search');
    setActivePageForHeader("search");

    return () => {
      document.body.removeAttribute('data-page');
      setActivePageForHeader("");
    }
  }, [setActivePageForHeader]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCardClick = () => {
    const trialSection = document.getElementById('trial-section');
    if (trialSection) {
      trialSection.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <>
      <Helmet>
        <title>Nos Tarifs | PlaniBeauty</title>
        <meta name="description" content="Découvrez nos offres et tarifs adaptés pour développer votre business avec PlaniBeauty." />
        <link rel="canonical" href="https://www.planibeauty.fr/tarifs" />
      </Helmet>

      {/* Titre de la page */}
      <div className="tarifs-header">
        <h1>Nos tarifs <span className="accent-dot">✦</span></h1>
        <p>2 offres adaptées pour développer votre business</p>
      </div>

      {/* Hero Section */}
      <div className="bande-section">
        <LazyLoadImage src={tarifs} alt="Tarifs PlaniBeauty" />
        <div className="bande-overlay"></div>
      </div>

      {/* Section des tarifs */}
      <section className="tarifs-section">
        <div className="tarifs-cards-container">
          {/* Formule Classic */}
          <div className="tarif-card cursor-pointer" onClick={handleCardClick}>
            <h2>Formule Classic</h2>
            <div className="price">
              <span className="amount">49 €</span>
              <span className="period">/mois</span>
            </div>
            <ul className="features-list">
              <li>Agenda en ligne</li>
              <li>Interface personnalisée</li>
              <li>Page professionnelle</li>
              <li>Moyen de paiement en ligne ou en espèces</li>
              <li>Possibilité d'acomptes</li>
              <li>Statistiques</li>
              <li>Priorité pour les formations</li>
            </ul>
            <div className="tarif-illustration">
              <img
                src={tarif1}
                alt="Interface agenda"
              />
            </div>
          </div>

          {/* Formule Caisse */}
          <div className="tarif-card disabled cursor-not-allowed">
            <div className="watermark">**Bientôt disponible**</div>
            <h2>Formule Caisse</h2>
            <div className="price">
              <span className="amount">0,00€</span>
              <span className="period">/mois</span>
            </div>
            <ul className="features-list">
              <li>Formule Classic</li>
              <li>Outils caisse</li>
              <li>Priorité pour les formations pour votre équipe</li>
            </ul>
            <div className="tarif-illustration">
              <img
                src={tarif2}
                alt="Interface caisse"
              />
            </div>
          </div>
        </div>
      </section>
      {/* Ajoutez ce code dans votre composant Abonnements.js, juste avant la dernière balise de fermeture </> */}

      {/* Section d'essai gratuit */}
      <section className="trial-section" id="trial-section">
        <div className="trial-background">
          <svg className="beauty-icon icon-1" style={{ top: '10%', left: '10%' }} viewBox="0 0 24 24">
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
            <path d="M12 6c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm0 10c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z" />
          </svg>
          <svg className="beauty-icon icon-2" style={{ top: '20%', right: '15%' }} viewBox="0 0 24 24">
            <path d="M20 12c0-4.42-3.58-8-8-8s-8 3.58-8 8 3.58 8 8 8 8-3.58 8-8zM6 12c0-3.31 2.69-6 6-6s6 2.69 6 6-2.69 6-6 6-6-2.69-6-6z" />
          </svg>
          <svg className="beauty-icon icon-3" style={{ bottom: '15%', right: '10%' }} viewBox="0 0 24 24">
            <path d="M12 2L4.5 20.29l.71.71L12 18l6.79 3 .71-.71z" />
          </svg>
        </div>
        <div className="trial-content">
          <h2>Testez Planibeauty Pro gratuitement pendant 10 jours !</h2>
          <p>Découvrez une gestion simplifiée et optimisée de vos rendez-vous.</p>
          <p>Activez votre essai gratuit en prenant rendez-vous avec un de nos conseillers.</p>
          <div className='flex flex-wrap items-center justify-center gap-3'>

            <a
              href="#trial"
              onClick={(e) => { e.preventDefault(); navigate('/ajouter-etablissement'); }}
              className="trial-button"
            >
              ESSAI GRATUIT
            </a>
            <a
              href="https://calendly.com/contact-planibeauty/inscription-site-planibeauty"
              target="_blank"
              rel="noopener noreferrer"
              className="trial-button"
            >
              DEMANDER INFO
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Tarifs;