import "./UserData.css"
import styled from "styled-components";

import { useContext, useState, useEffect, useRef } from "react"
import { FaComment, FaEllipsisVertical, FaEye, FaFire, FaPencil, FaTrash } from "react-icons/fa6"
import { dashboardContext } from "../../pages/dashboard/Dashboard";
import { truncateText } from "../../utils/stringHelper";
import { format } from "date-fns";
import DataTable from "react-data-table-component";
import { FaCheck } from "react-icons/fa";
import Swal from "sweetalert2";
import beauticianApi from "../../api/beautician.api";
import withReactContent from 'sweetalert2-react-content';
import ReactSwitch from 'react-switch';
import { fr } from "date-fns/locale";
import { Pen, Plus } from "lucide-react";
import trendingApi from "../../api/trending.api";
import { motion } from "motion/react";
import 'react-calendar/dist/Calendar.css';

import RenderStars from "./RenderStars";
import TrendingPanel from "./Trending/TrendingPanel";
import AddToTrendingPopUp from "./Trending/AddToTrendingPopUp";
import freeTrialApi from "../../api/free_trial_usage.api";
import { showWarningMessage } from "../../utils/messageHelper";

const CustomDropdown = ({ editUser, deleteRow, showSiteInfo, showComment, acceptRegistration, showAddToTrendingPanel, is_active }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [dropdownPosition, setDropdownPosition] = useState("bottom"); // "top" ou "bottom"
    const dropdownRef = useRef(null); // Ref pour le dropdown
    const { activePage } = useContext(dashboardContext);

    const toggleDropdown = () => setIsOpen((prev) => !prev);

    const callAction = (action) => {
        setIsOpen(false);
        action();
    };

    // Fermer le dropdown en cliquant en dehors
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        // Ajouter le gestionnaire d'événements
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            // Nettoyer l'événement
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    // Ajuster la position du menu en fonction de l'espace disponible
    useEffect(() => {
        if (isOpen && dropdownRef.current) {
            const rect = dropdownRef.current.getBoundingClientRect();
            const windowHeight = window.innerHeight;

            // Si le menu dépasse vers le bas, afficher vers le haut
            if (rect.bottom > (windowHeight - 250)) {
                setDropdownPosition("top");
            } else {
                setDropdownPosition("bottom");
            }
        }
    }, [isOpen]);

    return (
        <div className="text-center relative" ref={dropdownRef}>
            {/* Bouton de déclenchement */}
            <button
                className="p-2 rounded-full hover:bg-gray-200"
                onClick={toggleDropdown}
                aria-expanded={isOpen}
                aria-haspopup="true"
            >
                <FaEllipsisVertical />
            </button>

            {/* Menu déroulant */}
            {isOpen && (
                <div
                    className={`absolute w-96 p-2 bg-gray-100 shadow-lg border border-gray-300 rounded-md ${dropdownPosition === "top" ? "bottom-10" : "top-10"
                        } right-2`}
                    style={{ zIndex: "1000" }}
                >
                    {(activePage !== "client" && activePage !== "operator") && <>
                        {!is_active && <button
                            onClick={() => callAction(acceptRegistration)}
                            className="bg-green-500 flex items-center text-white gap-2 px-4 py-2 hover:bg-green-600 w-full text-left"
                        >
                            <FaCheck /> Accepter l'inscription du professionnel
                        </button>}
                    </>
                    }
                    <button
                        onClick={() => callAction(editUser)}
                        className="flex items-center bg-yellow-400 gap-2 px-4 py-2 hover:bg-yellow-500 w-full text-left"
                    >
                        <FaPencil /> Modifier les informations personnelles
                    </button>
                    <button
                        onClick={() => callAction(deleteRow)}
                        className="flex items-center bg-red-500 text-white gap-2 px-4 py-2 hover:bg-red-600 w-full text-left"
                    >
                        <FaTrash /> Supprimer le profil
                    </button>
                    {(activePage !== "client" && activePage !== "operator") && <>
                        <button
                            onClick={() => callAction(showSiteInfo)}
                            className="flex items-center bg-blue-500 text-white gap-2 px-4 py-2 hover:bg-blue-600 w-full text-left"
                        >
                            <FaEye /> Voir et modifier les caractéristiques
                        </button>
                        <button
                            onClick={() => callAction(showComment)}
                            className="flex items-center gap-2 px-4 py-2 bg-gray-200 hover:bg-gray-300 w-full text-left"
                        >
                            <FaComment /> Voir les avis
                        </button>
                        <button
                            onClick={() => callAction(showAddToTrendingPanel)}
                            className="flex items-center gap-2 px-4 py-2 bg-gray-200 hover:bg-gray-300 w-full text-left"
                        >
                            <FaFire /> Ajouter aux tendances
                        </button>
                    </>}

                </div>
            )}
        </div>
    );
};

const StyledCellWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const UserData = ({ datas }) => {
    const {
        setActiveID,
        showSiteInformation,
        showDeletionModal,
        showEditModal,
        setUserData,
        showUserSheetModal,
        activePage,
        setShowBeauticianCommentaire,
        setReloadTrigger,
        setDynamicUserData,
        trends
    } = useContext(dashboardContext)

    const [trendsList, setTrendsList] = useState([])
    const [trendsTrigger, setTrendsTrigger] = useState(false)
    const [showAddTrending, setShowAddTrending] = useState(false)
    const [showAddToTrending, setShowAddToTrending] = useState(false)
    const [activeBeautician, setActiveBeautician] = useState({})

    const [selectedRows, setSelectedRows] = useState([]);

    const handleRowClick = (row) => {
        setActiveID(row['id']);
        setUserData(row);
        setDynamicUserData(row);
        setTimeout(() => {
            showUserSheetModal(true);
        }, 200);
    };

    const handleSelectAll = () => {
        const allIds = datas.map((row) => row.id);
        setSelectedRows(selectedRows.length === datas.length ? [] : allIds);
    };

    const handleRowSelect = (rowId) => {
        setSelectedRows((prevSelected) =>
            prevSelected.includes(rowId)
                ? prevSelected.filter((id) => id !== rowId)
                : [...prevSelected, rowId]
        );
    };

    const deleteRow = (row) => {
        setActiveID(row["id"])
        showDeletionModal(true)
    }

    const editUser = (row) => {
        setActiveID(row['id']);
        setUserData(row)
        showEditModal(true)
    }

    const showSiteInfo = (row) => {
        setUserData(row)
        setActiveID(row['id']);
        showSiteInformation(true);
    }

    const showComment = (row) => {
        setActiveID(row['id']);
        setShowBeauticianCommentaire(true);
    }

    const showAddToTrendingPanel = (row) => {
        setActiveBeautician(row)
        setShowAddToTrending(true)
    }
    
    const MySwal = withReactContent(Swal);

    const handleAvailabilityChange = (row) => {
        MySwal.fire({
            title: 'Confirmation',
            text: `Voulez-vous vraiment modifier la disponibilité de ${row.first_name} ${row.last_name} ?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
        }).then(async (result) => {
            if (result.isConfirmed) {
                // Mettre à jour la disponibilité via une API ou une méthode
                await beauticianApi.updateAvailability(localStorage.getItem('token'), row.id, { is_available: !row.is_available });
                setReloadTrigger(prev => !prev);
            }
        });
    };

    const acceptRegistration = (row) => {
        Swal.fire({
            title: "Voulez-vous vraiment accepter l'inscription?",
            icon: "question",
            showCancelButton: true,
            showConfirmButton: true,
            cancelButtonText: "Non",
            confirmButtonText: "Oui, accepter"
        }).then(async (result) => {
            if (result.isConfirmed) {
                // Afficher le loader
                Swal.fire({
                    title: 'Traitement en cours...',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });

                try {
                    await beauticianApi.acceptRegistration(
                        localStorage.getItem('token'),
                        row['id'],
                        row['email'],
                        row['first_name'] + ' ' + row['last_name']
                    );

                    //on active la periode d'essaie
                    /*const res = await freeTrialApi.create(localStorage.getItem('token'), { user_id: row.user_id });
                    if(res.success) {
                        showWarningMessage('L\'utilisateur ne peut plus souscrire à la periode d\'essaie!');
                    }*/

                    // Indiquer le succès
                    Swal.fire({
                        title: "Succès!",
                        text: "L'inscription a été acceptée.",
                        icon: "success",
                        timer: 2000,
                        showConfirmButton: false
                    }).then(() => {
                        setReloadTrigger(prev => !prev);
                    });
                } catch (error) {
                    // Gestion des erreurs
                    Swal.fire({
                        title: "Erreur",
                        text: "Une erreur est survenue lors de l'acceptation de l'inscription.",
                        icon: "error",
                    });
                }
            }
        });
    };


    const formatType = (type) => {
        switch (type) {
            case 'admin':
                return 'Admin';
            case 'operator':
                return 'Opérateur';
            case 'beautician':
                return 'Professionnel';
            case 'client':
                return 'Client';
            default:
                return '';
        }
    }

    // Colonnes de la table
    const columns = ['all', 'indépendant', 'établissement'].includes(activePage) ? [
        {
            name: (
                <input
                    type="checkbox"
                    onChange={handleSelectAll}
                    checked={selectedRows.length === datas.length}
                />
            ),
            cell: (row) => (
                <input
                    type="checkbox"
                    className="ms-2"
                    checked={selectedRows.includes(row.id)}
                    onChange={() => handleRowSelect(row.id)}
                />
            ),
            width: "50px",
            ignoreRowClick: true,
        },
        {
            name: "ID",
            selector: (row) => row.id,
            sortable: true,
            width: "70px",
        },
        {
            name: "Type",
            selector: (row) => formatType(row.user_type),
            sortable: true,
        },
        {
            name: "Nom",
            selector: (row) => `${row.first_name} ${row.last_name}`,
            sortable: true,
        },
        {
            name: "Adresse",
            cell: (row) => (row.address ? truncateText(row.address, 25) : "-"),
            sortable: true,
        },
        {
            name: "Ville",
            selector: (row) => row.city || "-",
            sortable: true
        },
        {
            name: "Téléphone",
            selector: (row) => row.phone,
            sortable: true,
        },
        {
            name: "Date création",
            cell: (row) => format(new Date(row.created_at), "d MMMM yyyy à HH:mm", { locale: fr }),
            sortable: true,
            sortFunction: (rowA, rowB) => {
                const dateA = new Date(rowA.created_at);
                const dateB = new Date(rowB.created_at);
                return dateA - dateB; // Tri croissant
            },
        },
        {
            name: "Disponibilité",
            selector: (row) => row.is_available,
            sortable: true,
            cell: (row) => (
                <div className="flex justify-center items-center w-full">
                    <ReactSwitch
                        checked={(row.is_available !== 'undefined' && row.is_available !== undefined) ? row.is_available : false}
                        onChange={() => handleAvailabilityChange(row)}
                        offColor="#f87171" // Couleur désactivée
                        onColor="#4ade80" // Couleur activée
                        uncheckedIcon={false}
                        checkedIcon={false}
                        width={40} // Largeur du switch
                        height={20} // Hauteur du switch
                        handleDiameter={15}
                    />
                </div>
            )
        },
        {
            name: "Status inscription",
            selector: (row) => row.is_active,
            sortable: true,
            cell: (row) => (
                <div className="flex justify-center items-center w-full">
                    {row.is_active ?
                        <span className="bg-green-500 px-2 py-1 text-sm text-white rounded">Accepté</span> :
                        <span className="bg-yellow-500 px-2 py-1 text-sm rounded">En attente</span>}
                </div>
            ),
        },
        {
            name: "Status du compte",
            selector: (row) => row.is_active,
            sortable: true,
            cell: (row) => (
                <div className="flex justify-center items-center w-full">
                    {row.is_account_active ?
                        <span className="bg-green-500 px-2 py-1 text-sm text-white rounded">Activé</span> :
                        <span className="bg-yellow-500 px-2 py-1 text-sm rounded">Desactivé</span>}
                </div>
            ),
        },
        {
            name: "Actions",
            cell: (row) => (
                <div className=" w-full flex justify-center items-center">
                    <StyledCellWrapper>
                        <CustomDropdown
                            editUser={() => editUser(row)}
                            deleteRow={() => deleteRow(row)}
                            showComment={() => showComment(row)}
                            addToTrends={() => {}} //addToTrends(row)
                            showSiteInfo={() => showSiteInfo(row)}
                            showAddToTrendingPanel={() => showAddToTrendingPanel(row)}
                            is_active={row.is_active}
                            acceptRegistration={() => acceptRegistration(row)}
                        />
                    </StyledCellWrapper>
                </div>
            ),
            ignoreRowClick: true, // Empêche les clics sur cette cellule d'interagir avec les lignes
            allowoverflow: "true", // Contrôle l'affichage dans la cellule
            width: "90px",
        }
    ] : [
        {
            name: (
                <input
                    type="checkbox"
                    onChange={handleSelectAll}
                    checked={selectedRows.length === datas.length}
                />
            ),
            cell: (row) => (
                <input
                    type="checkbox"
                    className="ms-2"
                    checked={selectedRows.includes(row.id)}
                    onChange={() => handleRowSelect(row.id)}
                />
            ),
            width: "80px",
            ignoreRowClick: true,
        },
        {
            name: "ID",
            selector: (row) => row.id,
            sortable: true,
            width: "80px",
        },
        {
            name: "Type",
            selector: (row) => formatType(row.user_type),
            sortable: true,
        },
        {
            name: "Nom",
            selector: (row) => `${row.first_name} ${row.last_name}`,
            sortable: true,
        },
        {
            name: "Téléphone",
            selector: (row) => row.phone,
            sortable: true,
        },
        {
            name: "Date création",
            cell: (row) => format(new Date(row.created_at), "dd-MM-yyyy HH:mm", { locale: fr }),
            sortable: true,
            sortFunction: (rowA, rowB) => {
                const dateA = new Date(rowA.created_at);
                const dateB = new Date(rowB.created_at);
                return dateA - dateB; // Tri croissant
            },
        },
        {
            name: "Status du compte",
            selector: (row) => row.is_active,
            sortable: true,
            cell: (row) => (
                <div className="flex justify-center items-center w-full">
                    {row.is_account_active ?
                        <span className="bg-green-500 px-2 py-1 text-sm text-white rounded">Activé</span> :
                        <span className="bg-yellow-500 px-2 py-1 text-sm rounded">Desactivé</span>}
                </div>
            ),
        },
        {
            name: "Actions",
            cell: (row) => (
                <div className=" w-full flex justify-center items-center">
                    <StyledCellWrapper>
                        <CustomDropdown
                            editUser={() => editUser(row)}
                            deleteRow={() => deleteRow(row)}
                            showSiteInfo={() => showSiteInfo(row)}
                            showComment={() => showComment(row)}
                            showAddToTrendingPanel={() => showAddToTrendingPanel(row)}
                        />
                    </StyledCellWrapper>
                </div>
            ),
            ignoreRowClick: true, // Empêche les clics sur cette cellule d'interagir avec les lignes
            allowoverflow: "true", // Contrôle l'affichage dans la cellule
            width: "100px",
        }
    ];

    const paginationOptions = {
        rowsPerPageText: 'Lignes par page',
        rangeSeparatorText: 'sur',
        noRowsPerPage: false,
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Tout',
    };


    // Styles personnalisés pour la table
    const customStyles = {
        tableWrapper: {
            style: {
                minHeight: "50vh", // Définissez ici votre hauteur minimale souhaitée
            },
        },
        rows: {
            style: {
                minHeight: "50px",
                cursor: "pointer"
            },
        },
        headCells: {
            style: {
                fontWeight: "bold",
                backgroundColor: "#f7f7f7",
                fontSize: "14px"
            },
        },
        cells: {
            style: {
                padding: "5px",
                fontSize: "14px"
            },
        },
    };

    useEffect(() => {
        const getTrendsDetails = async() => {
            try {
                const result = await trendingApi.getActiveTrending()

                setTrendsList(result.data)
            }
            catch(error) {
                Swal.fire("Erreur", error.message, "error")
            }
        }

        getTrendsDetails()
    }, [trends, trendsTrigger])

    const title = {
        all: "Professionnelles",
        indépendant: "Professionnelles",
        établissement: "Professionnelles",
        operator: "Operateurs",
        client: "Clients"
    }

    return (
        <div>
            {datas.length > 0 ?
                <div className="">
                    <h1 className="text-xl pb-2 border-b-4">Liste des {title[activePage]}</h1>
                    <DataTable
                        columns={columns}
                        data={datas}
                        onRowClicked={handleRowClick}
                        pagination
                        highlightOnHover
                        responsive
                        customStyles={customStyles}
                        paginationComponentOptions={paginationOptions}
                    />
                    {(activePage !== "operator" && activePage !== "client") && <>
                        <h1 className="text-xl my-2 pb-2 border-b-4">Professionnelles aux tendances</h1>
                        { trendsList.length > 0 ?
                            <section
                                className="my-4 flex items-center justify-center flex-wrap gap-4"
                            >
                                {trendsList.map((tr, key) => (
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: .5 }}
                                        key={key} className="w-1/5 p-3 border rounded shadow cursor-pointer"
                                    >
                                        <h1 className="text-lg cursor-pointer">{
                                            tr.business_name.length > 15 ? tr.business_name.slice(0, 15) + "..." : tr.business_name
                                        }</h1>
    
                                        <RenderStars star_count={tr.average_rating} />
                                    </motion.div>
                                ))}

                                <button
                                    onClick={() => setShowAddTrending(true)}
                                    className="flex items-center gap-2 text-gray-500 font-semibold hover:underline"
                                >
                                    <Pen size={16} />
                                    Modifier
                                </button>
                            </section> :
                            <p className="pt-4 gap-2 flex items-center justify-center text-lg text-gray-400">
                                Aucune professionnelle à afficher.
                                <button
                                    onClick={() => setShowAddTrending(true)}
                                    className="flex items-center gap-1 text-gray-500 font-semibold hover:underline"><Plus size={16}
                                />
                                    Ajouter
                                </button>
                            </p>
                        }
                    </>}
                </div> :
                <div className="p-5 text-center">
                    <p className="text-gray-700 text-xl">Aucun enregitrements à afficher...</p>
                </div>
            }

            <TrendingPanel
                show={showAddTrending}
                setShow={setShowAddTrending}
                trigger={setTrendsTrigger}
            />

            <AddToTrendingPopUp
                show={showAddToTrending}
                setShow={setShowAddToTrending}
                selectedBeautician={activeBeautician}
                trendsTrigger={setTrendsTrigger}
            />
        </div>
    );
}

export default UserData