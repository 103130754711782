import React, { useState, useEffect } from 'react'
import BeauticianCard from './BeauticianCard'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import beauticianApi from '../../api/beautician.api'
import trendingApi from '../../api/trending.api'
import './BeauticianCardList.css'

const CarouselSection = ({ items, title }) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [cardsPerPage, setCardsPerPage] = useState(5)

  useEffect(() => {
    const updateCardsPerPage = () => {
      const perPage = window.innerWidth <= 768 ? 4 : 5
      console.log('Cards per page:', perPage) // Log pour debug
      setCardsPerPage(perPage)
    }

    updateCardsPerPage()
    window.addEventListener('resize', updateCardsPerPage)

    return () => window.removeEventListener('resize', updateCardsPerPage)
  }, [])

  useEffect(() => {
    setCurrentPage(0)
  }, [cardsPerPage])

  const totalPages = Math.ceil(items.length / cardsPerPage)

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 0))
  }

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages - 1))
  }

  const getCurrentCards = () => {
    const start = currentPage * cardsPerPage
    const end = start + cardsPerPage
    return items.slice(start, Math.min(end, items.length))
  }

  return (
    <div className="beautician-list-container">
      {/*title && <h2 className="text-2xl font-bold text-center mb-8">{title}</h2>*/}
      <div className="beauticians-carousel">
        <button
          className="carousel-button prev"
          onClick={handlePrevPage}
          disabled={currentPage === 0}
          aria-label="Précédent"
        >
          <ChevronLeft size={24} />
        </button>

        <div className="beautician-grid">
          {getCurrentCards().map((item) => (
            <div key={`${item.id}-${currentPage}`} className="beautician-grid-item">
              <BeauticianCard beautician={item} />
            </div>
          ))}
        </div>

        <button
          className="carousel-button next"
          onClick={handleNextPage}
          disabled={currentPage === totalPages - 1}
          aria-label="Suivant"
        >
          <ChevronRight size={24} />
        </button>

        <div className="carousel-dots">
          {Array.from({ length: totalPages }).map((_, index) => (
            <button
              key={index}
              className={`carousel-dot ${currentPage === index ? 'active' : ''}`}
              onClick={() => setCurrentPage(index)}
              aria-label={`Page ${index + 1}`}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const BeauticianCardList = () => {
  const [independants, setIndependants] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchBeauticians = async () => {
      try {
        setLoading(true)

        const [trending, unorderedResult] = await Promise.all([
          trendingApi.getActiveTrending(),
          beauticianApi.getAllAvailableBeauticiansWithAbonnement(),
        ])

        const beauticians = unorderedResult.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))

        if(trending.data.length < 5) {
          const newBeauticians = beauticians.slice(0, 10)
          const randomBeauticians = beauticians.slice(10).sort(() => Math.random() - 0.5).slice(0, 5)

          console.log([...newBeauticians, ...randomBeauticians]
            .filter((value, index, self) => self.findIndex((v) => v.id === value.id) === index))

          setIndependants(
            [
              ...newBeauticians.map(item => ({ ...item, card_status: "news" })),
              ...randomBeauticians
            ]
              .filter((value, index, self) => self.findIndex((v) => v.id === value.id) === index)
          )
        }
        else {
          const trendingIds = trending.data.map((tr) => tr.beautician_id)
          const filteredBeauticians = beauticians.filter((b) => !trendingIds.includes(b.id))
  
          const newBeauticians = filteredBeauticians.slice(0, 5)
  
          const randomBeauticians =
            filteredBeauticians.length > 5
              ? filteredBeauticians.slice(5).sort(() => Math.random() - 0.5).slice(0, 5)
              : []

          setIndependants(
            [
              ...trending.data.slice(0, 2).map(item => ({ ...item, card_status: "trending" })),
              ...newBeauticians.slice(0, 2).map(item => ({ ...item, card_status: "news" })),
              ...trending.data.slice(2, 3).map(item => ({ ...item, card_status: "trending" })),
              ...newBeauticians.slice(2, 5).map(item => ({ ...item, card_status: "news" })),
              ...trending.data.slice(3, 5).map(item => ({ ...item, card_status: "trending" })),
              ...randomBeauticians
            ]
              .filter((value, index, self) => self.findIndex((v) => v.id === value.id) === index)
          )
        }
      } catch (err) {
        setError('Erreur lors du chargement des Professionnels de Beauté.')
        console.error('Erreur:', err)
      } finally {
        setLoading(false)
      }
    }

    fetchBeauticians()
  }, [])

  if (loading) {
    return (
      <div className="beautician-list-container">
        <div className="beautician-grid" style={{ placeItems: 'center' }}>
          <div>Chargement...</div>
        </div>
      </div>
    )
  }

  if (error) {
    return (
      <div className="beautician-list-container">
        <div className="beautician-grid" style={{ placeItems: 'center' }}>
          <div>{error}</div>
        </div>
      </div>
    )
  }

  return (
    <div className="space-y-16">
      {independants.length > 0 ? (
        <CarouselSection items={independants} title="Professionnels de Beauté" />
      ) : (
        <div className="beautician-list-container">
          <div className="beautician-grid" style={{ placeItems: 'center' }}>
            <div>Aucune Professionnel de Beauté disponible pour le moment.</div>
          </div>
        </div>
      )}
    </div>
  )
}

export default BeauticianCardList
