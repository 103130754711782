import React, { useState, useEffect, useContext } from 'react';
import { Table, Button, Modal, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import promoCodesProApi from '../../../../../api/promo_codes_pro.api';
import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
import { showErrorMessage, showSuccessMessage, showWarningMessage } from '../../../../../utils/messageHelper';
import { format } from 'date-fns';
import { BeauticianPortalContext } from '../../../../../context/BeauticianPortalContext';

const PromoCodesPro = () => {

    const {profileData} = useContext(BeauticianPortalContext);

    const [promoCodes, setPromoCodes] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentPromoCode, setCurrentPromoCode] = useState(null);
    const [formData, setFormData] = useState({
        beautician_id: null,
        code: '',
        discount_percentage: '',
        expiration_date: '',
        usage_limit: '',
        beautician_subacategories_id: null
    });
    const token = localStorage.getItem('token');

    useEffect(() => {
        if(profileData) {
            fetchPromoCodes();
            formData.beautician_id = profileData.beautician_id
        }
    }, [profileData]);

    const fetchPromoCodes = async () => {
        try {
            const data = await promoCodesProApi.getAllPromoCodes(token, profileData.beautician_id);
            setPromoCodes(data);
        } catch (error) {
            showErrorMessage('Impossible de récupérer les codes promo');
        }
    };

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const generateRandomCode = () => {
        const randomCode = Math.random().toString(36).substring(2, 10).toUpperCase();
        setFormData({ ...formData, code: randomCode });
    };

    const handleCreate = async () => {

        if(!profileData) {
            showErrorMessage('Impossible de récupérer les données de l\'utilisateur');
            return;
        }

        if (!validateForm()) {
            return;
        }

        try {
            if(!formData.beautician_id){
                formData.beautician_id = profileData.beautician_id;
            }
            const create = await promoCodesProApi.createPromoCode(token, formData);
            if(create.success) {
                showSuccessMessage('Code promo créé avec succès');
                fetchPromoCodes();
                setShowModal(false);
            } else {
                showWarningMessage(create.message);
            }
        } catch (error) {
            showErrorMessage(error.message);
        }
    };

    const handleUpdate = async () => {

        if(!profileData) {
            showErrorMessage('Impossible de récupérer les données de l\'utilisateur');
            return;
        }

        if (!validateForm()) {
            return;
        }
        try {
            if(!formData.beautician_id){
                formData.beautician_id = profileData.beautician_id;
            }
            
            const update = await promoCodesProApi.updatePromoCode(token, currentPromoCode.id, formData);
            if (update.success) {
                showSuccessMessage('Code promo mis à jour avec succès');
                fetchPromoCodes();
                setShowModal(false);
            } else {
                showWarningMessage(update.message);
            }
        } catch (error) {
            showErrorMessage('Échec de la mise à jour du code promo');
        }
    };

    const handleDelete = async (id) => {
        Swal.fire({
            title: 'Êtes-vous sûr?',
            text: "Cette action est irréversible!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, supprimer!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await promoCodesProApi.deletePromoCode(token, id);
                    showSuccessMessage('Le code promo a été supprimé.');
                    fetchPromoCodes();
                } catch (error) {
                    showErrorMessage('Échec de la suppression du code promo');
                }
            }
        });
    };

    const handleShowModal = (promoCode = null) => {
        setCurrentPromoCode(promoCode);
        console.log(formData);
        setFormData(
            promoCode ||
            {
                code: '',
                discount_percentage: '',
                expiration_date: '',
                usage_limit: '',
                beautician_subacategories_id: null
            }
        );
        setShowModal(true);
    };

    // Fonction de validation du formulaire
    // Fonction de validation du formulaire
    const validateForm = () => {
        const { code, discount_percentage, expiration_date, usage_limit, beautician_subacategories_id } = formData;
        const today = new Date();
        const expiration = new Date(expiration_date);

        if (
            code.trim() === '' ||
            discount_percentage === '' ||
            expiration_date === '' ||
            usage_limit === ''
        ) {
            showWarningMessage('Tous les champs sont requis.');
            return false;
        }

        if (code.length < 8) {
            showWarningMessage('Le code doit contenir aux moins 8 caractères');
            return false;
        }

        if (discount_percentage < 0 || discount_percentage > 100) {
            showWarningMessage('Le pourcentage de remise doit être entre 0 et 100.');
            return false;
        }

        if (expiration <= today) {
            showWarningMessage('La date d\'expiration doit être ultérieure à aujourd\'hui.');
            return false;
        }

        if (usage_limit < 1) {
            showWarningMessage('La limite d\'utilisation doit être au moins 1.');
            return false;
        }

        return true;
    };
    return (
        <div className="my-3 p-6 bg-gray-100 rounded-lg">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold text-gray-800 mb-6">Gestion des codes promo</h2>
                <button
                    onClick={() => handleShowModal()}
                    className="px-4 py-2 text-white bg-[#bd193b] flex items-center gap-2 rounded shadow"
                >
                    <FaPlus />
                    Ajouter un Code Promo
                </button>
            </div>

            <Table striped bordered hover responsive className='max-w-100'>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Code</th>
                        <th>Remise</th>
                        <th>Limite d'usage</th>
                        <th>Déjà utilisé</th>
                        <th>Date d'expiration</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody className='max-h-[30vh] overflow-y-scroll'>
                    {promoCodes.map(promo => (
                        <tr key={promo.id}>
                            <td>{promo.id}</td>
                            <td>{promo.code}</td>
                            <td>{promo.discount_percentage}%</td>
                            <td>{promo.usage_limit}</td>
                            <td>{promo.times_used}</td>
                            <td>{format(promo.expiration_date, 'dd/MM/yyyy')}</td>
                            <td className='flex gap-2'>
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => handleShowModal(promo)}
                                >
                                    <FaEdit />
                                </button>
                                <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() => handleDelete(promo.id)}
                                >
                                    <FaTrash />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{currentPromoCode ? 'Modifier le Code Promo' : 'Ajouter un Code Promo'}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='overflow-y-auto'>
                    <Form>
                        <Form.Group>
                            <Form.Label>Code</Form.Label>
                            <div className="d-flex">
                                <Form.Control type="text" name="code" value={formData.code} onChange={handleInputChange} />
                                <Button onClick={generateRandomCode} className="ml-2">Générer</Button>
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Pourcentage de Remise</Form.Label>
                            <Form.Control
                                type="number"
                                name="discount_percentage"
                                value={formData.discount_percentage}
                                onChange={handleInputChange}
                                min="0"
                                max="100"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Date d'Expiration</Form.Label>
                            <Form.Control type="date" name="expiration_date" value={formData.expiration_date} onChange={handleInputChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Limite d'usage</Form.Label>
                            <Form.Control type="number" min="1" name="usage_limit" value={formData.usage_limit} onChange={handleInputChange} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Fermer</Button>
                    <Button variant="primary" onClick={currentPromoCode ? handleUpdate : handleCreate}>
                        {currentPromoCode ? 'Mettre à jour le Code Promo' : 'Créer le Code Promo'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default PromoCodesPro;
