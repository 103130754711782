import React, { useEffect, useState } from 'react';
import { Check, CreditCard, Wallet, Calendar } from 'lucide-react';
import './PaymentOptions.css';
import { useParams } from 'react-router-dom';
import { getBeauticianIdBySlug } from '../../utils/functions';
import beauticianApi from '../../api/beautician.api';

const PaymentOptions = ({ totalAmount, selectedOption, onOptionSelect, depositPercentage, existingPromoCode, oldTotal }) => {
  const depositAmount = (Number(totalAmount) * depositPercentage).toFixed(2);
  const remainingAmount = (totalAmount - depositAmount).toFixed(2);

  const [isLoading, setIsLoading] = useState(true);
  const [paymentMode, setPaymentMode] = useState(null);
  const [error, setError] = useState(null);

  const { slug } = useParams();

  // Fetch payment mode data
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const beauticianId = await getBeauticianIdBySlug(slug);
        if (!beauticianId) throw new Error("Esthéticienne introuvable.");

        const res = await beauticianApi.getBeauticianPaymentMode(beauticianId);
        setPaymentMode(res.data || { full: false, deposit: false, onsite: false });
      } catch (err) {
        setError(err.message || "Une erreur s'est produite lors du chargement.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  // Render loading state
  if (isLoading) {
    return <div className="payment-container p-3">Chargement des options de paiement...</div>;
  }

  // Render error state
  if (error) {
    return <div className="payment-container p-3 error-message">{error}</div>;
  }

  // Render payment options
  return (
    <div className="payment-container p-3">
      <h3 className="payment-title">Options de paiement</h3>
      <div className="payment-options-wrapper">
        {/* Paiement complet */}
        <button
          onClick={() => onOptionSelect('full')}
          className={`payment-card ${selectedOption === 'full' ? 'selected' : ''} payment-full`}
          disabled={!paymentMode?.full}
        >
          {selectedOption === 'full' && (
            <div className="check-icon">
              <Check />
            </div>
          )}
          <div className="payment-content">
            <div className="icon-wrapper full-payment-icon">
              <CreditCard />
            </div>
            <div className="payment-details">
              <div className="payment-label">Paiement</div>
              {existingPromoCode ?
              <div className="payment-amount"><span className='line-through'>{oldTotal}€</span> / {totalAmount}€</div> :
              <div className="payment-amount">{totalAmount}€</div>
              }
            </div>
          </div>
        </button>

        {/* Acompte */}
        <button
          onClick={() => onOptionSelect('deposit')}
          className={`payment-card ${selectedOption === 'deposit' ? 'selected' : ''} payment-deposit`}
          disabled={!paymentMode?.deposit}
        >
          {selectedOption === 'deposit' && (
            <div className="check-icon">
              <Check />
            </div>
          )}
          <div className="payment-content">
            <div className="icon-wrapper deposit-payment-icon">
              <Wallet />
            </div>
            <div className="payment-details">
              <div className="payment-label">Acompte de {depositPercentage * 100}%</div>
              {existingPromoCode ?
              <>
              <div className="payment-amount"><span className='line-through'>{depositAmount}€</span> / {(depositAmount * (1 - (existingPromoCode.discount_percentage / 100))).toFixed(2)}€</div>
              <div className="payment-subtitle">Reste: <span className='line-through'>{remainingAmount}€</span> / {(remainingAmount * (1 - (existingPromoCode.discount_percentage / 100))).toFixed(2)}€</div>
              </> :
              <>
              <div className="payment-amount">{depositAmount}€</div>
              <div className="payment-subtitle">Reste: {remainingAmount}€</div>
              </>
              }
            </div>
          </div>
        </button>

        {/* Payer sur place */}
        <button
          onClick={() => onOptionSelect('onsite')}
          className={`payment-card ${selectedOption === 'onsite' ? 'selected' : ''} payment-onsite`}
          disabled={!paymentMode?.onsite}
        >
          {selectedOption === 'onsite' && (
            <div className="check-icon">
              <Check />
            </div>
          )}
          <div className="payment-content">
            <div className="icon-wrapper onsite-payment-icon">
              <Calendar />
            </div>
            <div className="payment-details">
              <div className="payment-label">Sur place</div>
              {existingPromoCode ?
              <div className="payment-amount"><span className='line-through'>{oldTotal}€</span> / {totalAmount}€</div> :
              <div className="payment-amount">{totalAmount}€</div>
              }
            </div>
          </div>
        </button>
      </div>
    </div>
  );
};

export default PaymentOptions;