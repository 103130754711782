import Logo from "../../assets/images/logoRouge.png"
import { FaPlus } from "react-icons/fa"
import { useEffect } from "react";
import React, { useState } from "react"
import CreateOperatorModal from "./CreateOperatorModal"
import DashboardUserMenu from "./DashboardUserMenu"
import { REDIRECT_AFTER_LOGOUT } from "../../utils/constants"
import { useNavigate } from "react-router-dom";

const Header = ({ createUser, activePage, setActivePage }) => {
    const [showCreateOperatorModal, setShowCreateOperatorModal] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userData, setUserData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        type: '',
    });

    const navigate = useNavigate();

    const handleCloseCreateOperatorModal = () => setShowCreateOperatorModal(false);
    const handleShowCreateOperatorModal = () => setShowCreateOperatorModal(true);

    const getName = () => {
        let name = '';
        if (isAuthenticated) {
            name = userData.first_name + ' ' + userData.last_name;
        }
        return name;
    }

    const handleLogout = () => {
        localStorage.clear();
        setIsAuthenticated(false);
        setUserData({
            first_name: '',
            last_name: '',
            email: '',
            type: '',
        });
        window.location.href = REDIRECT_AFTER_LOGOUT;
    };



    useEffect(() => {
        // Vérifier l'authentification
        const token = localStorage.getItem('token');
        const firstName = localStorage.getItem('firstName');
        const lastName = localStorage.getItem('lastName');
        const userType = localStorage.getItem('userType');
        const email = localStorage.getItem('email');

        if (token && firstName && lastName) {
            setIsAuthenticated(true);
            setUserData({ first_name: firstName, last_name: lastName, email: email, type: userType }); // Inclusion du userType
        }

    }, []);

    return <>
        <nav className="relative px-14 flex items-center justify-between shadow">
            <a href="/">
                <img width="100" src={Logo} alt="Logo" />
            </a>

            <ul className="flex flex-wrap gap-4">

                <li>
                    <span
                        onClick={(e) => {
                            e.preventDefault();
                            navigate('/dashboard');
                            setActivePage("dashboard");
                        }}
                        className={`cursor-pointer anchor ${activePage === 'dashboard' ? 'active' : ''}`}
                    >
                        Tableau de bord
                    </span>
                </li>

                {/* Lien Utilisateurs avec Dropdown */}
                <li className="relative group">
                    <span
                        className={`cursor-pointer anchor ${['all', 'indépendant', 'établissement', 'operator', 'client'].includes(activePage) ? 'active' : ''
                            }`}
                    >
                        Utilisateurs
                    </span>

                    {/* Dropdown */}
                    <ul className="absolute left-0 top-6 hidden group-hover:block bg-white shadow-lg border rounded mt-2 w-48 p-0 z-10">
                        <li
                            className="px-4 py-2 hover:bg-rose-700 hover:text-white cursor-pointer"
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/dashboard/utilisateurs');
                                setActivePage("all");
                            }}
                        >
                            Professionnels
                        </li>
                        {userData.type === 'admin' && (
                            <>
                                <li
                                    className="px-4 py-2 hover:bg-rose-700 hover:text-white cursor-pointer"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate('/dashboard/utilisateurs');
                                        setActivePage("operator");
                                    }}
                                >
                                    Opérateurs
                                </li>
                                <li
                                    className="px-4 py-2  hover:bg-rose-700 hover:text-white cursor-pointer"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate('/dashboard/utilisateurs');
                                        setActivePage("client");
                                    }}
                                >
                                    Clients
                                </li>
                            </>
                        )}
                    </ul>
                </li>

                {/* Lien historiques des tendances */}
                {userData.type === 'admin' && (
                    <>
                        <li>
                            <span
                                onClick={(e) => {
                                    e.preventDefault();
                                    setActivePage("offre-abonnement");
                                    navigate('/dashboard/offres-abonnements');
                                }}
                                className={`cursor-pointer anchor ${activePage === 'offre-abonnement' ? 'active' : ''}`}
                            >
                                Offre et abonnement
                            </span>
                        </li>
                        <li>
                            <span
                                onClick={(e) => {
                                    e.preventDefault();
                                    setActivePage("suivi-des-professionnels");
                                    navigate('/dashboard/suivi-des-professionnels');
                                }}
                                className={`cursor-pointer anchor ${activePage === 'suivi-des-professionnels' ? 'active' : ''}`}
                            >
                                Suivi des professionnels
                            </span>
                        </li>
                        <li>
                            <span
                                onClick={(e) => {
                                    e.preventDefault();
                                    setActivePage("testeurs");
                                    navigate('/dashboard/testeurs');
                                }}
                                className={`cursor-pointer anchor ${activePage === 'testeurs' ? 'active' : ''}`}
                            >
                                Testeurs
                            </span>
                        </li>
                        <li>
                            <span
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate('/dashboard/parametre-accueil');
                                    setActivePage("home-setting");
                                }}
                                className={`cursor-pointer anchor ${activePage === 'home-setting' ? 'active' : ''}`}
                            >
                                Historiques des tendances
                            </span>
                        </li>
                    </>
                )}
            </ul>


            <DashboardUserMenu user_name={getName()} user_email={userData.email} user_type={userData.type} onLogout={handleLogout} />
        </nav>
        {(['all', 'indépendant', 'établissement', 'operator', 'client'].includes(activePage)) &&
            <section className="w-full flex items-center justify-between px-4">
                <div className="flex gap-3 flex-wrap items-center">
                    <button
                        onClick={() => createUser(true)}
                        className="px-4 py-2 text-white bg-[#bd193b] flex items-center gap-2 rounded shadow active:scale-[101%] duration-200"
                    >
                        <FaPlus />
                        {activePage === "client" ? "Créer un client" : "Créer un profil PRO"}
                    </button>

                    {userData.type === "admin" && <>
                        <button
                            onClick={handleShowCreateOperatorModal}
                            className="px-4 py-2 text-white bg-[#bd193b] flex items-center gap-2 rounded shadow active:scale-[101%] duration-200"
                        >
                            <FaPlus />
                            Créer un opérateur
                        </button>
                        <CreateOperatorModal show={showCreateOperatorModal} handleClose={handleCloseCreateOperatorModal} />
                    </>
                    }
                </div>
            </section>}
    </>
}

export default Header