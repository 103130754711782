import React, { useState, useEffect, useRef } from 'react';
import { X, ChevronLeft, ChevronRight } from 'lucide-react';

const ImageGallery = ({ images = [], initialIndex = 0, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const thumbnailsRef = useRef(null);
  const selectedThumbnailRef = useRef(null);

  const currentImage = images[currentIndex];

  useEffect(() => {
    // Faire défiler jusqu'à la vignette sélectionnée
    if (selectedThumbnailRef.current && thumbnailsRef.current) {
      const container = thumbnailsRef.current;
      const thumbnail = selectedThumbnailRef.current;
      
      // Calculer la position de défilement pour centrer la vignette
      const scrollLeft = thumbnail.offsetLeft - (container.clientWidth / 2) + (thumbnail.clientWidth / 2);
      
      container.scrollTo({
        left: scrollLeft,
        behavior: 'smooth'
      });
    }
  }, [currentIndex]);

  if (!images || images.length === 0 || !currentImage) {
    return null;
  }

  const handlePrevious = () => {
    setCurrentIndex((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prev) => (prev === images.length - 1 ? 0 : prev + 1));
  };

  return (
    <div className="fixed inset-4 z-50 flex items-center justify-center">
      <div className="absolute inset-0 bg-black/50 backdrop-blur-sm" onClick={onClose} />
      
      <div className="relative bg-white rounded-2xl shadow-xl max-w-4xl w-full mx-auto overflow-hidden max-h-[80vh]">
        {/* Header */}
        <div className="absolute top-0 left-0 right-0 flex justify-between items-center p-4 bg-gradient-to-b from-black/50 to-transparent z-10">
          <div className="text-white text-sm">
            {currentIndex + 1} / {images.length}
          </div>
          <button 
            onClick={onClose}
            className="text-white hover:text-gray-200 transition-colors"
          >
            <X size={24} />
          </button>
        </div>

        {/* Zone de l'image principale */}
        <div className="relative bg-gray-900 flex items-center justify-center h-[60vh]">
          <img
            src={currentImage.file_url}
            alt={currentImage.file_name}
            className="max-h-full max-w-full object-contain"
          />
          
          {images.length > 1 && (
            <>
              <button 
                onClick={handlePrevious}
                className="absolute left-2 text-white hover:text-gray-200 transition-colors bg-black/20 rounded-full p-2"
              >
                <ChevronLeft size={30} />
              </button>
              
              <button 
                onClick={handleNext}
                className="absolute right-2 text-white hover:text-gray-200 transition-colors bg-black/20 rounded-full p-2"
              >
                <ChevronRight size={30} />
              </button>
            </>
          )}
        </div>

        {/* Vignettes */}
        {images.length > 1 && (
          <div 
            ref={thumbnailsRef}
            className="p-4 bg-white border-t overflow-x-auto whitespace-nowrap"
          >
            <div className="flex gap-2 justify-center min-w-min">
              {images.map((image, index) => (
                <button
                  key={index}
                  ref={index === currentIndex ? selectedThumbnailRef : null}
                  onClick={() => setCurrentIndex(index)}
                  className={`relative flex-shrink-0 w-16 h-16 rounded-lg overflow-hidden 
                    ${currentIndex === index 
                      ? 'ring-2 ring-rose-500' 
                      : 'opacity-70 hover:opacity-100'}`}
                >
                  <img
                    src={image.file_url}
                    alt={image.file_name}
                    className="w-full h-full object-cover"
                  />
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageGallery;