import React from 'react';
import { FileIcon, Download } from 'lucide-react';
import { useMessageGallery } from '../../contexts/MessageGalleryContext';
import UploadProgress from './UploadProgress';

const MessageAttachment = ({ attachment, messageImages, imageIndex }) => {
  const { openGallery } = useMessageGallery();

  // Fonction pour formater la taille du fichier
  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 B';
    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  // Détermine si c'est une image
  const isImage = attachment.file_type?.startsWith('image/');

  // Rendu d'une image
  if (isImage) {
    return (
      <div className="relative max-w-sm rounded-lg overflow-hidden">
        <img 
          src={attachment.file_url} 
          alt={attachment.file_name}
          className="w-full h-auto rounded-lg cursor-pointer hover:opacity-90 transition-opacity"
          onClick={() => openGallery(messageImages, imageIndex)}
        />
        <div className="absolute bottom-0 left-0 right-0 bg-black/50 text-white p-2 text-sm">
          <div className="flex justify-between items-center">
            <span className="truncate">{attachment.file_name}</span>
            <span>{formatFileSize(attachment.file_size)}</span>
          </div>
          {attachment.uploadProgress !== undefined && (
            <UploadProgress progress={attachment.uploadProgress} />
          )}
        </div>
      </div>
    );
  }

  // Rendu d'un fichier non-image
  return (
    <div className="flex items-center gap-3 p-3 bg-gray-50 rounded-lg max-w-sm hover:bg-gray-100 transition-colors">
      <FileIcon className="w-8 h-8 text-gray-400 shrink-0" />
      <div className="flex-1 min-w-0">
        <div className="text-sm font-medium text-gray-900 truncate">
          {attachment.file_name}
        </div>
        <div className="text-xs text-gray-500">
          {formatFileSize(attachment.file_size)}
        </div>
      </div>
      <a 
        href={`/api/messages/attachments/${attachment.id}/download`}
        download={attachment.file_name}
        className="p-2 text-gray-500 hover:text-gray-700 rounded-full hover:bg-gray-200 transition-colors"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Download size={16} />
      </a>
    </div>
  );
};

export default MessageAttachment;