import React from 'react';
import './BeauticianCardPopup.css';
import defaultProfile from '../../assets/images/default-profile.png';
import { BASE_URL } from '../../utils/constants';

const BeauticianCardPopup = ({ beautician }) => {
    const formatPrice = (budgetCategory) => {
        switch(budgetCategory) {
            case 'economic': return '€';
            case 'moderate': return '€€';
            case 'premium': return '€€€';
            default: return '€';
        }
    };

    const getImageUrl = (profilePhoto) => {
        if (!profilePhoto) return defaultProfile;
        return `${BASE_URL}/images/beauticians/${profilePhoto}`;
    };

    return (
        <div className="beautician-popup" style={{ cursor: 'pointer' }}>
            <div className="popup-header">
                <img 
                    src={getImageUrl(beautician.profile_photo)}
                    alt={beautician.business_name || 'Photo profil'} 
                    className="popup-photo"
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = defaultProfile;
                    }}
                />
                <div className="popup-title">
                    <h3>{beautician.business_name || 'Sans nom'}</h3>
                    <div className="popup-subtitle">
                        <span className="price">{formatPrice(beautician.budget_category)}</span>
                        {beautician.average_rating > 0 && (
                            <span className="rating">
                                ★ {beautician.average_rating.toFixed(1)}
                            </span>
                        )}
                    </div>
                </div>
            </div>
            <div className="popup-content">
                {(beautician.specialties?.length > 0 || beautician.category_names?.length > 0) && (
                    <div className="services">
                        {(beautician.specialties || beautician.category_names || [])
                            .slice(0, 2)
                            .map((specialty, index) => (
                                <span key={index} className="service-tag">
                                    {specialty}
                                </span>
                            ))}
                        {(beautician.specialties?.length > 2 || beautician.category_names?.length > 2) && 
                            <span className="service-tag">+</span>
                        }
                    </div>
                )}
            </div>
        </div>
    );
};

export default BeauticianCardPopup;