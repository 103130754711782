// src/components/beautician/ServicesList.js
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Plus } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useBooking } from '../../context/BookingContext';
import './ServicesList.css';
import styles from './PortFolio.module.css';
import { BASE_URL } from '../../utils/constants';
import Swal from 'sweetalert2';
import { AppContext } from '../../contexts/AppContext';
import { truncateText } from '../../utils/stringHelper';


const ServiceImage = ({ imagesList }) => {
  const [images, setImages] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Premier useEffect pour charger les images
  useEffect(() => {
    const preloadImages = async () => {
      setIsLoading(true);
      try {
        const preloaded = await Promise.all(
          imagesList.map(async (image) => {
            const response = await fetch(
              `${BASE_URL}/images/beauticians_subcategories/${encodeURIComponent(image.photo_url)}`
            );
            const blob = await response.blob();
            return URL.createObjectURL(blob);
          })
        );
        setImages(preloaded);
      } catch (error) {
        console.error('Erreur lors du chargement des images:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (imagesList?.length > 0) {
      preloadImages();
    }
  }, [imagesList]);

  // Deuxième useEffect pour le nettoyage des URLs
  useEffect(() => {
    // Cleanup function pour libérer les URLs des images
    return () => {
      images.forEach(url => URL.revokeObjectURL(url));
    };
  }, [images]); // Maintenant images est correctement listé comme dépendance

  // useEffect pour la détection mobile
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const nextSlide = useCallback(() => {
    if (isAnimating || images.length <= 1) return;

    setIsAnimating(true);
    setCurrentSlide(prev => (prev + 1) % images.length);
    setTimeout(() => setIsAnimating(false), 500);
  }, [isAnimating, images.length]);

  const getVisibleImages = useCallback(() => {
    if (images.length === 0) return [];
    if (isMobile) return [images[currentSlide]];
    if (images.length <= 3) return images;

    const visibleImages = [];
    for (let i = 0; i < 3; i++) {
      const index = (currentSlide + i) % images.length;
      visibleImages.push(images[index]);
    }
    return visibleImages;
  }, [images, currentSlide, isMobile]);

  // useEffect pour le slider automatique
  useEffect(() => {
    if (images.length <= (isMobile ? 1 : 3)) return;

    const timer = setInterval(nextSlide, 3000);
    return () => clearInterval(timer);
  }, [images.length, nextSlide, isMobile]);

  return (
    <div>
      {isLoading ? (
        <p>Chargement des images...</p>
      ) : (
        <div className={styles.portfolioWrapper}>
          {images.length > 0 ? (
            <div className={styles.portfolioSlider}>
              <div className={`${styles.portfolioGrid} ${isMobile ? styles.mobileGrid : ''}`}>
                {getVisibleImages().map((imgUrl, index) => (
                  <div key={`${currentSlide}-${index}`} className={styles.portfolioItem}>
                    <div className={styles.imageWrapper}>
                      <img
                        src={imgUrl}
                        alt={`Réalisation ${index + 1}`}
                        className={styles.portfolioImage}
                      />
                    </div>
                  </div>
                ))}
              </div>
              {images.length > (isMobile ? 1 : 3) && (
                <div className={styles.paginationDots}>
                  {Array.from({ length: isMobile ? images.length : Math.ceil(images.length / 3) }).map((_, index) => (
                    <button
                      key={index}
                      className={`${styles.dot} ${(isMobile ? currentSlide : Math.floor(currentSlide / 3)) === index
                        ? styles.activeDot
                        : ''
                        }`}
                    />
                  ))}
                </div>
              )}
            </div>
          ) : (
            <div className={styles.noImages}>
              <p>Aucune image disponible</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

// src/components/beautician/ServicesList.js

const ServiceCategory = ({ title, services }) => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [hoveredServiceIndex, setHoveredServiceIndex] = useState(null);
  const { selectedServices, addService, currentBeauticianSlug } = useBooking();
  const { connectedUser } = useContext(AppContext);

  // État "Voir plus" pour chaque service
  const [seeMore, setSeeMore] = useState(() =>
    services.map(() => false) // Initialiser avec un tableau de false (un par service)
  );

  const isInSelectedServices = (service) => {
    if (connectedUser) {
      if (connectedUser.user_type !== "client") {
        return true;
      }
    }
    return false; // permettre d'ajuter plusieurs même services
    //return selectedServices.some((obj) => obj.beautician_subcategory_id === service.beautician_subcategory_id);
  };

  const handleServiceSelect = (service, e) => {
    e.preventDefault();
    e.stopPropagation();

    if (currentBeauticianSlug && selectedServices.length > 0) {
      if (slug !== currentBeauticianSlug) {
        Swal.fire(
          "Informations",
          "Vous avez déjà une réservation avec un(e) autre professionnel(le), pour faire d'autres réservations, veuillez confirmer ou supprimer ces prestations de votre panier !",
          "info"
        ).then((result) => {
          navigate(`/beauticians/${currentBeauticianSlug}/booking`);
        });
      } else {
        const result = addService(service, slug);
        if (result.success) {
          navigate(`/beauticians/${slug}/booking`);
        }
      }
    } else {
      const result = addService(service, slug);
      if (result.success) {
        navigate(`/beauticians/${slug}/booking`);
      }
    }
  };

  const toggleSeeMore = (index) => {
    setSeeMore((prevSeeMore) =>
      prevSeeMore.map((value, i) => (i === index ? !value : value))
    );
  };

  return (
    <div className="service-category">
      <h2>{title}</h2>
      <div className="service-list">
        {services &&
          services.map((service, index) => (
            <div
              key={service.id || index}
              className="service-item"
              onMouseEnter={() => setHoveredServiceIndex(index)}
              onMouseLeave={() => setHoveredServiceIndex(null)}
            >
              <div className="service-item-info">
                <div className="service-info ps-3">
                  <h3 className="service-name">{service.service_subcategory_name}</h3>
                  <p className="service-description">
                    {seeMore[index]
                      ? service.beautician_subcategory_description
                      : truncateText(service.beautician_subcategory_description, 60)}{" "}
                    {service.beautician_subcategory_description.length > 60 ? (
                      <a
                        href="#voir-plus"
                        onClick={() => toggleSeeMore(index)}
                      >
                        Voir {seeMore[index] ? "moins" : "plus"}
                      </a>
                    ) : (
                      ""
                    )}
                  </p>
                  {service.is_multiple_appointment && (
                    <p className="service-description">
                      Ce service nécessite {service.number_of_appointment} rendez-vous
                    </p>
                  )}
                </div>

                <div className="service-details">
                  <div className="price-duration">
                    <span className="price">{service.beautician_subcategory_price}€</span>
                    <span className="duration">{service.beautician_subcategory_duration} min</span>
                  </div>
                  <button
                    type="button"
                    className="add-button"
                    onClick={(e) => handleServiceSelect(service, e)}
                    disabled={isInSelectedServices(service)}
                  >
                    <Plus size={20} />
                  </button>
                </div>
              </div>

              {hoveredServiceIndex === index && service.images?.length > 0 && (
                <div className="service-images mt-2 flex gap-2">
                  <ServiceImage imagesList={service.images} />
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};


const ServicesList = ({ beauticianPrestations }) => {

  return <>
    {beauticianPrestations.length > 0 &&
      <div className="services-container">
        <h2 className="services-title">Mes prestations</h2>
        {beauticianPrestations.length > 0 ? beauticianPrestations?.map((category) => (
          <ServiceCategory
            key={category.category_id}
            title={category.category_name}
            services={category.items}
          />
        )) : <p>Aucune prestations disponibles!</p>}
      </div>}
  </>
};

export default ServicesList;