import React, { useEffect, useState, useContext } from 'react';  // Un seul import React avec tous les hooks
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet';
import './Concept.css';
import bandeImage from '../../images/hero/bande.png';
import discoverImage from '../../images/hero/Découvrez.png';
import mapImage from '../../images/hero/map.png';
import searchImage from '../../images/hero/1.png'
import colabImage from '../../images/hero/2.png'
import { ServiceCardList } from '../../components/Services/Services';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';  // Import du contexte

const Concept = () => {
  const navigate = useNavigate();
  const [isMounted, setIsMounted] = useState(false);
  const { setActivePageForHeader } = useContext(AppContext);

  useEffect(() => {
    setIsMounted(true);
    window.scrollTo(0, 0);
    
    // Définir l'état du header
    document.body.setAttribute('data-page', 'search');
    setActivePageForHeader("search");

    return () => {
      setIsMounted(false);
      document.body.removeAttribute('data-page');
      setActivePageForHeader("");
    }
  }, [setActivePageForHeader]);
 
  return (
    <>
      <Helmet>
      <title>Notre Concept | PlaniBeauty</title>
      <meta name="description" content="Découvrez comment PlaniBeauty révolutionne la réservation de prestations beauté. Une plateforme simple et intuitive pour connecter clients et professionnels." />
      <link rel="canonical" href="https://www.planibeauty.fr/concept" />
      <meta property="og:title" content="Le Concept PlaniBeauty | Réservation beauté" />
      <meta property="og:description" content="PlaniBeauty connecte clients et professionnels de la beauté. Découvrez notre concept unique." />
      </Helmet>
      <div className={`concept ${isMounted ? "mounted" : ""}`}>
        {/* Hero Section */}
        <div className="bande-section">
          <img src={bandeImage} alt="Beauty services" />
          <div className="bande-overlay"></div>
          <div className="container bande-content">
            <div>
              <h1>Le Concept Planibeauty</h1>
              <p>Découvrez une nouvelle façon de prendre soin de vous</p>
            </div>
          </div>
        </div>

        {/* Premier bloc de contenu */}
        <section className="content-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 mb-4 mb-md-0">
                <LazyLoadImage 
                  src={discoverImage} 
                  alt="Esthéticienne professionnelle"
                  className="img-fluid"
                />
              </div>
              <div className="col-md-6">
                <h2>Découvrez Planibeauty</h2>
                <p className="lead mb-4">
                  Avec Planibeauty, trouvez rapidement un expert beauté de confiance, recommandé par votre entourage.
                </p>
                <p>
                  Notre plateforme vous met en relation avec des prestataires de beauté dont les services ont été validés par des personnes de votre réseau. Fini les recherches interminables du "bon plan beauté" !
                </p>
                <p>
                  Chaque expert est certifié par le label "Linkshor", attestant que ses compétences ont été vérifiées et approuvées par des clients satisfaits. Les parcours professionnels et qualifications sont rigoureusement validés en amont.
                </p>
                <p>
                  Les prestataires sont évalués selon des critères précis, vous permettant de faire votre choix en toute sérénité. Après votre séance, partagez à votre tour votre expérience pour guider la communauté.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Deuxième section */}
        <section className="content-section bg-light-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 order-md-2 mb-4 mb-md-0">
              <LazyLoadImage
                  src={mapImage}
                  alt="Réservation de services beauté"
                  className="img-fluid"
                />
              </div>
              <div className="col-md-6 order-md-1">
                <h2>Une expérience beauté simplifiée</h2>
                <p>
                  Planibeauty facilite votre parcours beauté en vous permettant de trouver et de réserver facilement un prestataire près de chez vous. Notre carte interactive vous permet de localiser les experts du quartier en un clin d'œil, et de consulter les avis clients pour un choix éclairé.
                </p>
                <p>
                  Une fois votre spécialiste sélectionné, prenez rendez-vous directement sur la plateforme, en toute simplicité. Le règlement de votre prestation peut s'effectuer en ligne, pour une expérience fluide et sécurisée.
                </p>
                <p>
                  Avec Planibeauty, profitez d'une mise en relation rapide, pratique et sécurisée pour tous vos besoins esthétiques, sans tracas.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="cta-section">
          <div className="container">
            <h2>Prête à découvrir votre prochaine expérience beauté ?</h2>
            <div className="d-flex justify-content-center gap-3 flex-wrap">
              <a href="#" onClick={(e) => {e.preventDefault(); navigate('/search')}} className="btn-tile">
                <span>
                  <img src={searchImage} />
                </span>
                Trouver un Professionnel de beauté
              </a>
              <a href="#" onClick={(e) => {e.preventDefault(); navigate('/ajouter-etablissement')}} className="btn-tile">
                <span>
                  <img src={colabImage} />
                </span>
                Devenir partenaire
              </a>
            </div>
          </div>
        </section>

        <section className="services-wrapper">
          <ServiceCardList where="concept" />
        </section>

      </div>
    </>
  );
};

export default Concept;