import Swal from 'sweetalert2';

export const conversationStarter = {
    /**
     * Récupérer les conversations existantes
     */
    getExistingConversations: async (token) => {
        const response = await fetch('/api/messages/conversations', {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Erreur lors de la récupération des conversations');
        }

        return await response.json();
    },

    /**
     * Créer une conversation directe entre deux utilisateurs
     */
    createDirectConversation: async (options) => {
        const { 
            sender_id, 
            recipient_id, 
            initial_message_content,
            conversation_title 
        } = options;

        try {
            const token = localStorage.getItem('token');
            
            // Créer la conversation
            const response = await fetch('/api/messages/conversations', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    title: conversation_title,
                    participants: [recipient_id]
                })
            });

            if (!response.ok) {
                throw new Error('Impossible de créer la conversation');
            }

            const conversation = await response.json();

            // Envoyer le message initial si fourni
            if (initial_message_content) {
                const messageResponse = await fetch(`/api/messages/conversations/${conversation.id}/messages`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        content: initial_message_content
                    })
                });

                if (!messageResponse.ok) {
                    throw new Error('Impossible d\'envoyer le message initial');
                }
            }

            return conversation;
        } catch (error) {
            console.error('Erreur lors de la création de la conversation:', error);
            throw error;
        }
    },

    /**
     * Démarrer une conversation avec un beautician
     */
    startBeauticianConversation: async (beautician, connectedUser, navigate) => {
        // Vérifier si l'utilisateur est connecté
        if (!connectedUser) {
            Swal.fire({
                icon: 'info',
                title: 'Connexion requise',
                text: 'Vous devez être connecté pour démarrer une conversation.',
                confirmButtonText: 'Se connecter',
                showCancelButton: true,
                cancelButtonText: 'Annuler'
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/login');
                }
            });
            return;
        }

        // Vérifier que l'utilisateur n'est pas un beautician qui essaie de se parler à lui-même
        if (connectedUser.id === beautician.user_id) {
            Swal.fire({
                icon: 'error',
                title: 'Action non autorisée',
                text: 'Vous ne pouvez pas démarrer une conversation avec vous-même.',
                confirmButtonText: 'OK'
            });
            return;
        }

        try {
            if (!beautician?.user_id) {
                throw new Error('Impossible de trouver le profil utilisateur de l\'esthéticienne');
            }

            // Vérifier si une conversation existe déjà
            const conversations = await conversationStarter.getExistingConversations(localStorage.getItem('token'));
            const existingConversation = conversations.find(conv => 
                conv.name === beautician.business_name
            );

            // Si la conversation existe, rediriger vers celle-ci
            if (existingConversation) {
                navigate('/messaging', { 
                    state: { activeConversationId: existingConversation.id }
                });
                return;
            }

            // Créer une nouvelle conversation
            const newConversation = await conversationStarter.createDirectConversation({
                sender_id: connectedUser.id,
                recipient_id: beautician.user_id,
                conversation_title: beautician.business_name,
                initial_message_content: `Bonjour ${beautician.business_name}, j'aimerais échanger avec vous concernant vos services.`
            });

            // Rediriger vers la nouvelle conversation
            navigate('/messaging', { 
                state: { activeConversationId: newConversation.id }
            });

        } catch (error) {
            console.error('Erreur lors de l\'accès à la messagerie:', error);
            Swal.fire({
                icon: 'error',
                title: 'Erreur',
                text: 'Une erreur est survenue lors de l\'accès à la messagerie.',
                confirmButtonText: 'OK'
            });
        }
    }
};