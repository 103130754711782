import React, { useContext, useEffect, useState } from "react"
import { motion } from "framer-motion"
import notificationApi from "../../../api/notification.api"
import MySpinner from "../../loader/Spinner"
import NotificationItem from "./notifications/NotificationItem"
import NotificationModal from "./notifications/NotificationModal"
import { AppContext } from "../../../contexts/AppContext"

const Notification = () => {
    const [notifications, setNotifications] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [selectedNotification, setSelectedNotification] = useState(null)
    const [filter, setFilter] = useState("all") // 'all', 'read', 'unread'
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [counts, setCounts] = useState({
        total: 0,
        read: 0,
        unread: 0,
    })

    const { setNotificationChangeTrigger } = useContext(AppContext)

    const ITEMS_PER_PAGE = 10 // Nombre d'éléments par page

    // Fonction pour récupérer les notifications
    const fetchNotifications = async () => {
        setIsLoading(true)
        try {
            const userId = localStorage.getItem("userId")
            const token = localStorage.getItem("token")
            const result = await notificationApi.getNotifications({
                userId,
                token,
                filter,
                page: currentPage,
                limit: ITEMS_PER_PAGE,
            })

            setNotifications(result.data.notifications)
            setCounts({
                total: result.data.total_count,
                read: result.data.read_count,
                unread: result.data.unread_count,
            })
            setTotalPages(Math.ceil(result.data.total_count / ITEMS_PER_PAGE))
        } catch (error) {
            console.error("Erreur lors de la récupération des notifications :", error)
        } finally {
            setIsLoading(false)
        }
    }

    // Chargement initial et à chaque changement de filtre ou de page
    useEffect(() => {
        fetchNotifications()
    }, [filter, currentPage])

    const handleNotificationClick = async (notification) => {
        setSelectedNotification(notification)

        if (!notification.is_read) {
            try {
                const token = localStorage.getItem("token")
                await notificationApi.markAsRead(notification.id, token) // Appel API pour marquer comme lu
                setNotifications((prev) =>
                    prev.map((n) =>
                        n.id === notification.id ? { ...n, is_read: true } : n
                    )
                )
                setCounts((prev) => ({
                    ...prev,
                    unread: Number.parseInt(prev.unread) - 1,
                    read: Number.parseInt(prev.read) + 1,
                }))
                //trigger to change notification count 
                setNotificationChangeTrigger(prev => !prev)
            } catch (error) {
                console.error("Erreur lors de la mise à jour du statut :", error)
            }
        }
    }

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage)
        }
    }

    const markAllAsRead = async () => {
        try {
            const token = localStorage.getItem("token")
            const userId = localStorage.getItem("userId")
            await notificationApi.markAllAsRead(userId, token)
            setNotifications((prev) => prev.map((n) => ({ ...n, is_read: true }))) // Marquer toutes les notifications comme lues
            setCounts((prev) => ({ ...prev, unread: 0, read: prev.total }))
            // on met à jour le unread notification count par le triggger
            setNotificationChangeTrigger(prev => !prev)
        } catch (error) {
            console.error("Erreur lors de la mise à jour du statut :", error)
        }
    }

    return (
        <div className="w-full h-full">
            {/* Barre de filtres */}
            <div className="flex flex-col md:flex-row justify-between items-center gap-3 md:gap-6 p-3 bg-gray-50 border-b">
                <div className="flex flex-wrap gap-2">
                    {['all', 'unread', 'read'].map((key, idx) => (
                        <button
                            key={idx}
                            onClick={() => setFilter(key)}
                            className={`flex items-center px-3 py-2 border rounded ${filter === key
                                ? "bg-blue-500 text-white"
                                : "bg-white text-gray-700 border-gray-200"}`
                            }
                        >
                            {key === 'all' && 'Tout'}
                            {key === 'unread' && 'Non lus'}
                            {key === 'read' && 'Lus'}
                            <span className="ml-2 px-2 py-0.5 text-xs bg-red-500 text-white rounded-full">
                                {key === 'all' ? counts.total : key === 'unread' ? counts.unread : counts.read}
                            </span>
                        </button>
                    ))}
                </div>
                <button className="text-blue-600 px-3 py-1 border rounded" onClick={markAllAsRead}>
                    Marquer tout comme lu
                </button>
            </div>

            {/* Liste des notifications */}
            {isLoading ? (
                <MySpinner height="70vh" />
            ) : (
                <div className="min-h-[72vh] flex flex-col gap-3 p-3">
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        className="overflow-y-auto"
                    >
                        {notifications.length > 0 ? (
                            notifications.map((notification) => (
                                <NotificationItem
                                    key={notification.id}
                                    notification={notification}
                                    onClick={handleNotificationClick}
                                />
                            ))
                        ) : (
                            <div className="text-center text-gray-500 mt-4">
                                Aucun notification disponible.
                            </div>
                        )}
                    </motion.div>

                    {/* Pagination */}
                    <div className="flex justify-center items-center gap-2 mt-4">
                        <button
                            disabled={currentPage === 1}
                            onClick={() => handlePageChange(currentPage - 1)}
                            className={`px-3 py-1 border rounded ${currentPage === 1 ? "text-gray-400" : "text-blue-600"}`}
                        >
                            Précédent
                        </button>
                        <span className="text-gray-700">
                            Page {currentPage} sur {totalPages}
                        </span>
                        <button
                            disabled={currentPage === totalPages}
                            onClick={() => handlePageChange(currentPage + 1)}
                            className={`px-3 py-1 border rounded ${currentPage === totalPages ? "text-gray-400" : "text-blue-600"}`}
                        >
                            Suivant
                        </button>
                    </div>
                </div>
            )}

            {/* Modal */}
            {selectedNotification && (
                <NotificationModal
                    notification={selectedNotification}
                    onClose={() => setSelectedNotification(null)}
                />
            )}
        </div>
    )
}

export default Notification
