import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Swal from 'sweetalert2';
import abonnementApi from '../../../../../api/abonnement.api';
import { showSuccessMessage } from '../../../../../utils/messageHelper';

const PaymentForm = ({ 
    userId,
    offre, 
    setReloadLocalTrigger,
    onCloseForm, 
    method, 
    profileData, 
    existingPromoCode 
}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState({});

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        // address: {
        //     line1: '',
        //     city: '',
        //     postal_code: '',
        //     country: 'FR',
        // }
    });

    const validateForm = () => {
        const newErrors = {};

        // Name validation
        if (!formData.name.trim()) {
            newErrors.name = 'Le nom complet est requis';
        } else if (formData.name.trim().length < 2) {
            newErrors.name = 'Le nom doit contenir au moins 2 caractères';
        }

        // Email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.email.trim()) {
            newErrors.email = 'L\'email est requis';
        } else if (!emailRegex.test(formData.email)) {
            newErrors.email = 'Veuillez entrer un email valide';
        }

        // Address validations
        // if (!formData.address.line1.trim()) {
        //     newErrors.line1 = 'L\'adresse est requise';
        // }

        // if (!formData.address.city.trim()) {
        //     newErrors.city = 'La ville est requise';
        // }

        // const postalCodeRegex = /^\d{5}$/;
        // if (!formData.address.postal_code.trim()) {
        //     newErrors.postal_code = 'Le code postal est requis';
        // } else if (!postalCodeRegex.test(formData.address.postal_code)) {
        //     newErrors.postal_code = 'Le code postal doit contenir 5 chiffres';
        // }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        // if (name.includes('address.')) {
        //     setFormData(prev => ({
        //         ...prev,
        //         address: {
        //             ...prev.address,
        //             [name.split('.')[1]]: value
        //         }
        //     }));
        //     // Clear specific address error when typing
        //     if (errors[name.split('.')[1]]) {
        //         setErrors(prev => ({ ...prev, [name.split('.')[1]]: '' }));
        //     }
        // } else {
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
            // Clear specific error when typing
            if (errors[name]) {
                setErrors(prev => ({ ...prev, [name]: '' }));
            }
        // }
    };

    const handlePayment = async (e) => {
        e.preventDefault();

        // Validate form before proceeding
        if (!validateForm()) {
            return;
        }

        Swal.fire({
            icon: 'question',
            title: 'Confirmer l\'abonnement?',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Oui, confirmer",
            cancelButtonText: "Non"
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true);
                setMessage('');

                try {
                    const token = localStorage.getItem('token');

                    const data = {
                        userId: userId,
                        offre_id: offre.id,
                        offre_name: offre.name,
                        offre_pricing: offre.price_per_month,
                        is_trial: method === "free-trial" ? true : false,
                        trial_days: 10,
                        promo_code_id: (method === "code-promo" && existingPromoCode) ? existingPromoCode.id : null,
                        existingPromoCode: existingPromoCode ? existingPromoCode : null,
                        beautician_id: profileData.beautician_id,
                        user_info: {
                            name: formData.name,
                            email: formData.email,
                            // address: formData.address.line1,
                            // city: formData.address.city,
                            // postal_code: formData.address.postal_code,
                            // country: formData.address.country
                        },
                        paymentMethodId: null,
                    }

                    if(method === "free-trial" || (method === "code-promo" && existingPromoCode.discount_percentage === 0)) {
                        const cardElement = elements.getElement(CardElement);
                        const { error, paymentMethod } = await stripe.createPaymentMethod({
                            type: 'card',
                            card: cardElement,
                            billing_details: {
                                name: formData.name,
                                email: formData.email,
                                // address: {
                                //     line1: document.getElementById('address').value,
                                //     postal_code: document.getElementById('postal-code').value,
                                //     city: document.getElementById('city').value,
                                //     country: 'FR',
                                // },
                            },
                        });

                        if(error) {
                            setMessage(error.message);
                            setLoading(false);
                            return;
                        }

                        data.paymentMethodId = paymentMethod.id;
                    }

                    const { clientSecret } = await abonnementApi.createSubscription(token, data);


                    if ((method === "achat-normal" || (method === "code-promo" && existingPromoCode.discount_percentage !== 100)) && clientSecret) {
                        const cardElement = elements.getElement(CardElement);

                        const result = await stripe.confirmCardPayment(clientSecret, {
                            payment_method: {
                                card: cardElement,
                                billing_details: {
                                    name: formData.name,
                                    email: formData.email,
                                    // address: {
                                    //     line1: formData.address.line1,
                                    //     city: formData.address.city,
                                    //     postal_code: formData.address.postal_code,
                                    //     country: formData.address.country
                                    // }
                                }
                            },
                        });

                        if (result.error) {
                            setMessage(result.error.message);
                        }
                    }

                    setMessage('');
                    onCloseForm();
                    //setReloadLocalTrigger(prev => !prev);
                    showSuccessMessage("Votre abonnement a été réalisé avec succès. La page sera automatiquement actualisée dans quelques instants.");
                    
                    setTimeout(() => {
                        window.location.reload();
                    }, 4000);
                    
                } catch (error) {
                    setMessage(error.message);
                } finally {
                    setLoading(false);
                }
            }
        })
    };

    return (
        <form onSubmit={handlePayment} className="space-y-2">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-0">
                <div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-2">Nom Complet</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        className={`w-full px-3 py-2 border ${errors.name ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 ${errors.name ? 'focus:ring-red-500' : 'focus:ring-blue-500'}`}
                        placeholder="Votre nom complet"
                    />
                    {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
                </div>
                <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-2">Email</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        className={`w-full px-3 py-2 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 ${errors.email ? 'focus:ring-red-500' : 'focus:ring-blue-500'}`}
                        placeholder="votre.email@example.com"
                    />
                    {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
                </div>
            </div>

            {/*<div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-0">
                <div>
                    <label htmlFor="address.line1" className="block text-sm font-medium text-gray-700 mb-2">Adresse</label>
                    <input
                        type="text"
                        name="address.line1"
                        value={formData.address.line1}
                        onChange={handleInputChange}
                        className={`w-full px-3 py-2 border ${errors.line1 ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 ${errors.line1 ? 'focus:ring-red-500' : 'focus:ring-blue-500'}`}
                        placeholder="Numéro et rue"
                    />
                    {errors.line1 && <p className="text-red-500 text-xs mt-1">{errors.line1}</p>}
                </div>
                <div>
                    <label htmlFor="address.city" className="block text-sm font-medium text-gray-700 mb-2">Ville</label>
                    <input
                        type="text"
                        name="address.city"
                        value={formData.address.city}
                        onChange={handleInputChange}
                        className={`w-full px-3 py-2 border ${errors.city ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 ${errors.city ? 'focus:ring-red-500' : 'focus:ring-blue-500'}`}
                        placeholder="Votre ville"
                    />
                    {errors.city && <p className="text-red-500 text-xs mt-1">{errors.city}</p>}
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-0">
                <div>
                    <label htmlFor="address.postal_code" className="block text-sm font-medium text-gray-700 mb-2">Code Postal</label>
                    <input
                        type="text"
                        name="address.postal_code"
                        value={formData.address.postal_code}
                        onChange={handleInputChange}
                        className={`w-full px-3 py-2 border ${errors.postal_code ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 ${errors.postal_code ? 'focus:ring-red-500' : 'focus:ring-blue-500'}`}
                        placeholder="Code postal"
                    />
                    {errors.postal_code && <p className="text-red-500 text-xs mt-1">{errors.postal_code}</p>}
                </div>
                <div>
                    <label htmlFor="address.country" className="block text-sm font-medium text-gray-700 mb-2">Pays</label>
                    <input
                        type="text"
                        name="address.country"
                        value={formData.address.country}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Pays"
                    />
                </div>
            </div>*/}

            <div className="mt-4">
                <label htmlFor="" className="block text-sm font-medium mb-2 text-gray-700">Informations de la carte :</label>
                <CardElement
                    options={{
                        style: {
                            base: {
                                fontSize: '16px',
                                color: '#424770',
                                '::placeholder': {
                                    color: '#aab7c4',
                                },
                            },
                            invalid: {
                                color: '#9e2146',
                            },
                        },
                    }}
                    className="p-3 border rounded"
                />
            </div>

            <button
                type="submit"
                disabled={!stripe || loading}
                className="w-full mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
                {loading ? 'Traitement...' : `Payer ${
                    method === "free-trial" 
                    ? offre.price_per_month 
                    : ((method === "code-promo" && existingPromoCode) 
                        ? ((1 - (existingPromoCode.discount_percentage / 100)) * (offre.price_per_month * existingPromoCode.months_valid)) 
                        : offre.price_per_month)
                } EURO`}
            </button>

            {message && <p className="mt-4 text-red-500">{message}</p>}
        </form>
    );
};

export default PaymentForm;