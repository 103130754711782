// src/api/beautician.api.js
import { API_URL } from "../utils/constants"

const trendingApi = {
    getAllTrending: async () => {
        try {
            let url = `${API_URL}/trending/all`

            const response = await fetch(url)

            return await response.json()
        } catch (error) {
            console.error(error.message);
            throw error
        }
    },
    getActiveTrending: async () => {
        try {
            let url = `${API_URL}/trending`

            const response = await fetch(url)

            return await response.json()
        } catch (error) {
            console.error(error.message);
            throw error
        }
    },
    getTrendingByWeek: async (week) => {
        try {
            let url = `${API_URL}/trending/${week}`

            const response = await fetch(url)

            return await response.json()
        } catch (error) {
            console.error(error.message);
            throw error
        }
    },
    getTrendingByBeauticianId: async(id) => {
        try {
            let url = `${API_URL}/trending/beautician/${id}`

            const response = await fetch(url)

            return await response.json()
        } catch (error) {
            console.error(error.message);
            throw error
        }
    },
    addToTrending: async(beautician_id, week_start) => {
        try {
            let url = `${API_URL}/trending`

            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ beautician_id, week_start })
            })

            return await response.json()
        } catch (error) {
            console.error(error.message);
            throw error
        }
    },
    removeTrending: async(trending_id) => {
        try {
            let url = `${API_URL}/trending/${trending_id}`

            const response = await fetch(url, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json"
                }
            })

            return await response.json()
        } catch (error) {
            console.error(error.message);
            throw error
        }
    }
}

export default trendingApi;