import React, { useContext, useEffect, useState } from 'react';
import {
  User,
  Shield,
  Clock,
  CreditCard,
  ChevronRight,
  Euro,
} from 'lucide-react';
import './BeauticianSettings.css';
import AvailabilitySettings from './settings/AvailabilitySettings';
import PaymentModeSettings from './settings/PaymentModeSettings';
import SecuritySettings from './settings/SecuritySettings';
import ProfileSettings from './settings/ProfileSettings';
import PromoCodesPro from './settings/promo-codes/PromoCodesPro';
import AbonnementSettings from './settings/AbonnementSettings';
import { BeauticianPortalContext } from '../../../context/BeauticianPortalContext';
import { useLocation, useNavigate } from 'react-router-dom';

const BeauticianSettings = () => {
  const location = useLocation();
  const { abonnementActive } = useContext(BeauticianPortalContext);
  const [activeSection, setActiveSection] = useState(abonnementActive ? 'profile' : 'security');

  const navigate = useNavigate();
  const addQueryParam = (key, value) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    navigate(`${window.location.pathname}?${searchParams.toString()}`);
  };

  const handleClickSettingSection = (section) => {
    setActiveSection(section);
    addQueryParam('active', section);
  }


  useEffect(() => {
    if (location.search) {
      let params_array = location.search.split("=");
      if (params_array.length >= 2) {
        setActiveSection(params_array[1]);
        addQueryParam('active', params_array[1]);
      }
    }
  }, [location]);

  let settingsSections = [
    {
      id: 'security',
      title: 'Sécurité et confidentialité',
      icon: <Shield size={20} />,
      description: 'Gérez la sécurité de votre compte'
    },
    {
      id: 'abonnement',
      title: 'Abonnements',
      icon: <CreditCard size={20} />,
      description: 'Gérez vos abonnements mensuel'
    }
  ];

  if (abonnementActive) {
    settingsSections = [
      {
        id: 'profile',
        title: 'Gérer les informations',
        icon: <User size={20} />,
        description: 'Gérez vos informations personnelles et professionnelles'
      },
      {
        id: 'payment_mode',
        title: 'Mode de paiement',
        icon: <Euro size={20} />,
        description: 'Configurez vos préférences de mode de paiement'
      },
      {
        id: 'security',
        title: 'Sécurité et confidentialité',
        icon: <Shield size={20} />,
        description: 'Gérez la sécurité de votre compte'
      },
      {
        id: 'availability',
        title: 'Disponibilités',
        icon: <Clock size={20} />,
        description: 'Définissez vos horaires de travail'
      },
      {
        id: 'code_promo',
        title: 'Code promo',
        icon: <Clock size={20} />,
        description: 'Géréz vos codes promos'
      },
      {
        id: 'abonnement',
        title: 'Abonnements',
        icon: <CreditCard size={20} />,
        description: 'Gérez vos abonnements mensuel'
      }
    ];
  }

  const renderSettingsContent = () => {
    switch (activeSection) {
      case 'profile':
        return (
          <ProfileSettings />
        );

      case 'payment_mode':
        return (
          <PaymentModeSettings />
        );

      case 'security':
        return (
          <SecuritySettings />
        );

      case 'availability':
        return (
          <AvailabilitySettings />
        );

      case 'code_promo':
        return (
          <PromoCodesPro />
        );

      case 'abonnement':
        return (
          <AbonnementSettings />
        );
      default:
        return null;
    }
  };

  return (
    <div className="settings-container">
      {/* Navigation des paramètres */}
      <div className="settings-navigation">
        {settingsSections.map((section) => (
          <button
            key={section.id}
            className={`settings-nav-item ${activeSection === section.id ? 'active' : ''}`}
            onClick={() => handleClickSettingSection(section.id)}
          >
            <div className="nav-item-icon">{section.icon}</div>
            <div className="nav-item-content">
              <h3>{section.title}</h3>
              <p>{section.description}</p>
            </div>
            <ChevronRight size={20} className="nav-item-arrow" />
          </button>
        ))}
      </div>

      {/* Contenu des paramètres */}
      <div className="settings-content">
        <div className="settings-header">
          <h2>{settingsSections.find(s => s.id === activeSection)?.title}</h2>
        </div>
        {renderSettingsContent()}
      </div>
    </div>
  );
};

export default BeauticianSettings;