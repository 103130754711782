import { API_URL } from "../utils/constants";

const notificationApi = {
    getNotifications: async ({ userId, token, filter = "all", page = 1, limit = 10 }) => {
        try {
            const queryParams = new URLSearchParams({
                filter,       // Filtre ('all', 'read', 'unread')
                page,         // Page actuelle
                limit,        // Nombre de notifications par page
            }).toString();

            const result = await fetch(`${API_URL}/notifications/user/${userId}?${queryParams}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });

            if (!result.ok) {
                throw new Error(`Erreur HTTP: ${result.status}`);
            }

            return await result.json(); // Retourne un objet avec les notifications et les counts
        } catch (error) {
            console.error("Erreur lors de la récupération des notifications :", error);
            throw new Error(error);
        }
    },

    countBeauticianUnreadNotification: async (userId, token) => {
        try {
            const result = await fetch(`${API_URL}/notifications/user/${userId}/count-unread`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });

            return await result.json();
        } catch (error) {
            console.error("Erreur lors du comptage des notifications non lues :", error);
            throw new Error(error);
        }
    },

    markAsRead: async (id, token) => {
        try {
            const result = await fetch(`${API_URL}/notifications/${id}/read`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            return await result.json();
        } catch (error) {
            throw new Error(error);
        }
    },

    markAllAsRead: async (userId, token) => {
        try {
            const result = await fetch(`${API_URL}/notifications/user/${userId}/read-all`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            return await result.json();
        } catch (error) {
            throw new Error(error);
        }
    }
}


export default notificationApi;