import React, { useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import beauticianApi from "../../../../api/beautician.api";
import { formatTime } from "../../../../utils/functions";
import { format } from "date-fns";
import { fr } from 'date-fns/locale';
import './style.css';

const ShowMonthDetailsModal = ({ show, handleClose, selectedMonth, selectedPaymentType, beautician_id }) => {

    const [data, setData] = useState([]);

    const fetchRevenueInfoByMonth = async (beautician_id, month, payment_type) => {
        try {
            const response = await beauticianApi.getAppointmentInfoByMonthAndBeauticianId(localStorage.getItem('token'), beautician_id, month, payment_type);
            if (response.success) {
                setData(response.data);
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (beautician_id && selectedMonth && selectedPaymentType) {
            fetchRevenueInfoByMonth(beautician_id, selectedMonth, selectedPaymentType);
        }
    }, [beautician_id, selectedMonth, selectedPaymentType]);

    const PAYMENT_TYPE = {
        full: 'Paiement complet',
        deposit: 'Acompte',
        onsite: 'Paiement sur site',
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            dialogClassName="second-modal"
            backdropClassName="second-modal-backdrop">
            <Modal.Header closeButton>
                <Modal.Title>
                    Détails revenus {new Date(selectedMonth).toLocaleString('default', { month: 'long', year: 'numeric' })} | {PAYMENT_TYPE[selectedPaymentType]}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="overflow-auto">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Date rendez-vous</th>
                            <th>Heure</th>
                            <th>Nom du client</th>
                            <th>Services</th>
                            {selectedPaymentType !== "onsite" && <th>Total payé</th>}
                            <th>Total coût</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.length > 0 ? (
                            data.map((appointment, index) => (
                                <tr key={appointment.id}>
                                    <td>{index + 1}</td>
                                    <td>{format(new Date(appointment.appointment_date), 'd MMMM yyyy', { locale: fr })}</td>
                                    <td>{formatTime(appointment.start_time)} - {formatTime(appointment.end_time)}</td>
                                    <td>{`${appointment.first_name} ${appointment.last_name}`}</td>
                                    <td>
                                        {appointment.services.map(service => (
                                            <div key={service.appointment_service_id}>
                                                {service.subcategory_name} - {service.price_at_booking} €
                                            </div>
                                        ))}
                                    </td>
                                    {appointment.payment_type !== "onsite" && <td>{appointment.payed} €</td>}
                                    <td>{appointment.total_prices} €</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="7" className="text-center">Aucune donnée disponible.</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Modal.Body>
        </Modal>
    );
};

export default ShowMonthDetailsModal;