import { API_URL } from "../utils/constants"

const emailApi = {
    sendEmailNotification : async (to, subject, name, message, link, link_text) => {
        try {
            const send = await fetch(`${API_URL}/email/send-email-notification`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    to: to,
                    subject: subject,
                    name: name,
                    message: message,
                    link: link,
                    link_text: link_text
                })
            });
            if (!send.ok) {
                throw new Error('Erreur lors de l\'envoie de l\'email!');
            }

            return await send.json();
        } catch(error) {
            throw new Error('Impossible d\'envoyer l\'email!');
        }
    },

    sendEmailAccountStatusNotification : async (to, subject, name, message) => {
        try {
            const send = await fetch(`${API_URL}/email/send-email-account-status-notification`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    to: to,
                    subject: subject,
                    name: name,
                    message: message
                })
            });
            if (!send.ok) {
                throw new Error('Erreur lors de l\'envoie de l\'email!');
            }

            return await send.json();
        } catch(error) {
            throw new Error('Impossible d\'envoyer l\'email!');
        }
    },

    sendEmailRegistrationConfirmation : async (to, password) => {
        try {
            const send = await fetch(`${API_URL}/email/send-email-registration-confirmation`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    to: to,
                    subject: "Création de compte éstheticienne",
                    password: password,
                })
            });
            if (!send.ok) {
                throw new Error('Erreur lors de l\'envoie de l\'email!');
            }

            return await send.json();
        } catch(error) {
            throw new Error('Impossible d\'envoyer l\'email!');
        }
    },

    sendEmailRegistrationRequest : async (data) => {
        try {
            const send = await fetch(`${API_URL}/email/send-email-registration-request`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            });
            if (!send.ok) {
                throw new Error('Erreur lors de l\'envoie de l\'email!');
            }

            return await send.json();
        } catch(error) {
            throw new Error('Impossible d\'envoyer l\'email!');
        }
    },

    sendEmailInstructionsFreeTrial : async (to, name) => {
        try {
            const send = await fetch(`${API_URL}/email/send-email-instructions-free-trial`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    to: to,
                    name: name
                })
            });
            if (!send.ok) {
                throw new Error('Erreur lors de l\'envoie de l\'email!');
            }

            return await send.json();
        } catch(error) {
            throw new Error('Impossible d\'envoyer l\'email!');
        }
    },

    sendEmailInstructionsCodePromo : async (to, name, code) => {
        try {
            const send = await fetch(`${API_URL}/email/send-email-instructions-code-promo`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    to: to,
                    name: name,
                    code: code
                })
            });
            if (!send.ok) {
                throw new Error('Erreur lors de l\'envoie de l\'email!');
            }

            return await send.json();
        } catch(error) {
            throw new Error('Impossible d\'envoyer l\'email!');
        }
    },
}

export default emailApi;