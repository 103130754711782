import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight, CalendarDays, CalendarRange, Calendar, Clock } from 'lucide-react';
import { 
  format, 
  addDays, 
  startOfWeek, 
  endOfWeek, 
  addWeeks,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  eachHourOfInterval,
  isSameDay,
  addMonths,
  getMonth,
  getYear,
  parseISO
} from 'date-fns';
import { fr } from 'date-fns/locale';
import './ModernCalendar.css';

const ModernCalendar = ({ events, onEventClick }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [view, setView] = useState('day'); // 'day', 'week', 'month'

  const generateTimeSlots = () => {
    const slots = [];
    for (let hour = 6; hour < 22; hour++) {
      slots.push(new Date().setHours(hour, 0, 0));
    }
    return slots;
  };
  
  // Générer les créneaux horaires de 5h à 23h
  const timeSlots = generateTimeSlots();

  const getEventsForTimeSlot = (date, hour) => {
    return events.filter(event => {
      const eventStart = new Date(event.start);
      const eventEnd = new Date(event.end);
      const slotTime = new Date(date).setHours(hour, 0, 0);
      
      return (
        isSameDay(eventStart, date) && 
        eventStart.getHours() === hour
      );
    });
  };

  const getEventsForDate = (date) => {
    return events.filter(event => {
      const eventDate = new Date(event.start);
      return isSameDay(eventDate, date);
    });
  };

  // Obtenir les jours de la semaine
  const weekDays = Array.from({ length: 7 }).map((_, index) => 
    addDays(startOfWeek(currentDate, { weekStartsOn: 1 }), index)
  );

  // Obtenir les jours du mois
  const getMonthDays = () => {
    const start = startOfWeek(startOfMonth(currentDate), { weekStartsOn: 1 });
    const end = endOfWeek(endOfMonth(currentDate), { weekStartsOn: 1 });
    return eachDayOfInterval({ start, end });
  };

  const navigateDate = (direction) => {
    switch (view) {
      case 'day':
        setCurrentDate(prev => addDays(prev, direction === 'prev' ? -1 : 1));
        break;
      case 'week':
        setCurrentDate(prev => addWeeks(prev, direction === 'prev' ? -1 : 1));
        break;
      case 'month':
        setCurrentDate(prev => addMonths(prev, direction === 'prev' ? -1 : 1));
        break;
    }
  };

  
  const EventCard = ({ event, isCompact = false }) => {
    const calculateDuration = () => {
      const [startHour, startMin] = event.start_time.split(':').map(Number);
      const [endHour, endMin] = event.end_time.split(':').map(Number);
      return ((endHour * 60 + endMin) - (startHour * 60 + startMin));
    };
  
    const calculateTop = () => {
      const [hour, minute] = event.start_time.split(':').map(Number);
      return (hour - 6) * 24 + (minute / 60) * 24;
    };
  
    const calculateHeight = () => {
      const duration = calculateDuration();
      return (duration / 60) * 24;
    };
  
    const getInitials = (fullName) => {
      if (!fullName || typeof fullName !== 'string') return '';
      const words = fullName.trim().split(/\s+/);
      if (words.length >= 2) {
        return `${words[0][0]}${words[words.length - 1][0]}`.toUpperCase();
      }
      return words[0][0].toUpperCase();
    };
  
    return (
      <div 
        className={`
          absolute 
          ${isCompact 
            ? 'w-full'
            : view === 'week' 
              ? 'w-[90%] ml-[5%]' 
              : 'w-[80%] ml-[10%]'
          }
          bg-gradient-to-r from-blue-50 to-blue-100
          hover:from-blue-100 hover:to-blue-200
          border border-blue-200
          rounded-md cursor-pointer
          transition-colors
          shadow-sm hover:shadow-md
          ${isCompact ? 'p-1' : 'event-card'}
          overflow-hidden
        `}
        style={{
          top: !isCompact ? `${calculateTop()}px` : 'auto',
          height: !isCompact ? `${calculateHeight()}px` : 'auto',
          zIndex: 30
        }}
        onClick={(e) => {
          e.stopPropagation();
          onEventClick(event);
        }}
      >
        <div className="flex items-center space-x-2 w-full">
          {/* Bulle avec taille fixe */}
          {/* <div className="w-2 h-2 rounded-full bg-blue-500 flex-none  md:w-1 h-1"></div> */}
          <div className="bulle"></div>
          {/* Nom complet en mode normal, initiales en mode compact */}
          <div className={`font-medium text-blue-800 truncate text-sm`}>
            <span className="hidden md:inline">{event.title}</span>
            <span className="md:hidden">{getInitials(event.title)}</span>
          </div>
          
          {/* L'heure */}
          <div className={`flex-shrink-0 flex items-center gap-1 text-blue-600 ${isCompact ? 'text-xs' : 'text-sm'}`}>
            <Clock className="w-3 h-3" />
            <span>{event.start_time.slice(0, 5)} - {event.end_time.slice(0, 5)}</span>
          </div>
          
          {/* Les services */}
          <div className={`text-blue-600 truncate ${isCompact ? 'text-xs' : 'text-sm'}`}>
            {event.services?.map(s => s.subcategory_name).join(' • ')}
          </div>
        </div>
      </div>
    );
  };

  const renderDayView = () => (
    <div className="grid grid-cols-1 h-full overflow-y-auto">
      <div className="relative">  {/* Conteneur principal avec position relative */}
        <div className="sticky top-0 bg-white z-20">
          {/* En-tête si nécessaire */}
        </div>
        <div className="relative"> {/* Conteneur des heures */}
          {timeSlots.map((time, index) => {
            const timeDate = new Date(time);
            return (
              <div 
                key={time.toString()} 
                className="grid grid-cols-12 group h-6 border-b border-gray-100"
              >
                <div className="col-span-1 pr-2 text-right text-sm text-gray-500 sticky left-0 bg-white z-10 border-r border-gray-200">
                  {format(timeDate, 'HH:mm')}
                </div>
                <div className="col-span-11 relative">
                  <div className="absolute inset-0 border-b border-dashed border-gray-100"></div>
                </div>
              </div>
            );
          })}
  
          {/* Couche des événements */}
          <div className="absolute inset-0">
            {events
              .filter(event => isSameDay(new Date(event.start), currentDate))
              .map(event => (
                <EventCard 
                  key={event.id} 
                  event={event}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );

  const renderWeekView = () => (
    <div className="h-full overflow-y-auto">
      {/* En-tête des jours de la semaine */}
      <div className="grid grid-cols-8 border-b sticky top-0 bg-white z-20">
        <div className="p-2 text-sm text-gray-500 border-r"></div>
        {weekDays.map(day => (
          <div 
            key={day.toString()}
            className={`p-2 text-center border-l ${
              isSameDay(day, new Date()) ? 'bg-blue-50' : ''
            }`}
          >
            <div className="text-sm font-medium">
              {format(day, 'EEE', { locale: fr })}
            </div>
            <div className={`text-xs ${
              isSameDay(day, new Date()) ? 'text-blue-600' : 'text-gray-500'
            }`}>
              {format(day, 'd MMM')}
            </div>
          </div>
        ))}
      </div>
  
      {/* Grille des heures et événements */}
      <div className="relative">
        <div className="grid grid-cols-8">
          {/* Colonne des heures */}
          <div className="col-span-1">
            {timeSlots.map(time => (
              <div key={time} className="h-6 text-right pr-2 text-sm text-gray-500 sticky left-0 bg-white z-10 border-r border-gray-200">
                {format(new Date(time), 'HH:mm')}
              </div>
            ))}
          </div>
          
          {/* Colonnes des jours */}
          {weekDays.map(day => (
            <div key={day.toString()} className="col-span-1 relative">
              {/* Lignes des heures */}
              {timeSlots.map(time => (
                <div key={time} className="h-6 border-b border-gray-100 relative">
                  <div className="absolute inset-0 border-l border-dashed border-gray-100"></div>
                </div>
              ))}
              
              {/* Événements du jour */}
              <div className="absolute inset-0">
                {events
                  .filter(event => isSameDay(new Date(event.start), day))
                  .map(event => (
                    <EventCard 
                      key={event.id} 
                      event={event}
                    />
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const renderMonthView = () => {
    const monthDays = getMonthDays();
    const currentMonth = getMonth(currentDate);
    const currentYear = getYear(currentDate);

    return (
      <div className="h-full overflow-y-auto flex flex-col">
        <div className="grid grid-cols-7 text-center border-b">
          {['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'].map(day => (
            <div key={day} className="p-2 text-sm font-medium text-gray-600">
              {day}
            </div>
          ))}
        </div>
        <div className="grid grid-cols-7 auto-rows-fr divide-x divide-y flex-1">
          {monthDays.map(day => {
            const dayEvents = getEventsForDate(day);
            const isCurrentMonth = getMonth(day) === currentMonth && getYear(day) === currentYear;

            return (
              <div 
                key={day.toString()} 
                className={`min-h-16 p-1 ${isCurrentMonth ? 'bg-white' : 'bg-gray-50'} cursor-pointer hover:bg-gray-50 transition-colors`}
                onClick={() => {
                  setCurrentDate(day);
                  setView('day');
                }}
              >
                <div className="text-right">
                  <span className={`text-sm px-2 py-1 rounded-full ${
                    isSameDay(day, new Date()) 
                      ? 'bg-blue-100 text-blue-800' 
                      : 'text-gray-500'
                  }`}>
                    {format(day, 'd')}
                  </span>
                </div>
                <div className="mt-1">
                  {dayEvents.length > 0 && (
                    <div className="flex items-center gap-1">
                      <div className="bulle"></div>
                      <span className="text-xs text-blue-800">
                        {dayEvents.length} {dayEvents.length === 1 ? 'RDV' : 'RDVs'}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col h-full bg-white rounded-lg shadow">
      {/* Header avec navigation et sélection de vue */}
      <div className="p-4 border-b flex flex-wrap justify-between items-center gap-4">
        <div className="flex items-center space-x-2">
          <button 
            onClick={() => navigateDate('prev')}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <ChevronLeft className="w-5 h-5" />
          </button>
          <button 
            onClick={() => setCurrentDate(new Date())}
            className="px-3 py-1 text-sm bg-blue-100 text-blue-800 rounded-full hover:bg-blue-200 transition-colors"
          >
            Aujourd'hui
          </button>
          <button 
            onClick={() => navigateDate('next')}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <ChevronRight className="w-5 h-5" />
          </button>
          <h2 className="text-lg font-semibold ml-2">
            {format(currentDate, 
              view === 'week' 
                ? "'Semaine du' d MMM" 
                : view === 'month'
                  ? 'MMMM yyyy'
                  : "EEEE d MMMM yyyy", 
              { locale: fr }
            )}
          </h2>
        </div>
        
        <div className="flex items-center space-x-2">
          <button 
            onClick={() => setView('day')}
            className={`flex items-center px-3 py-1 rounded-lg text-sm ${
              view === 'day' ? 'bg-blue-100 text-blue-800' : 'hover:bg-gray-100'
            }`}
          >
            <Calendar className="w-4 h-4 mr-1" />
            Jour
          </button>
          <button 
            onClick={() => setView('week')}
            className={`flex items-center px-3 py-1 rounded-lg text-sm ${
              view === 'week' ? 'bg-blue-100 text-blue-800' : 'hover:bg-gray-100'
            }`}
          >
            <CalendarRange className="w-4 h-4 mr-1" />
            Semaine
          </button>
          <button 
            onClick={() => setView('month')}
            className={`flex items-center px-3 py-1 rounded-lg text-sm ${
              view === 'month' ? 'bg-blue-100 text-blue-800' : 'hover:bg-gray-100'
            }`}
          >
            <CalendarDays className="w-4 h-4 mr-1" />
            Mois
          </button>
        </div>
      </div>

      {/* Contenu du calendrier */}
      <div className="flex-1 overflow-hidden">
        {view === 'day' && renderDayView()}
        {view === 'week' && renderWeekView()}
        {view === 'month' && renderMonthView()}
      </div>
    </div>
  );
};

export default ModernCalendar;