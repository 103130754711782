import React, { useState, useEffect } from 'react';
import { useMessage } from '../../contexts/MessageContext';
import { X, Search, Loader } from 'lucide-react';
import { Modal } from 'react-bootstrap';

const NewConversationModal = ({ isOpen, onClose, onCreateConversation, searchTerm = '' }) => {
    const { searchUsers, searchResults, setSearchResults, searchError } = useMessage();
    const [localSearchTerm, setLocalSearchTerm] = useState(searchTerm);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setLocalSearchTerm(searchTerm);
    }, [searchTerm]);

    // Premier useEffect pour la recherche
    useEffect(() => {
        const delayDebounce = setTimeout(() => {
            if (localSearchTerm.length > 2) {
                setIsLoading(true);
                const userId = localStorage.getItem('userId');
                searchUsers(localSearchTerm);
            }
        }, 300);

        return () => {
            clearTimeout(delayDebounce);
        };
    }, [localSearchTerm]); // Enlever searchUsers de la dépendance

    // Deuxième useEffect pour gérer le chargement
    useEffect(() => {
        if (searchResults.length >= 0) {
            setIsLoading(false);
        }
    }, [searchResults]);

    // Effect de nettoyage lors de la fermeture
    useEffect(() => {
        return () => {
            setSearchResults([]);
            setSelectedUser(null);
            setLocalSearchTerm('');
        };
    }, [setSearchResults]);

    const handleUserClick = (user) => {
        setSelectedUser(selectedUser?.id === user.id ? null : user);
    };

    const handleStartConversation = () => {
        if (selectedUser) {
            onCreateConversation(selectedUser);
            onClose();
        }
    };

    return (
        <Modal show={isOpen} onHide={onClose} centered>
            <div className="p-4">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-semibold">Nouvelle conversation</h2>
                    <button onClick={onClose} className="p-2 hover:bg-gray-100 rounded-full">
                        <X size={24} />
                    </button>
                </div>

                <div className="relative">
                    <input
                        type="text"
                        placeholder={localSearchTerm === '' ? "Rechercher une personne..." : ""}
                        className="w-full p-2 pr-12 border rounded mb-4 focus:outline-none focus:ring-2 focus:ring-pink-400"
                        value={localSearchTerm}
                        onChange={(e) => setLocalSearchTerm(e.target.value)}
                    />
                    {localSearchTerm === '' && (
                        <Search className="absolute right-4 top-6 -translate-y-1/2 text-gray-400" size={20} />
                    )}
                </div>

                {searchError && (
                    <div className="text-red-500 text-center mb-4">
                        Une erreur s'est produite lors de la recherche
                    </div>
                )}

                <div className="max-h-96 overflow-y-auto mb-4">
                    {isLoading ? (
                        <div className="flex justify-center items-center p-4">
                            <Loader className="animate-spin" size={24} />
                        </div>
                    ) : searchResults.length > 0 ? (
                        searchResults.map(user => (
                            <div
                                key={user.id}
                                onClick={() => handleUserClick(user)}
                                className={`p-3 border-b flex items-center justify-between cursor-pointer hover:bg-gray-50 ${
                                    selectedUser?.id === user.id ? 'bg-pink-50' : ''
                                }`}
                            >
                                <div className="flex items-center">
                                    <div className="rounded-full w-10 h-10 bg-gradient-to-r from-pink-400 to-rose-400 text-white flex items-center justify-center mr-3">
                                        {user.business_name?.[0] || `${user.first_name[0]}${user.last_name[0]}`}
                                    </div>
                                    <div>
                                        <div className="font-medium">
                                            {user.business_name || `${user.first_name} ${user.last_name}`}
                                        </div>
                                    </div>
                                </div>
                                <div className="text-sm text-gray-500">
                                    {user.user_type === 'beautician' ? 'Esthéticienne' :
                                     user.user_type === 'admin' ? 'Administrateur' : 'Client'}
                                </div>
                            </div>
                        ))
                    ) : localSearchTerm.length > 2 ? (
                        <div className="text-center text-gray-500 p-4">
                            Aucun utilisateur trouvé
                        </div>
                    ) : (
                        <div className="text-center text-gray-500 p-4">
                            Entrez au moins 3 caractères pour rechercher
                        </div>
                    )}
                </div>

                <button
                    onClick={handleStartConversation}
                    disabled={!selectedUser}
                    className={`w-full py-2 px-4 rounded-full font-medium transition-all duration-300 ${
                        selectedUser
                            ? 'bg-gradient-to-r from-pink-400 to-rose-400 text-white hover:from-pink-500 hover:to-rose-500'
                            : 'bg-gray-200 text-gray-500 cursor-not-allowed'
                    }`}
                >
                    Démarrer la conversation
                </button>
            </div>
        </Modal>
    );
};

export default NewConversationModal;