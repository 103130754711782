import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation, Link } from 'react-router-dom';
import {
  LayoutDashboard,
  UserCircle,
  Scissors,
  Calendar,
  BarChart2,
  Settings,
  Clock,
  Euro,
  MessageCircle,
} from 'lucide-react';
import './BeauticianPortalSidebar.css';

import { BeauticianPortalContext } from '../../../context/BeauticianPortalContext';
import { AppContext } from '../../../contexts/AppContext';
import { useMessage } from '../../../contexts/MessageContext'; // Ajout du context message


const BeauticianPortalSidebar = ({ onLinkClick }) => {

  const { profileData, isParentLoading, abonnementActive } = useContext(BeauticianPortalContext);
  const { setNotificationChangeTrigger } = useContext(AppContext);
  const { unreadMessages } = useMessage(); // Récupération des messages non lus

  const location = useLocation();

  // Liste des chemins spécifiques
  const pathsToTriggerAction = [
    "/beautician-dashboard/",
    "/beautician-dashboard/profile",
    "/beautician-dashboard/services",
    "/beautician-dashboard/agenda",
    "/beautician-dashboard/historiques",
    "/beautician-dashboard/stats",
    "/beautician-dashboard/settings",
    "/beautician-dashboard/notifications",
    "/beautician-dashboard/messaging",
  ];

  useEffect(() => {
    if (pathsToTriggerAction.includes(location.pathname)) {
      setNotificationChangeTrigger(prev => !prev);
    }
  }, [location.pathname]);

  const [menuItems, setMenuItems] = useState([
    {
      title: "Tableau de bord",
      icon: <LayoutDashboard size={20} />,
      path: "/beautician-dashboard/", // Chemin complet pour le dashboard
      exact: true, // Propriété pour activer uniquement ce chemin exact
    }
  ]);

  useEffect(() => {
    if (!isParentLoading && profileData) {

      if (profileData.is_active) {
        if (abonnementActive) {
          setMenuItems(([
            {
              title: "Tableau de bord",
              icon: <LayoutDashboard size={20} />,
              path: "/beautician-dashboard/", // Chemin complet pour le dashboard
              exact: true, // Propriété pour activer uniquement ce chemin exact
            },
            {
              title: "Messages",
              icon: <MessageCircle size={20} />,
              path: "/beautician-dashboard/messaging",
              hasNotification: unreadMessages > 0,
              notificationCount: unreadMessages // Ajout du compte
            },
            {
              title: "Mon Profil",
              icon: <UserCircle size={20} />,
              path: "/beautician-dashboard/profile",
            },
            {
              title: "Mes Services",
              icon: <Scissors size={20} />,
              path: "/beautician-dashboard/services",
            },
            {
              title: "Agenda",
              icon: <Calendar size={20} />,
              path: "/beautician-dashboard/agenda",
            },
            {
              title: "Historiques",
              icon: <Clock size={20} />,
              path: "/beautician-dashboard/historiques",
            },
            {
              title: "Revenu mensuel",
              icon: <Euro size={20} />,
              path: "/beautician-dashboard/revenu-mensuel",
            },
            {
              title: "Statistiques",
              icon: <BarChart2 size={20} />,
              path: "/beautician-dashboard/stats",
            },
            {
              title: "Paramètres",
              icon: <Settings size={20} />,
              path: "/beautician-dashboard/settings",
            }
          ]));
        } else {
          setMenuItems(([
            {
              title: "Tableau de bord",
              icon: <LayoutDashboard size={20} />,
              path: "/beautician-dashboard/", // Chemin complet pour le dashboard
              exact: true, // Propriété pour activer uniquement ce chemin exact
            },
            {
              title: "Paramètres",
              icon: <Settings size={20} />,
              path: "/beautician-dashboard/settings",
            }
          ]));
        }
      }
    }
  }, [profileData, isParentLoading, abonnementActive, unreadMessages]);

  return (
    <div className="sidebar-inner">
      <div className="sidebar-branding">
        <Link to="/" className="sidebar-brand-text">Planibeauty</Link>
      </div>

      <nav className="sidebar-nav">
      {menuItems.map((item, index) => (
        <NavLink
          key={index}
          to={item.path}
          className={({ isActive }) =>
            `sidebar-link ${isActive ? 'sidebar-link-active' : ''}`
          }
          onClick={onLinkClick}
          end={item.exact}
          title={item.title} // Ajout du titre qui apparaîtra au survol
        >
          <span className="sidebar-link-icon">
            {item.icon}
            {item.hasNotification && (
              <span className="sidebar-message-count">
                {item.notificationCount > 9 ? '9+' : item.notificationCount}
              </span>
            )}
          </span>
          <span className="sidebar-link-text">{item.title}</span>
        </NavLink>
      ))}
        </nav>
    </div>
  );
};

export default BeauticianPortalSidebar;
