import React, { useEffect, useState } from "react";
import { Modal, Button, Table } from "react-bootstrap";
import abonnementApi from "../../../api/abonnement.api";

const ShowAllActiveAbonnementModal = ({ show, onClose }) => {

    const [abonnements, setAbonnements] = useState(false);

    const fetchAllActiveAbonnements = async () => {
        const res = await abonnementApi.getAllActiveAbonnement(localStorage.getItem('token'));
        if (res.success) {
            setAbonnements(res.data);
        }
    }

    useEffect(() => {
        fetchAllActiveAbonnements();
    }, []);

    return (
        <Modal show={show} onHide={onClose} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>Abonnements Actifs</Modal.Title>
            </Modal.Header>
            <Modal.Body className="overflow-auto">
                {abonnements.length > 0 ? (
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Utilisateur</th>
                                <th>Offre</th>
                                <th>Prix par Mois</th>
                                <th>Début</th>
                                <th>Fin</th>
                                <th>Mois</th>
                            </tr>
                        </thead>
                        <tbody>
                            {abonnements.map((abonnement, index) => (
                                <tr key={abonnement.id}>
                                    <td>{index + 1}</td>
                                    <td>{abonnement.user_name}</td>
                                    <td>{abonnement.offre_name}</td>
                                    <td>{abonnement.price_per_month} €</td>
                                    <td>{new Date(abonnement.start_date).toLocaleDateString()}</td>
                                    <td>{new Date(abonnement.end_date).toLocaleDateString()}</td>
                                    <td>{abonnement.months}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    <div className="text-center text-gray-500">
                        Aucun abonnement actif n'est disponible.
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Fermer
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ShowAllActiveAbonnementModal;