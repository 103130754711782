import React, { useContext, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import SelectNextAppointmentDate from "./SelectNextAppointmentDate";
import { showErrorMessage, showInformationMessage, showSuccessMessage, showWarningMessage } from "../../../../utils/messageHelper";
import { BeauticianPortalContext } from "../../../../context/BeauticianPortalContext";
import { format } from "date-fns";
import appointmentApi from "../../../../api/appointement.api";
import Swal from "sweetalert2";
//import { BASE_URL } from "../../../../utils/constants";
import { addMinutesToTime, formatTime, validEmail } from "../../../../utils/functions";
import { API_URL, BASE_URL } from "../../../../utils/constants";
import { fr } from "date-fns/locale";


const PlanifyNextAppointmentModal = ({ showModal, handleCloseModal, service, nextAppointmentNumber, old_appointment }) => {

    const [selectedDate, setSelectedDate] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    const { profileData, setReloadTrigger } = useContext(BeauticianPortalContext);

    const handlePlanifyNextAppointment = async () => {

        if (!selectedDate) {
            showWarningMessage('Selectionnez une date!');
            return;
        }

        if (!selectedTime) {
            showWarningMessage('Selectionnez l\'heure du rendez-vous!');
            return;
        }

        // si la date selectionner est inferieur à la date du rendez-vous actuel
        if (format(selectedDate, 'yyyy-MM-dd') <= format(old_appointment.appointment_date, 'yyyy-MM-dd')) {
            showWarningMessage(`La date selectionnée doit être ulterieur à ${format(old_appointment.appointment_date, 'd MMMM yyyy')} !`);
            return;
        }

        let appointmentData = {
            name: old_appointment.client_first_name + " " + old_appointment.client_last_name,
            beautician_id: profileData.beautician_id, // beautician id
            user_id: old_appointment.client_id,
            appointment_date: format(selectedDate, 'yyyy-MM-dd'),
            start_time: selectedTime,
            end_time: addMinutesToTime(selectedTime, service.subcategory_duration),
            total_price: 0,
            payed: 0,
            payment_type: '',
            services: [{
                subcategory_name: service.subcategory_name,
                beautician_subcategory_id: service.beautician_subcategory_id,
                price_at_booking: service.price_at_booking,
            }],
            is_planified_appointment: true,
            appointment_number: nextAppointmentNumber,
            appointment_id: old_appointment.appointment_id
        };

        //on verifie si le créneaux est toujours disponible

        const result = await appointmentApi.isAppointmentAvailable(appointmentData.beautician_id, appointmentData.appointment_date, appointmentData.start_time, appointmentData.end_time);
        if (result.success && result.is_available) {
            try {
                // Afficher le loader
                Swal.fire({
                    title: 'Veuillez patienter...',
                    text: 'Traitement en cours...',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                // Créer le rendez-vous
                const create_appointment = await appointmentApi.createAppointment(
                    localStorage.getItem('token'),
                    appointmentData,
                    true
                );

                if (create_appointment.success) {

                    //let url = process.env.NODE_ENV === "production" ? `${BASE_URL}/beautician-dashboard` : 'http://localhost:3000/beautician-dashboard';

                    //on envoie un meail au client pour le notifier du prochain rendez-vous
                    //si email du client donné, envoyé email de confirmation
                    if (validEmail(profileData.email)) {

                        let services_list = '';
                        appointmentData.services.forEach((service) => {
                            services_list += service.subcategory_name;
                            services_list += ", ";
                        });
                        services_list = services_list.slice(0, -2);

                        let name = profileData.business_name ?? `${profileData.first_name} ${profileData.last_name}`;

                        let message = `
                            <p>Nous vous informons que vous avez un rendez-vous planifié avec <b>${name}</b>
                            le  <b>${format(appointmentData.appointment_date, 'EEEE d MMMM yyyy', { locale: fr })}</b> de <b>${formatTime(appointmentData.start_time)} à ${formatTime(appointmentData.end_time)}</b> pour 
                                les services suivantes : <b>${services_list}</b>.</p>
                            
                        `;

                        const emailData = {
                            to: old_appointment.client_email,
                            subject: 'Notification de rendez-vous',
                            name: `${old_appointment.client_first_name} ${old_appointment.client_last_name}`,
                            message: message,
                            link: `${BASE_URL}/client-dashboard`,
                            link_text: 'Accedez à mon tableau de bord',
                        };
                        const send = await fetch(`${API_URL}/email/send-email-notification`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(emailData),
                        });
                    }

                    showSuccessMessage('Le rendez-vous a été ajouté à votre agenda!');
                    setReloadTrigger(prev => !prev);
                    setSelectedDate('');
                    setSelectedTime('');
                    handleCloseModal();
                } else {
                    // Afficher une erreur si la création de la réservation échoue
                    showErrorMessage('Une erreur est survenue lors de la réservation.');
                }
            } catch (error) {
                // Gérer les erreurs générales
                showErrorMessage('Une erreur est survenue. Veuillez réessayer.');
            }
        } else if (result.success && !result.is_available) {
            showInformationMessage('Désolé mais ce créneau n\'est plus disponible, veuillez choisir un autre!');
        } else {
            showErrorMessage("Erreur interne du serveur");
        }

    }

    const handleCancel = () => {
        setSelectedDate('');
        setSelectedTime('');
        handleCloseModal();
    }

    return (
        <Modal
            show={showModal}
            onHide={handleCancel}
            centered
            backdropClassName="secondary-backdrop"
            style={{ zIndex: 1051 }}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>Planifier un prochain rendez-vous</Modal.Title>
            </Modal.Header>
            <Modal.Body className="overflow-y-auto">
                <SelectNextAppointmentDate
                    service={service}
                    selectedDate={selectedDate}
                    selectedTime={selectedTime}
                    setSelectedDate={setSelectedDate}
                    setSelectedTime={setSelectedTime}
                    nextAppointmentNumber={nextAppointmentNumber}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCancel}>
                    Annuler
                </Button>
                <Button variant="primary" onClick={handlePlanifyNextAppointment}>
                    Planifier
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PlanifyNextAppointmentModal;