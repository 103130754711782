import beauticianApi from "../api/beautician.api";
import emailApi from "../api/email.api";
import userApi from '../api/user.api';
import { fr } from "date-fns/locale";
import { format } from "date-fns";
import { BASE_URL } from "./constants";

const sendEmailNotification = async (beautician_id, appointment_date, start_time, end_time, link, link_text) => {

    // Récupérer les informations de l'esthéticienne
    const beautician = await beauticianApi.getBeauticianById(beautician_id);

    try {
        const message = `
            Un client a pris rendez-vous avec vous le 
            <b>${format(appointment_date, 'EEEE d MMMM yyyy', { locale: fr })}</b> de <b>${start_time} à ${end_time}</b>.
            Vous pouvez le confirmer en allant dans votre tableau de bord !
        `;
        await emailApi.sendEmailNotification(
            beautician.data.email,
            'Notification de réservation!',
            `${beautician.data.first_name} ${beautician.data.last_name}`,
            message,
            link,
            link_text
        );
    } catch (emailError) {
        console.log(emailError);
    }
}


const sendEmailAccountStatusNotification = async (user_id, status, raison) => {

    // Récupérer les informations de l'utilisateur
    const user = await userApi.getUserById(localStorage.getItem('token'), user_id);

    try {
        let message = `
            <p>
                Votre compte a été <b>${status === 'active' ? 'activé' : 'désactivé'}</b> par l'administrateur!<br>
                Pour plus d'informations, veuillez contacter le support.
            </p>
        `;
        if (status === 'inactive') {
            message += `<p><b>Raison</b>: ${raison}</p>`;
        }
        await emailApi.sendEmailAccountStatusNotification(
            user.email,
            `${status === 'active' ? 'Activation' : 'Désactivation'} de votre compte!`,
            `${user.first_name} ${user.last_name}`,
            message,
        );
    } catch (emailError) {
        console.log(emailError);
    }
}

const sendEmailBookingStatusToBeautician = async (beautician_id, client_name, appointment_date, start_time, end_time, status = 'cancel', is_already_confirmed = false, reason = '') => {
    try {
        // Récupérer les informations de l'esthéticienne
        const beautician = await beauticianApi.getBeauticianById(beautician_id);

        let message = `
            <p>Votre rendez-vous avec <b>${client_name}</b>, prevu le
            <b>${format(appointment_date, 'EEEE d MMMM yyyy', { locale: fr })}</b> de <b>${start_time} à ${end_time}</b>
            a été <b>${status === 'confirmation' ? 'confirmé' : 'annulé'}</b> par le client!</p>
        `;

        if (status === 'cancel' && is_already_confirmed) {
            message += `<p><b>Raison</b>: ${reason}</p>`;
        }

        let base_url = process.env.NODE_ENV === "production" ? `${BASE_URL}` : 'http://localhost:3000';

        const link = status === 'confirmation' ? `${base_url}/beautician-dashboard` : `${base_url}/beautician-dashboard}`;
        const link_text = 'Accéder à mon tableau de bord';

        await emailApi.sendEmailNotification(
            beautician.data.email,
            `Notification ${status === 'confirmation' ? 'de confirmation' : 'd\'annulation'} de rendez-vous!`,
            `${beautician.data.first_name} ${beautician.data.last_name}`,
            message,
            link,
            link_text
        );
    } catch (emailError) {
        console.log(emailError);
    }
}

const sendEmailBookingStatusToClient = async (user, beautician_name, appointment_date, start_time, end_time, status, beautician_slug = '', reason = '', is_already_confirmed = false, beautician, config = null) => {
    try {
        let message = `
            <p>Votre rendez-vous avec <b>${beautician_name}</b>, le
            <b>${format(appointment_date, 'EEEE d MMMM yyyy', { locale: fr })}</b> de <b>${start_time} à ${end_time}</b>
            a été <b>${status === 'confirmation' ? 'confirmé' : 'annulé'}</b>!</p>
        `;

        // si status cancel et déjà confirmé, ajouter la raison de l'annulation
        if (status === 'cancel' && is_already_confirmed) {
            message += `<p><b>Raison</b>: ${reason}</p>`;
        }

        // si status confirmé, ajouter l'adresse du beautician
        if (status === 'confirmation') {
            message += `<p>
                <b>Adresse</b> : ${config.isAddressChanged ? config.newAddress : beautician.address} <br />
                </p>`;
            // <b>Numéro de téléphone : </b> ${beautician.phone} <br />
            // <b>Adresse email : </b> ${beautician.email}
        }

        // Si la durée à changer
        if (config) {
            if (config.isDurationChanged && status === 'confirmation') {
                message += `<p>
                La durée de votre rendez-vous est maintenant <b>${config.newDuration}</b> minutes au lieu de <b>${config.oldDuration}</b> minutes
                </p>`;
            }
        }

        let base_url = process.env.NODE_ENV === "production" ? `${BASE_URL}` : 'http://localhost:3000';

        const link = status === 'confirmation' ? `${base_url}/client-dashboard` : `${base_url}/beauticians/${beautician_slug}`;
        const link_text = status === 'confirmation' ? 'Accéder à mon tableau de bord' : 'Prendre un nouveau rendez-vous';

        await emailApi.sendEmailNotification(
            user.email,
            `Notification ${status === 'confirmation' ? 'de confirmation' : 'd\'annulation'} de rendez-vous!`,
            `${user.first_name} ${user.last_name}`,
            message,
            link,
            link_text
        );
    } catch (emailError) {
        console.log(emailError);
    }
}

const sendEmaiBeauticianCreation = async (beauticianEmail, beauticianPassword) => {
    try {
        await emailApi.sendEmailRegistrationConfirmation(
            beauticianEmail,
            beauticianPassword
        );
    } catch (emailError) {
        console.log(emailError);
    }
}

export {
    sendEmailNotification,
    sendEmailBookingStatusToClient,
    sendEmaiBeauticianCreation,
    sendEmailBookingStatusToBeautician,
    sendEmailAccountStatusNotification
};