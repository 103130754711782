import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import abonnementApi from '../../../../../api/abonnement.api';

const ShowHistoryAbonnementModal = ({ showModal, handleCloseModal, user_id }) => {

  const [abonnements, setAbonnements] = useState([]);

  const fetchAbonnements = async (user_id) => {
    const res = await abonnementApi.getAllAbonnementByUserId(localStorage.getItem('token'), user_id);
    setAbonnements(res);
  }

  useEffect(() => {
    fetchAbonnements(user_id);
  }, []);

  return (
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered
        backdropClassName="main-backdrop"
        size="xl" className="my-modal-xl"
        style={{ zIndex: 1050 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Historiques d'abonnement</Modal.Title>
        </Modal.Header>
        <Modal.Body className="overflow-y-scroll min-h-[60vh]">
        <table className="table-auto w-full border-collapse border border-gray-400">
                <thead>
                    <tr className="bg-gray-200">
                        <th className="border border-gray-400 px-4 py-2">Période</th>
                        <th className="border border-gray-400 px-4 py-2">Mois</th>
                        <th className="border border-gray-400 px-4 py-2">Statut</th>
                        <th className="border border-gray-400 px-4 py-2">Offre</th>
                        <th className="border border-gray-400 px-4 py-2">Prix/Mois</th>
                        <th className="border border-gray-400 px-4 py-2">Code promo</th>
                        <th className="border border-gray-400 px-4 py-2">Remise</th>
                        <th className="border border-gray-400 px-4 py-2">Total payé</th>
                    </tr>
                </thead>
                <tbody>
                    {abonnements.map((abonnement) => (
                        <tr key={abonnement.id} className={`${(abonnement.status === 'active' && new Date(abonnement.end_date) >= new Date()) ? 'bg-green-200' : 'bg-gray-100'}`}>
                            <td className="border border-gray-300 px-4 py-2">
                                {new Date(abonnement.start_date).toLocaleDateString()}{' - '}{new Date(abonnement.end_date).toLocaleDateString()}
                            </td>
                            <td className="border border-gray-300 px-4 py-2">{abonnement.months}</td>
                            <td className="border border-gray-300 px-4 py-2">{abonnement.status}</td>
                            <td className="border border-gray-300 px-4 py-2">{abonnement.offre_name}</td>
                            <td className="border border-gray-300 px-4 py-2">
                                {abonnement.price_per_month} €
                            </td>
                            <td className="border border-gray-300 px-4 py-2">
                              {abonnement.promo_code || '-'}
                            </td>
                            <td className="border border-gray-300 px-4 py-2">
                              {abonnement.promo_discount_percentage || '-'}
                            </td>
                            <td className="border border-gray-300 px-4 py-2">
                                {abonnement.promo_discount_percentage ? ((abonnement.price_per_month * abonnement.months) * (1 - (abonnement.promo_discount_percentage / 100))) : abonnement.price_per_month * abonnement.months} €
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
  );
};

export default ShowHistoryAbonnementModal;
