import React from 'react';
import { Minus, ArrowRight } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useBooking } from '../../context/BookingContext';
import './ServicesSelection.css';
import Swal from 'sweetalert2';

const ServicesSelection = ({ onNextStep }) => {
  const {slug} = useParams();
  const navigate = useNavigate();
  const { selectedServices, removeService, clearBooking } = useBooking();

  const handleServiceRemove = (localId) => {
    let previousLength = selectedServices.length;
    removeService(localId);
    if(previousLength === 1) { // si vide retourner à la page du beautician
      Swal.fire('Informations', 'Le panier est vide, veuillez ajouter pour pouvoir réserver!', 'info').then(() => {
        clearBooking();
        navigate(`/beauticians/${slug}`);
      });
    }
  };

  const calculateTotal = () => {
    return selectedServices.reduce((acc, service) => ({
      price: acc.price + Number(service.beautician_subcategory_price),
      duration: acc.duration + Number(service.beautician_subcategory_duration)
    }), { price: 0, duration: 0 });
  };

  return (
    <div className="services-selection">
      <div className="services-list">
        <div className="selected-services-container">
          <h2>Prestations sélectionnées</h2>
          {selectedServices.map((service) => (
            <div key={service.beautician_subcategory_id} className="selected-service-item">
              <div className="selected-service-info">
                <div className="service-main-info">
                  <span className="service-name">{service.service_subcategory_name}</span>
                  <div className="service-details">
                    <span className="service-price">{service.beautician_subcategory_price}€</span>
                    <span className="service-duration">{service.beautician_subcategory_duration} min</span>
                    {service.is_multiple_appointment && <span className="service-duration">Ce service necessite {service.number_of_appointment} rendez-vous</span>}
                  </div>
                </div>
                <button
                  className="remove-button"
                  onClick={() => handleServiceRemove(service.localId)}
                >
                  <Minus size={16} />
                </button>
              </div>
            </div>
          ))}
          <div className="services-total">
            <div className="total-line">
              <span>Total durée:</span>
              <span className="total-duration">{calculateTotal().duration} min</span>
            </div>
            <div className="total-line">
              <span>Total prix:</span>
              <span className="total-price">{calculateTotal().price}€</span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center gap-2">
        <button className="add-more-button" onClick={() => navigate(`/beauticians/${slug}#prestation`)}>
          Ajouter d'autres prestations
        </button>
        <button 
          className="next-step-button"
          disabled={selectedServices.length === 0}
          onClick={onNextStep}
        >
          Continuer <ArrowRight size={20} />
        </button>
      </div>
    </div>
  );
};

export default ServicesSelection;