export const getWeekDates = (date) => {
    const today = date ? new Date(date) : new Date()
    const day = today.getDay()
    const diffToMonday = today.getDate() - day + (day === 0 ? -6 : 1) // Calcul de la date du lundi
    const diffToSunday = today.getDate() - day + (day === 0 ? 0 : 7) // Calcul de la date du dimanche

    const monday = new Date(today)
    const sunday = new Date(today)

    monday.setDate(diffToMonday)
    sunday.setDate(diffToSunday)

    // Formatage en dd/mm/yy
    const formatDate = (date) => {
        const dayFormatted = String(date.getDate()).padStart(2, '0')
        const monthFormatted = String(date.getMonth() + 1).padStart(2, '0')
        const yearFormatted = String(date.getFullYear()).slice(-2)
        return `${dayFormatted}/${monthFormatted}/${yearFormatted}`
    }

    return [formatDate(monday), formatDate(sunday)]
}

export const getMonday = (date) => {
    const givenDate = new Date(date)
    const day = givenDate.getDay()
    const diff = (day === 0 ? -6 : 1) - day

    givenDate.setDate(givenDate.getDate() + diff)
    return givenDate
}