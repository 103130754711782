import React, { useContext, useEffect, useState, useMemo } from 'react';
import {
  Calendar,
  TrendingUp,
  Users,
  Clock,
  Award,
  Star,
  ChevronRight,
  DollarSign,
  MessageCircle 
} from 'lucide-react';
import './BeauticianDashboard.css';
import appointmentApi from "../../../api/appointement.api";
import availabilityApi from '../../../api/availability.api';
import beauticianApi from '../../../api/beautician.api';
import ratingApi from '../../../api/rating.api';
import { Link, useNavigate } from 'react-router-dom';
import { BeauticianPortalContext } from '../../../context/BeauticianPortalContext';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useMessage } from '../../../contexts/MessageContext'; // Ajout du context message

const checkDateValidation = (appointment_date, start_time) => {
  const dateTime = new Date(appointment_date);
  const [hours, minutes, seconds] = start_time.split(':').map(Number);

  dateTime.setHours(hours);
  dateTime.setMinutes(minutes);
  dateTime.setSeconds(seconds);
  
  return dateTime >= new Date().setHours(0, 0) && dateTime <= new Date().setHours(24, 0);
}

const getDuration = (startTime, endTime) => {
  const start = new Date("1970-01-01T" + startTime + "Z");
  const end = new Date("1970-01-01T" + endTime + "Z");

  const diffInMs = end - start;
  const hours = Math.floor(diffInMs / 3600000);
  const minutes = Math.floor((diffInMs % 3600000) / 60000);

  return `${hours > 0 ? hours + " h " : ""}${minutes} min`;
}

const BeauticianDashboard = () => {
  const navigate = useNavigate();

  const { isParentLoading, profileData, abonnementActive, isOnFreeTrial, trial } = useContext(BeauticianPortalContext);

  const [serviceDone, setServiceDone] = useState(0);
  const [dailyRevenue, setDailyRevenue] = useState(0);
  const { unreadMessages } = useMessage(); // Ajout du hook useMessage

  const [averageDuration, setAverageDuration] = useState(0);
  const [upcomingAppointments, setUpcomingAppointments] = useState([]);
  const [pendingAppointmentCount, setPendingAppointmentCount] = useState(0);
  const [kpiData, setKpiData] = useState([
    { title: 'Clients du jour', value: 0, icon: <Users size={24} />, trend: "", color: '#0ea5e9' },
    { title: 'Taux occupation', value: 0, icon: <Clock size={24} />, trend: "", color: '#8b5cf6' },
    { title: 'Nouveaux clients', value: 0, icon: <Award size={24} />, trend: "", color: '#f59e0b' },
    { title: 'Avis positifs', value: 0, icon: <Star size={24} />, trend: "", color: '#10b981' },
  ]);

  const updateKpi = (title, updates) => {
    setKpiData(prevKpis =>
      prevKpis.map(k => (k.title === title ? { ...k, ...updates } : k))
    )
  }

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {

        if (profileData) {
          let occupancyRate = 100
          let appointmentTotalDuraion = 0

          const b = await beauticianApi.getBeauticianById(profileData.beautician_id);
          const result = await appointmentApi.getAppointmentByBeautician(profileData.beautician_id);

          const rating = await ratingApi.getRatingByBeauticianId(profileData.beautician_id);

          // Taux d'occupation
          const schedule = await availabilityApi.getBeauticianAvailability(profileData.beautician_id)
          const appointement = await appointmentApi.getAppointmentByBeautician(profileData.beautician_id)

          const today = new Date().toLocaleString('en-US', { weekday: 'long' }).toLowerCase()
          const availability = schedule.find(entry => entry.day === today)

          const activeDateAppointment = appointement.filter(a => {
            const activeDate = new Date()

            activeDate.setDate(activeDate.getDate() - 1)

            return (a.status === "confirmed" || a.status === "completed") && activeDate.toISOString().startsWith(a.appointment_date.split("T")[0])
          })

          if("beautician_id" in availability) {
            const { start_time, end_time, break_start, break_end } = availability

            const [hs,ms,ss] = start_time.split(":").map(e => parseInt(e))
            const [he,me,se] = end_time.split(":").map(e => parseInt(e))
            const [hbs, mbs, sbs] = break_start.split(":").map(e => parseInt(e))
            const [hbe, mbe, sbe] = break_end.split(":").map(e => parseInt(e))

            const morningDuration =  (hbs * 3600 + mbs * 60 + sbs) - (hs * 3600 + ms * 60 + ss)
            const afternoonDuraion = (he * 3600 + me * 60 + se) - (hbe * 3600 + mbe * 60 + sbe)

            const availabilityTotalDuration = morningDuration + afternoonDuraion

            appointmentTotalDuraion = activeDateAppointment.map(e => {
              const { start_time, end_time } = e

              const [hs, ms, ss] = start_time.split(":").map(e => parseInt(e))
              const [he, me, se] = end_time.split(":").map(e => parseInt(e))

              return (he * 3600 + me * 60 + se) - (hs * 3600 + ms * 60 + ss)
            }).reduce((acc, val) => acc += val, 0)

            occupancyRate = (appointmentTotalDuraion * 100 / availabilityTotalDuration).toFixed(2)
          }

          // Nouveaux clients (ce mois uniquement)
          const currentMonth = new Date().getMonth()
          const currentYear = new Date().getFullYear()

          const allClients = result.reduce((uniqueClients, r) => {
            uniqueClients.add(r.client_id)
          
            return uniqueClients
          }, new Set()).size // La taille du Set représente le nombre de clients uniques
          
          const nouveauxClients = result.reduce((uniqueClients, r) => {
            const createdDate = new Date(r.created_at)
            
            // Vérifie si le rendez-vous est dans le mois et l'année en cours
            if (createdDate.getMonth() === currentMonth && createdDate.getFullYear() === currentYear) {
              uniqueClients.add(r.client_id) // Ajoute l'ID du client au Set
            }
          
            return uniqueClients
          }, new Set()).size // La taille du Set représente le nombre de clients unique

          setAverageDuration(function() {
            if(appointmentTotalDuraion > 3600) {
              const h = parseInt(appointmentTotalDuraion / 3600)
              const m = appointmentTotalDuraion * 60 - h * 60

              return `${h} h ${m}`
            }

            return appointmentTotalDuraion / 60
          })
          setServiceDone(activeDateAppointment.length)
          setDailyRevenue(activeDateAppointment.reduce((sum, item) => sum + parseFloat(item.total_prices), 0))

          updateKpi("Avis positifs", { value: b.data.average_rating })
          updateKpi("Clients", { value: allClients, change: nouveauxClients })
          updateKpi("Nouveaux clients", {
            value: result.filter(r => checkDateValidation(r.created_at, r.start_time) && r.status !== "completed").length
          })

          updateKpi("Taux occupation", {
            value: occupancyRate + "%"
          })
          updateKpi('Clients du jour', {
            value: result
              .filter(r => {
                const tempDate = new Date(r.appointment_date)
                const appointmentDate = `${tempDate.getFullYear()}-${(tempDate.getMonth() + 1 < 10 ? "0" : "")}${tempDate.getMonth() + 1}-${(tempDate.getDate() < 10 ? "0" : "")}${tempDate.getDate()}`

                return new Date().toISOString().startsWith(appointmentDate)
              })
              .reduce((uniqueIds, r) => {
                // Vérifie si l'identifiant est déjà dans le tableau uniqueIds
                if (!uniqueIds.includes(r.id)) {
                  uniqueIds.push(r.id)
                }
                return uniqueIds
              }, [])
              .length,
            trend: result
              .filter(r => {
                const tempDate = new Date(r.appointment_date)
                const appointmentDate = `${tempDate.getFullYear()}-${(tempDate.getMonth() + 1 < 10 ? "0" : "")}${tempDate.getMonth() + 1}-${(tempDate.getDate() < 10 ? "0" : "")}${tempDate.getDate()}`

                return new Date().toISOString().startsWith(appointmentDate) && r.status === "pending"
              })
              .reduce((uniqueIds, r) => {
                // Vérifie si l'identifiant est déjà dans le tableau uniqueIds
                if (!uniqueIds.includes(r.id)) {
                  uniqueIds.push(r.id)
                }
                return uniqueIds
              }, [])
              .length
          })                  

          setUpcomingAppointments(result.filter(r => r.status === "confirmed" && checkDateValidation(r.appointment_date, r.start_time)).map(r => ({
            time: r.start_time,
            client: `${r.last_name} ${r.first_name}`,
            service: r.service_name,
            duration: getDuration(r.start_time, r.end_time),
            status: r.status
          })));
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
      }
    };

    const countPendingAppointment = async () => {
      try {
        const result = await appointmentApi.listAppointmentsValidByBeauticianJoinUserAndServices(localStorage.getItem('token'), profileData.beautician_id);
        const pendingAppt = [];
        if (result && Array.isArray(result)) {
          result.forEach((element) => {
            if (element.status === "pending") {
              pendingAppt.push(element);
            }
          });
        }
        setPendingAppointmentCount(pendingAppt.length);
      } catch (error) {
        console.log(error);
      }
    }

    if(profileData) {
      fetchDashboardData();
      countPendingAppointment();
    }

  }, [profileData]);

  const kpiCards = useMemo(() => kpiData.map((kpi, index) => (
    <div key={index} className="kpi-card">
      <div className="kpi-icon" style={{ backgroundColor: `${kpi.color}15`, color: kpi.color }}>
        {kpi.icon}
      </div>
      <div className="kpi-info">
        <span className="kpi-title">{kpi.title}</span>
        <div className="kpi-stats">
          <span className="kpi-value">{kpi.value}</span>
          <span className="kpi-trend">{kpi.trend}</span>
        </div>
      </div>
    </div>
  )), [kpiData]);

  const appointmentCards = useMemo(() => upcomingAppointments.map((appointment, index) => (
    <div key={index} className={`appointment-card ${appointment.status}`}>
      <div className="appointment-time">{appointment.time}</div>
      <div className="appointment-info">
        <div className="client-name">{appointment.client}</div>
        <div className="service-details">
          {appointment.service} • {appointment.duration}
        </div>
      </div>
      <div className="appointment-status">
        {appointment.status === 'confirmed' ? 'Confirmé' : 'En attente'}
      </div>
    </div>
  )), [upcomingAppointments]);

  return (
    <div className="dashboard">
      {isOnFreeTrial && 
        <div className='alert alert-warning text-center'>Votre periode d'essaie se terminera le {format(trial.trial_end_date, 'd MMMM yyyy', {locale: fr})}. Votre abonnement sera renouvellé automatiquement à la fin du periode d'essaie!</div>
      }
      {(!abonnementActive && !isOnFreeTrial) &&
        <div className='alert alert-warning text-center'>Votre n'avez aucun abonnement actif, veuillez vous abonner dans le menu Paramètres et puis dans le sous-menu abonnements!</div>
      }
      <div className="dashboard-wrapper">
      <header className="dashboard-header">
          <div className="header-content flex justify-between">
            <h1>Tableau de bord</h1>
            <div className="today-date">
              {new Date().toLocaleDateString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
            </div>
            {abonnementActive && (
              <div className="flex gap-2">
                <button 
                  className='flex justify-center gap-2 items-center px-3 py-2 bg-gray-600 rounded hover:bg-gray-700 text-white'
                  onClick={() => navigate('/messaging')}
                >
                  <MessageCircle size={16} /> Messages
                  {unreadMessages > 0 && (
                    <span className="bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                      {unreadMessages > 9 ? '9+' : unreadMessages}
                    </span>
                  )}
                </button>
                
                <button 
                  className='flex justify-center gap-2 items-center px-3 py-2 bg-gray-600 rounded hover:bg-gray-700 text-white' 
                  onClick={() => navigate('/beautician-dashboard/agenda?page=pending')}
                >
                  <Clock size={16} /> Réservation en attentes
                  <span className="bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                    {pendingAppointmentCount}
                  </span>
                </button>
              </div>
            )}
          </div>
        </header>

        {(!isParentLoading && profileData?.is_active) ?
          <div className=''>
            <div className="kpi-grid">
              {kpiCards}
            </div>

            {abonnementActive && ( 
              <div className="dashboard-main">
              <div className="dashboard-section appointments-section">
                <div className="section-header">
                  <div className="section-title">
                    <Calendar size={20} />
                    <h2>Rendez-vous du jour</h2>
                  </div>
                  <Link to="agenda" className="view-all-button no-underline">
                    <span>Voir tout</span>
                    <ChevronRight size={16} />
                  </Link>
                </div>
                <div className="appointments-list">
                  {appointmentCards}
                </div>
              </div>

              <div className="dashboard-section stats-section">
                <div className="section-header">
                  <div className="section-title">
                    <TrendingUp size={20} />
                    <h2>Performances du jour</h2>
                  </div>
                </div>
                <div className="stats-grid">
                  <div className="stat-card">
                    <DollarSign size={20} className="stat-icon" />
                    <div className="stat-title">Chiffre d'affaires</div>
                    <div className="stat-value">{dailyRevenue} €</div>
                    <div className="stat-trend positive"></div>
                  </div>
                  <div className="stat-card">
                    <Clock size={20} className="stat-icon" />
                    <div className="stat-title">Services réalisés</div>
                    <div className="stat-value">{serviceDone}</div>
                    <div className="stat-trend positive"></div>
                  </div>
                  <div className="stat-card">
                    <Users size={20} className="stat-icon" />
                    <div className="stat-title">Durée moyenne</div>
                    <div className="stat-value">{averageDuration} min</div>
                    <div className="stat-trend neutral"></div>
                  </div>
                </div>
              </div>
            </div>)}
          </div> :
          <div className=''>
            <h3>Votre compte est en cours de validation!</h3>
            <p>Vous allez recevoir un mail lorsque votre compte sera confirmé par l'administration.</p>
          </div>
        }
      </div>
    </div>
  );
};

export default BeauticianDashboard;
