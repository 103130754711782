import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import TileSelector from './TileSelector';
import { HeaderContext } from '../../contexts/HeaderContext';
import CreateEtabModal from './CreateEtabModal';
import { AppContext } from '../../contexts/AppContext';
import { useSearchParams } from 'react-router-dom';

const AjoutEtablissement = () => {
  const { setIsSignupModalOpen } = useContext(HeaderContext);
  const {isLoggedIn} = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const { setActivePageForHeader } = useContext(AppContext);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleTileClick = (type) => {

    if(type === "client") {
      setIsSignupModalOpen(true);
    }
    else {
      setSelectedType(type);
      setShowModal(true);
    }
  };

  useEffect(() => {
        window.scrollTo(0, 0);
        document.body.setAttribute('data-page', 'search');
        setActivePageForHeader("search");
    
        return () => {
          document.body.removeAttribute('data-page');
          setActivePageForHeader("");
        }
      }, [setActivePageForHeader]);

  useEffect(() => {
    window.scrollTo(0, 0);
    let open = searchParams.get('open');
    if(open) {
      if (open === 'indep') {
        setSelectedType('indépendant');
        setShowModal(true);
      } else if(open === 'etab') {
        setSelectedType('établissement');
        setShowModal(true);
      }
    }
  }, [])

  useEffect(() => {
    if(isLoggedIn) {
      window.location.href = "/";
    }
  }, [isLoggedIn])

  return (<>
    <Helmet>
      <title>Ajouter votre établissement | PlaniBeauty</title>
      <meta name="description" content="Professionnel(le) de la beauté ? Rejoignez PlaniBeauty et développez votre activité en ligne." />
      <link rel="canonical" href="https://www.planibeauty.fr/ajouter-etablissement" />
      <meta property="og:title" content="Rejoignez PlaniBeauty | Inscription Professionnels" />
      <meta property="og:description" content="Inscrivez votre établissement sur PlaniBeauty et boostez votre visibilité." />
    </Helmet>
    <div>
      <TileSelector onSelect={handleTileClick} />
      {showModal && <CreateEtabModal
        selectedType={selectedType}
        showCreateEtabModal={showModal}
        setShowCreateEtabModal={setShowModal}
      />}
      {/*<EstablishmentForm 
        show={showModal}
        onHide={() => setShowModal(false)}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
      />*/}
    </div>
  </>
  );
};

export default AjoutEtablissement;