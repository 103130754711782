import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import functionalites1 from '../../assets/fonctionnalités/fonctionnalités1.png';
import functionalites2 from '../../assets/fonctionnalités/fonctionnalités2.png';
import functionalites3 from '../../assets/fonctionnalités/fonctionnalités3.png';
import functionalites4 from '../../assets/fonctionnalités/fonctionnalités4.png';
import functionalites5 from '../../assets/fonctionnalités/fonctionnalités5.png';
import functionalites6 from '../../assets/fonctionnalités/fonctionnalités6.png';
import imageFond from '../../assets/fonctionnalités/image_fond.png';
import './FeaturesSlider.css';
import { AppContext } from '../../contexts/AppContext';

const FloatingParticles = () => {
  return (
    <div className="floating-particles">
      {[...Array(15)].map((_, i) => (
        <div
          key={i}
          className="particle"
          style={{
            '--delay': `${Math.random() * 10}s`,
            '--position': `${Math.random() * 100}%`,
          }}
        />
      ))}
    </div>
  );
};

// Ajouter ce composant après FloatingParticles
const GeometricBackground = () => {
  return (
    <div className="geometric-background">
      {[...Array(20)].map((_, i) => (
        <div
          key={i}
          className="geometric-shape"
          style={{
            '--delay': `${Math.random() * 15}s`,
            '--position-x': `${Math.random() * 100}%`,
            '--position-y': `${Math.random() * 100}%`,
            '--size': `${Math.random() * (100 - 20) + 20}px`,
            '--rotation': `${Math.random() * 360}deg`,
          }}
        />
      ))}
    </div>
  );
};

const NavigationMenu = ({ slides, activeIndex, onNavigate }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`lateral-nav ${isOpen ? 'open' : ''}`}>
      <div
        className="lateral-nav-trigger"
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
      />
      <div className="lateral-nav-menu">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`lateral-nav-menu-item ${activeIndex === index ? 'active' : ''}`}
            onClick={() => {
              onNavigate(index);
              setIsOpen(false);
            }}
          >
            {slide.type === "hero"
              ? slide.title.split('\n')[0]
              : slide.title
            }
          </div>
        ))}
      </div>
    </div>
  );
};



const FeaturesSlider = () => {
  const navigate = useNavigate();
  const { connectedUser } = useContext(AppContext);
  const [activeSlide, setActiveSlide] = useState(0);


  const slides = [
    {
      type: "hero",
      image: functionalites1,
      title: "La clé du succès de\nvotre business",
      subtitle: "Découvrez nos fonctionnalités",
      buttons: [
        { text: "ESSAI GRATUIT", variant: "white" },
        { text: "DEMANDER INFO", variant: "red" },
        { text: "NOS TARIFS", variant: "dark" }
      ]
    },
    {
      type: "feature",
      image: functionalites2,
      title: "Obtenez des rendez-vous et gérez votre agenda.",
      features: [
        {
          title: "RÉSERVATIONS 24/7",
          description: "Les clients peuvent peuvent prendre rendez-vous facilement en ligne à tout moment."
        },
        {
          title: "AGENDA INTELLIGENT EN LIGNE",
          description: "Synchronisation en temps réel des créneaux disponibles"
        },
        {
          title: "GESTION DE VOTRE TEMPS DE TRAVAIL",
          description: "Paramétrages de vos congés, de fermetures récurrentes et exceptionnelles"
        },
        {
          title: "CONFIRMATION ET RAPPELS AUTOMATIQUES",
          description: "Notifications par SMS pour confirmer, rappeler ou annuler un rendez-vous"
        }
      ]
    },
    {
      type: "feature",
      image: functionalites3,
      title: "Des paiements simplifiés, vos revenus sécurisés.",
      features: [
        {
          title: "PAIEMENT EN LIGNE SÉCURISÉ",
          description: "Via carte bancaire"
        },
        {
          title: "PAIEMENT SUR PLACE",
          description: "Intégration avec un logiciel de caisse"
        },
        {
          title: "ACOMPTES À LA RÉSERVATION",
          description: "Possibilité de demander un acompte pour sécuriser les rendez-vous"
        },
        {
          title: "PROTECTION CONTRE LES ABSENCES",
          description: "Facturation automatique des pénalités en cas de no-show ou d'annulation tardive"
        }
      ]
    },
    {
      type: "feature",
      image: functionalites4,
      title: "Chouchoutez votre clientèle.",
      features: [
        {
          title: "FICHE CLIENT COMPLÈTE ET PERSONNALISABLE",
          description: "Historique des rendez-vous, préférences, notes et coordonnées"
        },
        {
          title: "CAMPAGNES MARKETING",
          description: "Fidéliser votre clientèle à l'aide d'envoi d'offres promotionnelles par sms et/ou mail."
        },
        {
          title: "PARRAINAGE",
          description: "Encouragez vos clients à promouvoir votre business"
        },
        {
          title: "AVIS CLIENTS",
          description: "Recueillez les retours de vos clients pour améliorer vos services"
        }
      ]
    },
    {
      type: "feature",
      image: functionalites5,
      title: "Gestion des statistiques.",
      features: [
        {
          title: "SUIVI DES PERFORMANCES",
          description: "Nombre de réservations, chiffre d'affaires."
        },
        {
          title: "RAPPORTS CLIENTS",
          description: "Identification des clients réguliers"
        },
        {
          title: "\"BEST-SELLERS OR LESS SOLD\"",
          description: "Identification des prestations populaires et de celles qui se vendent moins"
        },
        {
          title: "SUIVI DES AVIS CLIENTS",
          description: "Analyse des avis clients pour améliorer les services"
        }
      ]
    },
    {
      type: "hero",
      image: functionalites6,
      title: "Renforcez votre présence\nsur Internet, développez\nvotre communauté.",
      subtitle: "",
      buttons: [
        { text: "PACKS COMMUNICATION", variant: "white" }
      ]
    },
    {
      type: "hero",
      image: imageFond,
      title: "Boostez votre business dès\nmaintenant.",
      subtitle: "",
      buttons: [
        { text: "ESSAI GRATUIT", variant: "white" },
        { text: "DEMANDER INFO", variant: "red" },
        { text: "NOS TARIFS", variant: "dark" }
      ],
      isLastSlide: true
    }
  ];

  const slidesRef = useRef([]);

  useEffect(() => {
    const observers = [];

    slidesRef.current.forEach((slide, index) => {
      if (slide) {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              slide.classList.add('animate-slide');
              // Déconnecter l'observer après la première animation
              observer.unobserve(slide);
            }
          });
        }, { threshold: 0.3 });

        observer.observe(slide);
        observers.push(observer);
      }
    });

    // Cleanup
    return () => {
      observers.forEach(observer => observer.disconnect());
    };
  }, []);

  const handleNavigate = (index) => {
    const element = slidesRef.current[index];
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      setActiveSlide(index);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.scrollY + window.innerHeight / 2;

      slidesRef.current.forEach((slide, index) => {
        if (slide) {
          const { top, bottom } = slide.getBoundingClientRect();
          const slidePosition = top + window.scrollY;

          if (currentPosition >= slidePosition && currentPosition <= slidePosition + slide.offsetHeight) {
            setActiveSlide(index);
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="features-slider-container">
      <NavigationMenu
        slides={slides}
        activeIndex={activeSlide}
        onNavigate={handleNavigate}
      />
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`features-slider ${slide.isLastSlide ? 'with-background' : ''}`}
          ref={el => slidesRef.current[index] = el}
        >
          <GeometricBackground />
          <FloatingParticles />
          {slide.type === "hero" ? (
            slide.isLastSlide ? (
              <div className="last-slide-content">

                <div className="features-text">
                  <h1 className="features-title">{slide.title}</h1>
                  <div className="features-buttons">
                    {slide.buttons.map((button, index) => (
                      <button
                        key={index}
                        className={`features-button ${button.variant} ${connectedUser && button.text === "ESSAI GRATUIT" ? 'btn-feature-disabled' : ''}`}
                        /* onClick={() => {
                          if(button.text === "NOS TARIFS") {
                            navigate('/abonnements')
                          } else if(button.text === "ESSAI GRATUIT") {
                            navigate('/ajouter-etablissement')
                          }
                        }} */
                        onClick={() => {
                          if (button.text === "NOS TARIFS") {
                            navigate('/abonnements')
                          } else if (button.text === "ESSAI GRATUIT") {
                            navigate('/ajouter-etablissement')
                          } else if (button.text === "DEMANDER INFO") {
                            window.open('https://calendly.com/contact-planibeauty/inscription-site-planibeauty', '_blank')
                          }
                        }}
                        disabled={connectedUser && button.text === "ESSAI GRATUIT"}
                      >
                        {button.text}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <div className="features-content">
                <div className="features-image">
                  <img src={slide.image} alt="Fonctionnalité" />
                </div>
                <div className="features-text">
                  <h1 className="features-title">{slide.title}</h1>
                  <p className="features-subtitle">{slide.subtitle}</p>
                  <div className="features-buttons">
                    {slide.buttons.map((button, index) => (
                      <button
                        key={index}
                        className={`features-button ${button.variant} ${connectedUser && button.text === "ESSAI GRATUIT" ? 'btn-feature-disabled' : ''}`}
                        /* onClick={() => {
                          if(button.text === "NOS TARIFS") {
                            navigate('/abonnements')
                          } else if(button.text === "ESSAI GRATUIT") {
                            navigate('/ajouter-etablissement')
                          }
                        }} */
                        onClick={() => {
                          if (button.text === "NOS TARIFS") {
                            navigate('/abonnements')
                          } else if (button.text === "ESSAI GRATUIT") {
                            navigate('/ajouter-etablissement')
                          } else if (button.text === "DEMANDER INFO") {
                            window.open('https://calendly.com/contact-planibeauty/inscription-site-planibeauty', '_blank')
                          } else if (button.text === "PACKS COMMUNICATION") {
                            navigate('/packs-communication')
                          }
                        }}
                        disabled={connectedUser && button.text === "ESSAI GRATUIT"}
                      >
                        {button.text}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            )
          ) : (
            <div className="feature-card">
              {(index === 2 || index === 3) ? (
                <>
                  <div className="features-image">
                    <img src={slide.image} alt="Fonctionnalité" />
                  </div>
                  <div className="feature-content">
                    <h2 className="feature-title">{slide.title}</h2>
                    <div className="feature-list">
                      {slide.features.map((feature, index) => (
                        <div key={index} className="feature-item">
                          <span className="feature-star">✦</span>
                          <div className="feature-item-content">
                            <h3>{feature.title}</h3>
                            <p>{feature.description.split(' ').map((word, i) => {
                              // Mots spécifiques à mettre en évidence
                              const highlightWords = ['en ligne', 'SMS'];
                              return highlightWords.includes(word) ?
                                <span key={i} className="highlight">{word} </span> :
                                word + ' '
                            })}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="feature-content">
                    <h2 className="feature-title">{slide.title}</h2>
                    <div className="feature-list">
                      {slide.features.map((feature, index) => (
                        <div key={index} className="feature-item">
                          <span className="feature-star">✦</span>
                          <div className="feature-item-content">
                            <h3>{feature.title}</h3>
                            <p>{feature.description}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="features-image">
                    <img src={slide.image} alt="Fonctionnalité" />
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );

};

export default FeaturesSlider;