import React, { useState } from 'react';
import userApi from '../../api/user.api';
import { showSuccessMessage } from '../../utils/messageHelper';

const ChangeEmailComponent = ({ actionAfterSuccess }) => {
    const [newEmail, setNewEmail] = useState('');
    const [password, setPassword] = useState('');
    const [code, setCode] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState('change'); // "change" or "verify"

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleChangeEmail = async (e) => {
        e.preventDefault();
        setMessage('');
        setError('');
        setLoading(true);

        if (!validateEmail(newEmail)) {
            setError('Veuillez entrer une adresse email valide.');
            setLoading(false);
            return;
        }

        try {
            const token = localStorage.getItem('token');
            const data = await userApi.changeEmailRequest(token, { newEmail, password });

            if (data.success) {
                setMessage('Un code de vérification a été envoyé à votre nouvelle adresse email. Pour confirmer le chagement entrez le code dans le champ suivant puis appuyer sur vérifier!');
                setStep('verify');
            } else {
                setError(data.message);
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };
    const handleVerifyCode = async () => {
        setMessage('');
        setError('');
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const userId = localStorage.getItem('userId');
            if (token && userId) {
                const response = await userApi.verifyChangeEmailCode(token, userId, code);
                if (response.success) {
                    showSuccessMessage('Adresse email changée et vérifiée avec succès!');
                    //setMessage('Adresse email changée et vérifiée avec succès!');
                    setStep('change');
                    setNewEmail('');
                    setPassword('');
                    setCode('');
                    if (actionAfterSuccess) {
                        actionAfterSuccess();
                    }
                } else {
                    setError(response.message);
                }
            } else {
                throw new Error('Identifiants invalides! Veuillez vous reconnecter et réessayer.');
            }
        } catch (error) {
            setError(error.message || 'Erreur lors de la vérification.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="p-6">
            {message && (
                <p className="mb-4 p-3 rounded bg-green-200 text-green-700 text-center font-medium">{message}</p>
            )}
            {error && (
                <p className="mb-4 p-3 rounded bg-red-200 text-red-600 text-center font-medium">{error}</p>
            )}
            {step === 'change' && (
                <form onSubmit={handleChangeEmail} className="space-y-4">
                    <h2 className="text-2xl font-bold text-center mb-6">Changer l'adresse email</h2>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Nouveau email</label>
                        <input
                            type="email"
                            value={newEmail}
                            onChange={(e) => setNewEmail(e.target.value)}
                            className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            placeholder="Entrer le nouveau adresse email"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Mot de passe</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            placeholder="Entrer votre mot de passe pour valider"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 focus:ring-2 focus:ring-blue-500"
                        disabled={loading}
                    >
                        {loading ? 'Chargement...' : 'Confirmer le changement'}
                    </button>
                </form>
            )}
            {step === 'verify' && (
                <div>
                    <h2 className="text-2xl font-bold text-center mb-6">Validation du changement de l'adresse email</h2>
                    <input
                        type="text"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        placeholder="Code de confirmation"
                        className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                    <button
                        onClick={handleVerifyCode}
                        className="w-full bg-green-600 text-white py-2 rounded-lg hover:bg-green-700 focus:ring-2 focus:ring-green-500 mt-4"
                        disabled={loading}
                    >
                        {loading ? 'Chargement...' : 'Vérifier'}
                    </button>
                </div>
            )}
        </div>
    );
};

export default ChangeEmailComponent;