// FiltersColumn.js
import React, { useState, useEffect, useRef, useCallback  } from 'react';
import { useSearchParams } from 'react-router-dom';
import { MapPin, X, Loader2 } from 'lucide-react';
import debounce from 'lodash/debounce';
import { searchCities } from '../../data/searchUtils';
import Filters from '../../data/Filters';
import './FiltersColumn.css';

const FiltersColumn = ({
    className = "",
    selectedServices = [],
    setSelectedServices,
    selectedPrices = [],
    setSelectedPrices,
    minimumRating = 0,
    setMinimumRating,
    radius = 5000,
    setRadius,
    onLocationChange,
    serviceCategories = [],
    priceRanges = [],
    beauticians = [] // Ajout de la prop beauticians
}) => {
    const wrapperRef = useRef(null);
    const [searchParams] = useSearchParams();

    const [locationTerm, setLocationTerm] = useState('');
    const [showLocationSuggestions, setShowLocationSuggestions] = useState(false);
    const [locationSuggestions, setLocationSuggestions] = useState([]);
    const [locationSelectedIndex, setLocationSelectedIndex] = useState(-1);
    const [isLoading, setIsLoading] = useState(false);

    const debouncedSearch = useCallback(
        debounce(async (value) => {
        if (value.length < 2) {
            setLocationSuggestions([]);
            setShowLocationSuggestions(false);
            return;
        }

        setIsLoading(true);
        try {
            const results = await searchCities(value);
            setLocationSuggestions(results);
            setShowLocationSuggestions(true);
        } catch (error) {
            console.error('Erreur recherche ville:', error);
            setLocationSuggestions([]);
        } finally {
            setIsLoading(false);
        }
        }, 200),
        []
    );

    const handleLocationKeyDown = (e) => {
        switch (e.key) {
        case 'ArrowDown':
            e.preventDefault();
            setLocationSelectedIndex(prev => 
            prev < locationSuggestions.length - 1 ? prev + 1 : prev
            );
            break;
        case 'ArrowUp':
            e.preventDefault();
            setLocationSelectedIndex(prev => prev > -1 ? prev - 1 : -1);
            break;
        case 'Enter':
            e.preventDefault();
            if (locationSelectedIndex > -1) {
            handleLocationSelect(locationSuggestions[locationSelectedIndex]);
            }
            break;
        case 'Escape':
            e.preventDefault();
            setShowLocationSuggestions(false);
            break;
        default:
            break;
        }
    };

    const handleLocationSelect = (suggestion) => {
        setLocationTerm(suggestion.text);
        onLocationChange({
            lat: suggestion.coordinates[0],
            lng: suggestion.coordinates[1]
        });
        setShowLocationSuggestions(false);
    };

    const clearSearch = () => {
        setLocationTerm('');
        setShowLocationSuggestions(false);
        onLocationChange(null);
    };
    

    // Fermeture des suggestions au clic extérieur
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setShowLocationSuggestions(false);  // Changé ici
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
        const serviceParam = searchParams.get('service');
        if (serviceParam && serviceCategories.length > 0) {
            // Chercher la catégorie correspondante (insensible à la casse)
            const matchingCategory = serviceCategories.find(
                category => category.name.toLowerCase() === serviceParam.toLowerCase()
            );

            if (matchingCategory && !selectedServices.includes(matchingCategory.id)) {
                // Ajouter à la sélection si pas déjà sélectionné
                setSelectedServices([...selectedServices, matchingCategory.id]);
            }
        }
    }, [searchParams, serviceCategories]); 



    return (
        <div className={`filters-column ${className}`}>
            <div className="search-box-container" ref={wrapperRef}>
                <div className="search-input-section">
                    <MapPin size={20} />
                    <input
                        type="text"
                        className="search-input"
                        placeholder="Saisissez une ville..."
                        value={locationTerm}
                        onChange={(e) => {
                            setLocationTerm(e.target.value);
                            debouncedSearch(e.target.value);
                        }}
                        onFocus={() => {
                            if (locationTerm.length >= 2) {
                                setShowLocationSuggestions(true);
                            }
                        }}
                        onKeyDown={handleLocationKeyDown}
                    />
                    {locationTerm && (
                        <button
                            className="clear-button"
                            onClick={clearSearch}
                            aria-label="Effacer la recherche"
                        >
                            <X size={16} />
                        </button>
                    )}
                </div>

                {showLocationSuggestions && locationTerm.length >= 2 && (
                    <div className="suggestions-dropdown location-suggestions">
                        {isLoading ? (
                            <div className="suggestion-loading">
                                <Loader2 className="animate-spin" size={20} />
                                <span>Recherche en cours...</span>
                            </div>
                        ) : locationSuggestions.length > 0 ? (
                            locationSuggestions.map((suggestion, index) => (
                                <div
                                    key={`${suggestion.type}-${index}`}
                                    className={`suggestion-item ${locationSelectedIndex === index ? 'selected' : ''}`}
                                    onClick={() => handleLocationSelect(suggestion)}
                                >
                                    <div className="suggestion-content">
                                        <span className="suggestion-text">{suggestion.text}</span>
                                        {suggestion.subtext && (
                                            <span className="suggestion-subtext">
                                                {suggestion.subtext}
                                            </span>
                                        )}
                                    </div>
                                    <span className={`suggestion-type ${suggestion.type}`}>
                                        {suggestion.typeLabel}
                                    </span>
                                </div>
                            ))
                        ) : (
                            <div className="suggestion-empty">Aucune ville trouvée</div>
                        )}
                    </div>
                )}
            </div>

            {/* Ajouter le bouton de réinitialisation ici */}
            <button 
                className="reset-filters-button"
                onClick={() => {
                    clearSearch();  // Changé ici
                    setSelectedServices([]);
                    setSelectedPrices([]);
                    setMinimumRating(0);
                    setRadius(5000);
                }}
            >
                Réinitialiser les filtres
            </button>

            <div className="filters-scroll">
                <Filters
                    selectedServices={selectedServices}
                    onServicesChange={setSelectedServices}
                    selectedPrices={selectedPrices}
                    onPricesChange={setSelectedPrices}
                    minimumRating={minimumRating}
                    onRatingChange={setMinimumRating}
                    radius={radius}
                    onRadiusChange={setRadius}
                    serviceCategories={serviceCategories}
                    priceRanges={priceRanges}
                />
            </div>
        </div>
    );
};

export default FiltersColumn;