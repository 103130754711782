import React, { useContext, useEffect, useRef, useState } from 'react'
import { NavLink, Outlet, useLocation } from 'react-router-dom'
import {
    UserCircle,
    Calendar,
    Clock,
    Bell,
    ChevronDown,
    MessageCircle // Ajout de l'icône message

} from 'lucide-react'
import { AppContext } from '../../../contexts/AppContext'
import { useMessage } from '../../../contexts/MessageContext' // Import du context message
import bgContact from '../../../assets/images/bg-contact.png'


const ClientPortalLayout = () => {
    const { unreadNotificationCount, setNotificationChangeTrigger } = useContext(AppContext)
    const { unreadMessages } = useMessage(); // Ajout du hook pour les messages


    const location = useLocation()
    const [isMobile, setIsMobile] = useState(false)
    const [menuOpened, setMenuOpened] = useState(false)
    const menuRef = useRef(null)

    // Liste des chemins spécifiques
    const pathsToTriggerAction = [
        "/client-dashboard/",
        "/client-dashboard/agenda",
        "/client-dashboard/historiques",
        "/client-dashboard/notifications"
    ]

    const handleResize = () => {
        setIsMobile(window.innerWidth < 768)
        setMenuOpened(window.innerWidth >= 768)
    }

    const handleMenu = () => {
        if(isMobile)
            setMenuOpened(prev => !prev)
    }

    useEffect(() => {
        handleResize()
        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    useEffect(() => {
        if (pathsToTriggerAction.includes(location.pathname)) {
            setNotificationChangeTrigger(prev => !prev)
        }
    }, [location.pathname])

    const menuItems = [
        {
            title: "Mon Profil",
            icon: <UserCircle size={20} />,
            path: "/client-dashboard/",
            exact: true, // Propriété pour activer uniquement ce chemin exact
        },
        {
            title: "Messages",
            icon: <MessageCircle size={20} />,
            path: "/client-dashboard/messaging", // Redirection directe vers /messaging
            suffix: unreadMessages > 0 ? (
                <div className="relative">
                    <span style={{
                        backgroundColor: 'red',
                        borderRadius: "50%",
                        padding: "0px 6px",
                        color: "white"
                    }}>{unreadMessages > 9 ? '9+' : unreadMessages}</span>
                </div>
            ) : null
        },
        {
            title: "Mes rendez-vous",
            icon: <Calendar size={20} />,
            path: "/client-dashboard/agenda",
        },
        {
            title: 'Historiques',
            icon: <Clock size={20} />,
            path: "/client-dashboard/historiques",
        },
        {
            title: <div className='flex items-center gap-2'>
                <span>Notifications</span>
                <div className="relative">
                    <span style={{
                        backgroundColor: 'red',
                        borderRadius: "50%",
                        padding: "0px 6px",
                        color: "white"
                    }}>{unreadNotificationCount}</span>
                </div>
            </div>,
            icon: <Bell size={20} />,
            path: "/client-dashboard/notifications",
        }
    ]

    return (
        
        <div className="min-h-screen flex flex-col md:flex-row border" style={{
            backgroundImage: `url(${bgContact})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat"
        }}>
            {/* Sidebar */}
            <aside className="w-full md:w-1/6 flex flex-col border-r bg-white">
                <header
                    className="flex items-center justify-between cursor-pointer md:cursor-default"
                    onClick={handleMenu}
                >
                    <h1 className="p-3 text-xl text-[#bd193b] font-semibold border-b">BeautyPro</h1>
                    <button className="p-4 md:hidden">
                        <ChevronDown className={`cursor-pointer ${menuOpened && "rotate-180"} duration-300`} />
                    </button>
                </header>

                <div
                    ref={menuRef}
                    className="overflow-hidden duration-500 px-3 flex flex-col align-center justify-between gap-4 md:!height-auto"
                    style={{
                        height: menuOpened ? `${menuRef.current?.scrollHeight}px` : '0px',
                        transition: 'height 0.5s ease',
                    }}
                >
    
                    {menuItems.map((item, index) =>
                        <NavLink
                            key={index}
                            to={item.path}
                            end={item.exact}
                            className={({ isActive }) =>
                                `flex items-center gap-2 no-underline duration-300 ${isActive ? "text-[#bd193b]" : "text-gray-800"}` 
                            }
                        >
                            {item.icon}
                            <span className="flex items-center gap-2">
                                {typeof item.title === 'string' ? item.title : item.title}
                                {item.suffix}
                            </span>
                        </NavLink>
                    )}
                </div>
            </aside>

            {/* Main content */}
            <div className="w-full flex justify-center pr-0">
                <div className="w-full pr-0">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default ClientPortalLayout
