import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa6";

const PasswordField = ({
    label = "Password", 
    value = "", 
    onChange = () => { },
    className = "",
    placeholder = "",
    ...rest // Capturer tous les autres attributs
}) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };

    return (
        <div className={`flex flex-col gap-2 ${className}`}>
            {/* Label */}
            {label && <label className="text-sm font-medium text-gray-700">{label}</label>}

            {/* Champ mot de passe */}
            <div className="relative">
                <input
                    type={showPassword ? "text" : "password"}
                    value={value}
                    onChange={onChange}
                    className="w-full px-3 py-2 pr-10 border rounded-lg shadow-sm focus:ring focus:ring-blue-300 focus:outline-none"
                    placeholder={placeholder}
                    {...rest} // Appliquer les attributs restants ici
                />
                {/* Icône pour afficher/masquer */}
                <button
                    type="button"
                    className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 focus:outline-none"
                    onClick={togglePasswordVisibility}
                >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
            </div>
        </div>
    );
};

export default PasswordField;