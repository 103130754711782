import { API_URL } from "../utils/constants";

const promoCodesProApi = {

    // Get all promo codes
    getAllPromoCodes: async (token, beautician_id) => {
        const response = await fetch(`${API_URL}/promo-codes-pro/beauticians/${beautician_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });
        return response.json();
    },

    // Get a single promo code by ID
    getPromoCodeById: async (token, promoCodeId) => {
        const response = await fetch(`${API_URL}/promo-codes-pro/${promoCodeId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });
        return response.json();
    },

    // Create a new promo code
    createPromoCode: async (token, promoCodeData) => {
        const response = await fetch(`${API_URL}/promo-codes-pro`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(promoCodeData)
        });

        if (!response.ok) {
            throw new Error('Une erreur s\'est produite!')
        }
        
        return response.json();
    },

    // Update an existing promo code
    updatePromoCode: async (token, promoCodeId, promoCodeData) => {
        const response = await fetch(`${API_URL}/promo-codes-pro/${promoCodeId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(promoCodeData)
        });

        if (!response.ok) {
            throw new Error('Une erreur s\'est produite!')
        }

        return response.json();
    },

    // Delete a promo code
    deletePromoCode: async (token, promoCodeId) => {
        const response = await fetch(`${API_URL}/promo-codes-pro/${promoCodeId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });
        return response.json();
    },

    //check promotion
    checkPromotion: async (token, data) => {
        const response = await fetch(`${API_URL}/promo-codes-pro/check-promotion`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        });
        return response.json();
    },

    incrementPromoCodeUsage: async (token, promoCodeId) => {
        const response = await fetch(`${API_URL}/promo-codes-pro/${promoCodeId}/increment-usage`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        return response.json();
    }
};

export default promoCodesProApi;