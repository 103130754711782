import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { motion } from "motion/react"

import defaultProfile from "../../assets/images/default-profile.png"

import { FaX } from "react-icons/fa6"
import { dashboardContext } from '../../pages/dashboard/Dashboard'
import { FaStar } from 'react-icons/fa'
import { BiStar } from 'react-icons/bi'
import { BASE_URL } from '../../utils/constants'
import MySpinner from '../loader/Spinner'
import { beauticiansSubCategoriesApi } from '../../api/beauticians_subcategories.api'
import { groupPrestationsByCategoryId } from '../../utils/functions'
import { sitesApi } from '../../api/sites.api'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import beauticianApi from '../../api/beautician.api'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import ReactSwitch from 'react-switch';
import SiteInfoComponent from './SiteInfoComponent';
import userApi from '../../api/user.api';
import { sendEmailAccountStatusNotification } from '../../utils/mailServices';
import promoCodesApi from '../../api/promo_codes.api';
import emailApi from '../../api/email.api';
import { showSuccessMessage } from '../../utils/messageHelper';
import abonnementApi from '../../api/abonnement.api';


const UserSheet = () => {
    const {
        showDeletionModal,
        showEditModal,
        userData,
        showUserSheetModal,
        setShowBeauticianCommentaire,
        setReloadTrigger,
        setIsFromSheet,
        dynamicUserData,
        setDynamicUserData
    } = useContext(dashboardContext)

    //const [data, setData] = useState(userData)
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    const [localIsAvailable, setLocalIsAvailable] = useState(false);
    const [localIsBeauticianActive, setLocalIsBeauticianActive] = useState(false);
    const [localIsAccountActiveUser, setLocalIsAccountActiveUser] = useState(false);
    const [localIsSentInstructions, setLocalIsSendInstructions] = useState(false);
    const [offres, setOffres] = useState([{
        id: 'free-trial',
        name: 'Essaie gratuit de 10 jours sur l\'offre classique',
        code: ""
    }]);

    const getRating = (rate) => {
        return (
            <span className="flex items-center gap-2">
                {rate > 0 ? <FaStar className="text-[#bd193b]" /> : <BiStar />}
                {rate > 1 ? <FaStar className="text-[#bd193b]" /> : <BiStar />}
                {rate > 2 ? <FaStar className="text-[#bd193b]" /> : <BiStar />}
                {rate > 3 ? <FaStar className="text-[#bd193b]" /> : <BiStar />}
                {rate > 4 ? <FaStar className="text-[#bd193b]" /> : <BiStar />}
            </span>
        )
    }

    const acceptRegistration = (id, name, email, user_id) => {
        Swal.fire({
            title: "Voulez-vous vraiment accepter l'inscription?",
            icon: "question",
            showCancelButton: true,
            showConfirmButton: true,
            cancelButtonText: "Non",
            confirmButtonText: "Oui, accepter"
        }).then(async (result) => {
            if (result.isConfirmed) {
                // Afficher le loader
                Swal.fire({
                    title: 'Traitement en cours...',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });

                try {
                    await beauticianApi.acceptRegistration(
                        localStorage.getItem('token'),
                        id,
                        email,
                        name
                    );

                    // on active le free trial
                    /*const res = await freeTrialApi.create(localStorage.getItem('token'), { user_id: user_id });

                    if(res.success) {
                        showWarningMessage('L\'utilisateur ne peut plus souscrire à la periode d\'essaie!');
                    }*/

                    setLocalIsBeauticianActive(true);

                    // Indiquer le succès
                    Swal.fire({
                        title: "Succès!",
                        text: "L'inscription a été acceptée.",
                        icon: "success",
                        timer: 2000,
                        showConfirmButton: false
                    });
                } catch (error) {
                    // Gestion des erreurs
                    Swal.fire({
                        title: "Erreur",
                        text: "Une erreur est survenue lors de l'acceptation de l'inscription.",
                        icon: "error",
                    });
                }
            }
        });
    };

    const MySwal = withReactContent(Swal);

    const handleAvailabilityChange = (first_name, last_name, id, is_available) => {
        MySwal.fire({
            title: 'Confirmation',
            text: `Voulez-vous vraiment modifier la disponibilité de ${first_name} ${last_name} ?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
        }).then(async (result) => {
            if (result.isConfirmed) {
                // Mettre à jour la disponibilité via une API ou une méthode
                setLocalIsAvailable(prev => !prev);
                await beauticianApi.updateAvailability(localStorage.getItem('token'), id, { is_available: !is_available });
                //setReloadTrigger(prev => !prev);
            }
        });
    };

    useEffect(() => {
        const getAllData = async () => {
            setIsLoading(true);
            setLocalIsAvailable(userData['is_available']);
            setLocalIsAccountActiveUser(userData['is_account_active']);
            const sites = await sitesApi.getSiteByBeauticianId(userData["id"]);
            userData["social_networks"] = sites.data;
            const services = await beauticiansSubCategoriesApi.getByBauticianId(userData["id"]);
            const grouped_cat = groupPrestationsByCategoryId(services);

            userData["prestations"] = grouped_cat;

            setLocalIsBeauticianActive(userData['is_active']);

            setDynamicUserData({
                ...userData
            })

            // get promo codes 
            const promo_codes = await promoCodesApi.getAllPromoCodes(localStorage.getItem('token'));

            if (Array.isArray(promo_codes)) {
                let arr = [];
                promo_codes.forEach((element) => {
                    if ([2, 6].includes(element.months_valid) && parseInt(element.discount_percentage) === 100) {
                        arr.push({ 
                            id: element.id, 
                            name: `Essaie gratuit de ${element.months_valid} mois sur l'offre ${element.offre_name}`,
                            code: element.code
                        });
                    }
                });
                setOffres([
                    ...offres,
                    ...arr
                ]);
            }

            const check_abonnements = await abonnementApi.getAllAbonnementByUserId(localStorage.getItem('token'), userData['user_id']);
            if(Array.isArray(check_abonnements) && check_abonnements.length > 0) {
                setLocalIsSendInstructions(true);
            }

            setIsLoading(false);
        }

        getAllData()

        return () => {
            setDynamicUserData([])
        }
    }, []);

    const budgetCategory = {
        'moderate': 'Modérée',
        'economic': 'Economique',
        'premium': 'Premium'
    };

    const getImageUrl = (profilePhoto) => {
        if (!profilePhoto) return defaultProfile;
        try {
            return `${BASE_URL}/images/beauticians/${encodeURIComponent(profilePhoto)}`;
        } catch (error) {
            console.error('Error with image path:', error);
            return defaultProfile;
        }
    };

    const handleDeleteClick = () => {
        setIsFromSheet(true);
        showDeletionModal(true);
    }

    const handleEditClick = () => {
        setIsFromSheet(true);
        showEditModal(true);
    }

    const handleChangeStatusCompteUser = (user_id) => {
        Swal.fire({
            title: `Voulez-vous vraiment ${localIsAccountActiveUser ? 'desactiver' : 'activer'} le compte?`,
            icon: "question",
            showCancelButton: true,
            showConfirmButton: true,
            cancelButtonText: "Non",
            confirmButtonText: `Oui, ${localIsAccountActiveUser ? 'desactiver' : 'activer'}`
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {

                    let raison = '';

                    // Pour la désactivation, on demande la raison
                    if (localIsAccountActiveUser) {
                        const { value: inputRaison } = await Swal.fire({
                            title: 'Raison de la désactivation',
                            input: 'textarea',
                            inputLabel: 'Raison',
                            inputPlaceholder: 'Raison de la désactivation',
                            inputAttributes: {
                                'aria-label': 'Raison de la désactivation'
                            },
                            showCancelButton: true,
                            confirmButtonText: 'Valider',
                            cancelButtonText: 'Annuler',
                            inputValidator: (value) => {
                                if (!value) {
                                    return 'Vous devez renseigner la raison de la désactivation!';
                                }
                            }
                        });

                        if (!inputRaison) {
                            // L'utilisateur a annulé ou n'a pas validé
                            return;
                        }

                        // La raison entrée par l'utilisateur
                        raison = inputRaison;
                    }

                    sendEmailAccountStatusNotification(user_id, !localIsAccountActiveUser ? 'active' : 'inactive', raison);

                    // Afficher le loader
                    Swal.fire({
                        title: 'Traitement en cours...',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });

                    await userApi.changeAccountStatus(
                        localStorage.getItem('token'),
                        !localIsAccountActiveUser,
                        user_id
                    );

                    setLocalIsAccountActiveUser(prev => !prev);

                    // Indiquer le succès
                    Swal.fire({
                        title: "Succès!",
                        text: "Le status du compte a été modifié avec succès.",
                        icon: "success",
                        timer: 2000,
                        showConfirmButton: false
                    });
                } catch (error) {
                    // Gestion des erreurs
                    Swal.fire({
                        title: "Erreur",
                        text: "Une erreur est survenue.",
                        icon: "error",
                    });
                }
            }
        });

    }

    const handleSentOffreInstructionClick = async (id, code) => {
        Swal.fire({
            title:"Confirmez l'action?",
            text: "Nous allons envoyer un email au professionnel pour les instructions à suvivre pour activer l'offre.",
            icon: 'question',
            showCancelButton: true,
            showConfirmButton: true, 
            confirmButtonText: "Oui, confirmer",
            cancelButtonText: "Annuler"
        }).then(async (result) => {
            if(result.isConfirmed) {
                if(id === "free-trial") {
                    await emailApi.sendEmailInstructionsFreeTrial(dynamicUserData["email"], dynamicUserData['business_name'] ?? `${dynamicUserData["first_name"]} ${dynamicUserData["last_name"]}`)
                } else {
                    await emailApi.sendEmailInstructionsCodePromo(dynamicUserData["email"], dynamicUserData['business_name'] ?? `${dynamicUserData["first_name"]} ${dynamicUserData["last_name"]}`, code);
                }
            }
            showSuccessMessage("L'email a été bien envoyé au professionnel!");
            setLocalIsSendInstructions(prev => !prev);
        });
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="absolute top-0 z-20 left-0 w-screen h-screen bg-gray-700 bg-opacity-80 flex items-center justify-center"
        >
            <div
                onSubmit={(e) => e.preventDefault()}
                className={`relative ${dynamicUserData['user_type'] === 'beautician' ? 'w-5/6 min-h-[50vh]' : 'w-1/2 min-h-[20vh]'} p-4 max-h-[90vh] overflow-y-auto flex flex-col gap-2 bg-white border rounded shadow`}
            >
                <button
                    onClick={() => {
                        showUserSheetModal(false);
                        setReloadTrigger(prev => !prev);
                    }}
                    className="absolute top-6 right-6"
                >
                    <FaX />
                </button>

                {isLoading ? <div>
                    <MySpinner height={"40vh"} />
                </div> :
                    <div>
                        <div className="flex items-center justify-between gap-3 py-2 px-5 bg-gray-100">
                            <div className="flex flex-wrap gap-3 items-center">
                                <button className='px-3 py-2 text-sm rounded bg-yellow-400 hover:bg-yellow-500' onClick={handleEditClick}>Modifier les informations</button>
                                <button className='px-3 py-2 text-sm rounded bg-red-500 hover:bg-red-600 text-white' onClick={handleDeleteClick}>Supprimer le profil</button>
                                <button className='px-3 py-2 text-sm rounded bg-yellow-400 hover:bg-yellow-500' onClick={() => handleChangeStatusCompteUser(dynamicUserData['user_type'] === "beautician" ? dynamicUserData["user_id"] : dynamicUserData['id'])}>{!localIsAccountActiveUser ? 'Activer' : 'Desactiver'} le compte</button>
                                {dynamicUserData['user_type'] === 'beautician' && <>
                                    {!localIsBeauticianActive &&
                                        <button className='px-3 py-2 text-sm rounded text-white bg-green-500 hover:bg-green-600' onClick={() => acceptRegistration(dynamicUserData['id'], (dynamicUserData['first_name'] + ' ' + dynamicUserData['last_name']), dynamicUserData['email'], dynamicUserData['user_id'])}>Accepter l'inscription</button>
                                    }
                                    <div className="flex items-center gap-2">
                                        <ReactSwitch
                                            checked={localIsAvailable}
                                            onChange={() => handleAvailabilityChange(dynamicUserData['first_name'], dynamicUserData['last_name'], dynamicUserData['id'], dynamicUserData['is_available'])}
                                            offColor="#f87171" // Couleur désactivée
                                            onColor="#4ade80" // Couleur activée
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            width={40} // Largeur du switch
                                            height={20} // Hauteur du switch
                                            handleDiameter={15}
                                        />
                                        <label htmlFor=""> Disponibilité</label>
                                    </div>
                                </>}
                            </div>
                            {dynamicUserData['user_type'] === 'beautician' && <>
                                <button className='px-3 py-2 text-sm rounded bg-gray-200 border border-gray-800 hover:bg-gray-300' onClick={() => navigate(`/beauticians/${dynamicUserData['slug']}`)}>Voir le profil</button>
                            </>}
                        </div>
                        <div className='flex w-full gap-3 mt-2'>
                            <div className={`${dynamicUserData['user_type'] === 'beautician' ? 'w-1/2' : 'w-full'}`}>
                                <section className="flex flex-col gap-2 mt-4 w-full">
                                    <section className={`flex gap-3 w-full`}>
                                        {
                                            dynamicUserData['profile_photo'] && <div className="size-48 border rounded shadow">
                                                <img className="w-full h-full" alt={dynamicUserData["last_name"]} src={dynamicUserData['profile_photo'] ? getImageUrl(dynamicUserData['profile_photo']) : defaultProfile} />
                                            </div>
                                        }
                                        <div className="flex flex-col gap-1 w-full">
                                            <div className='flex justify-between gap-2 w-full'>
                                                <h5 className='underline'>Informations personnelles</h5>
                                            </div>
                                            <span className="text-base"><b>Nom complet:</b> {`${dynamicUserData["first_name"]} ${dynamicUserData["last_name"]}`}</span>
                                            {dynamicUserData['business_name'] && <span className="text-base"><b>Nom commercial:</b> {dynamicUserData["business_name"]}</span>}
                                            {dynamicUserData['address'] && <span className="text-base"><b>Adresse:</b> {dynamicUserData["address"]}</span>}
                                            {dynamicUserData['budget_category'] && <span className="text-base"><b>Catégorie budgétaire:</b> {budgetCategory[dynamicUserData["budget_category"]]}</span>}
                                            {dynamicUserData['average_rating'] && <div className='flex items-center gap-2'><b>Note globale :</b> {getRating(dynamicUserData['average_rating'])}</div>}
                                            <span><b>Date inscription: </b>{format(dynamicUserData['created_at'], 'd MMMM yyyy à HH:mm', { locale: fr })}</span>
                                        </div>
                                    </section>
                                    <div className="flex flex-col gap-1">
                                        <h5 className='underline'>Contacts</h5>
                                        <div className="flex items-center gap-2">
                                            <span className="font-semibold">Email: </span>
                                            <span>{dynamicUserData["email"]}</span>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <span className="font-semibold">Téléphone: </span>
                                            <span>{dynamicUserData["phone"]}</span>
                                        </div>
                                    </div>
                                    {dynamicUserData['user_type'] === "beautician" && <>
                                        <div className="flex flex-col gap-1">
                                            <h5 className='underline'>A propos</h5>
                                            <p>{dynamicUserData['description']}</p>
                                        </div>
                                        <div>
                                            <button className='px-3 py-2 border border-gray-500 bg-gray-200 hover:bg-gray-300 rounded' onClick={() => setShowBeauticianCommentaire(true)}>Voir les avis</button>
                                        </div>
                                    </>}

                                    {(dynamicUserData['user_type'] === "beautician" && !localIsSentInstructions && localIsAccountActiveUser && localIsBeauticianActive) && <>
                                        <div className="flex flex-col gap-1 mt-3">
                                            <h5 className='underline'>Envoyer les instructions pour activer un offre gratuit ou avec code promo au professionnel</h5>
                                            {offres.map(element =>
                                                <button 
                                                key={element.id} 
                                                className='px-3 py-2 border border-gray-500 bg-gray-200 hover:bg-gray-300 rounded'
                                                onClick={() => handleSentOffreInstructionClick(element.id, element.code)}
                                                >
                                                    {element.name}
                                                </button>
                                            )}
                                        </div>
                                    </>}
                                </section>
                            </div>
                            {dynamicUserData['user_type'] === 'beautician' && <div className='w-1/2'>
                                <SiteInfoComponent />
                            </div>}
                        </div>
                    </div>
                }

            </div>
        </motion.div>
    )
}

export default UserSheet