import React, { useContext, useEffect, useState } from 'react';
import { BeauticianPortalContext } from '../../../context/BeauticianPortalContext';
import './BeauticianGestionCaisse.css';
import beauticianApi from '../../../api/beautician.api';
import ShowMonthDetailsModal from './gestion-caisse/ShowMonthDetailsModal';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

const BeauticianGestionCaisse = () => {

  const { profileData } = useContext(BeauticianPortalContext);

  const [revenueInfo, setRevenueInfo] = useState(null);
  const [showMonthDetailsModal, setShowMonthDetailsModal] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedPaymentType, setSelectedPaymentType] = useState(null);

  const today = new Date();
  const formattedTodayDate = format(today, 'd MMMM yyyy', { locale: fr });

  const fetchRevenueInfo = async (beautician_id) => {
    try {
      const response = await beauticianApi.getAllAppointmentInfoByBeauticianId(localStorage.getItem('token'), beautician_id);
      if (response.success) {
        setRevenueInfo(response.data);
      }
    }
    catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    if (profileData) {
      fetchRevenueInfo(profileData.beautician_id);
    }
  }, [profileData]);

  const PAYMENT_TYPE = {
    full: 'Paiement complet',
    deposit: 'Acompte',
    onsite: 'Paiement sur site',
  }

  const handleCloseShowMonthDetailsModal = () => {
    setShowMonthDetailsModal(false);
  }

  const handleClickMonth = (month, payment_type) => {
    setSelectedMonth(month);
    setSelectedPaymentType(payment_type);
    setShowMonthDetailsModal(true);
  }

  return (
    <div className="agenda-container p-6 bg-gray-100 rounded-lg shadow-md">

      <div className="agenda-header mb-4">
        <h4 className="text-xl font-bold text-gray-800">Revenu mensuel à la date du {formattedTodayDate}</h4>
      </div>

      <div className="space-y-6">
        <h5>Paiement par carte bancaire</h5>
        {/* Section des revenus mensuels */}
        <div className="monthly-earnings">
          <ul className="overflow-y-auto max-h-[50vh] space-y-3 p-3">
            {revenueInfo && revenueInfo.monthlyEarnings.length > 0 ? (
              revenueInfo.monthlyEarnings.map((item, index) => (
                <li key={index} onClick={() => handleClickMonth(item.month, item.payment_type)} className="bg-white cursor-pointer hover:bg-gray-200 p-4 rounded-lg shadow flex justify-between items-center">
                  <span className="text-gray-600">{format(new Date(item.month), 'MMMM yyyy', {locale: fr})}</span>
                  <span className="text-gray-500">{PAYMENT_TYPE[item.payment_type]}</span>
                  <div>
                    <div className="text-yellow-600 mb-2 font-semibold">Total payé en ligne : {item.total_earnings} €</div>
                    <div className="text-green-600 font-semibold">Total coût des services : {item.total_prices} €</div>
                  </div>
                </li>
              ))
            ) : (
              <p className="text-gray-500">Aucune donnée disponible pour les revenus mensuels.</p>
            )}
          </ul>
        </div>

        <div className="space-y-6">
          <h5>Paiement sur site</h5>
          {/* Section des revenus mensuels */}
          <div className="monthly-earnings">
            <ul className="overflow-y-auto max-h-[50vh] space-y-3 p-3">
              {revenueInfo && revenueInfo.monthlyEarningsOnSite.length > 0 ? (
                revenueInfo.monthlyEarningsOnSite.map((item, index) => (
                  <li key={index} onClick={() => handleClickMonth(item.month, item.payment_type)} className="bg-white cursor-pointer hover:bg-gray-200 p-4 rounded-lg shadow flex justify-between items-center">
                    <span className="text-gray-600">{format(new Date(item.month), 'MMMM yyyy', {locale: fr})}</span>
                    <span className="text-gray-500">{PAYMENT_TYPE[item.payment_type]}</span>
                    <div>
                      <div className="text-green-600 font-semibold">Total coût des services : {item.total_prices} €</div>
                    </div>
                  </li>
                ))
              ) : (
                <p className="text-gray-500">Aucune donnée disponible pour les revenus mensuels sur site.</p>
              )}
            </ul>
          </div>
        </div>


        {/* Section des totaux globaux */}
        <h5>Total global</h5>
        <div className='flex flex-col md:flex-row flex-wrap'>

          <div className="grand-total w-full md:w-2/3">
            <ul className="space-y-2 p-3">
              {revenueInfo && revenueInfo.grandTotal.length > 0 ? (
                revenueInfo.grandTotal.map((item, index) => (
                  <li key={index} className="bg-white p-4 rounded-lg shadow flex justify-between items-center">
                    <span className="text-gray-500">{PAYMENT_TYPE[item.payment_type]}</span>
                    <div>
                      <div className="text-yellow-600 mb-2 font-semibold">Total global payé en ligne : {item.grand_total} €</div>
                      <div className="text-green-600 font-semibold">Total global coût des services : {item.total_prices} €</div>
                    </div>
                  </li>
                ))
              ) : (
                <p className="text-gray-500">Aucune donnée disponible pour le total global.</p>
              )}
            </ul>
          </div>
          <div className="w-full md:w-1/3 p-3">
            <div className="bg-white p-4 rounded-lg shadow text-center">
              <h4 className="text-4xl font-semibold text-gray-700 mb-2">Total global</h4>
              <h6 className="text-3xl font-bold text-green-600">
                {(revenueInfo && (revenueInfo.grandTotalAll.total_prices || 0)) || 0} €
              </h6>
            </div>
          </div>

        </div>
      </div>

      {/* Modal show details par mois */}
      {showMonthDetailsModal &&
        <ShowMonthDetailsModal
          show={showMonthDetailsModal}
          handleClose={handleCloseShowMonthDetailsModal}
          selectedMonth={selectedMonth}
          selectedPaymentType={selectedPaymentType}
          beautician_id={profileData.beautician_id}
        />
      }
    </div>
  );
};

export default BeauticianGestionCaisse;