import React, { useContext, useEffect } from 'react';
import './PacksCommunication.css';
import { AppContext } from '../../contexts/AppContext';
import heroImage from '../../assets/pack_communication/18.png';
import imageOne from '../../assets/pack_communication/19.png';
import imageTwo from '../../assets/pack_communication/20.png';
import imageThree from '../../assets/pack_communication/21.png';
import './PacksCommunication.css';


const CheckedBox = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="3" fill="black"/>
      <path d="M12 5L6.5 10.5L4 8" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
  
const WhiteBoxWithCross = () => (
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="15" height="15" rx="2.5" fill="white" stroke="black"/>
    <path d="M12 5L6.5 10.5L4 8" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
);

// Remplacez le composant AnimatedBackground existant par celui-ci :

const AnimatedBackground = () => {
    React.useEffect(() => {
        const container = document.querySelector('.particles-container');
        if (!container) return;

        // Création des particules
        for (let i = 0; i < 15; i++) {
            const particle = document.createElement('div');
            particle.className = 'particle';
            const size = Math.random() * 5 + 3;
            particle.style.width = `${size}px`;
            particle.style.height = `${size}px`;
            particle.style.left = Math.random() * 100 + 'vw';
            particle.style.top = Math.random() * 100 + 'vh';
            particle.style.animationDelay = Math.random() * 5 + 's';
            particle.style.animationDuration = (Math.random() * 10 + 15) + 's';
            container.appendChild(particle);
        }

        // Création des formes géométriques
        const shapes = [
            { class: 'geometric-shape circle-shape', count: 3 },
            { class: 'geometric-shape square-shape', count: 3 },
            { class: 'geometric-shape triangle-shape', count: 3 }
        ];

        shapes.forEach(shape => {
            for (let i = 0; i < shape.count; i++) {
                const element = document.createElement('div');
                element.className = shape.class;
                element.style.left = Math.random() * 90 + 'vw';
                element.style.top = Math.random() * 90 + 'vh';
                element.style.animationDelay = Math.random() * 5 + 's';
                element.style.animationDuration = (Math.random() * 10 + 20) + 's';
                // Ajout d'une rotation aléatoire initiale
                element.style.transform = `rotate(${Math.random() * 360}deg)`;
                container.appendChild(element);
            }
        });

        // Nettoyage
        return () => {
            if (container) {
                container.innerHTML = '';
            }
        };
    }, []);

    return (
        <>
            <div className="particles-container" />
            <div className="background-shapes" />
        </>
    );
};

  const useIntersectionObserver = () => {
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add('visible');
            }
          });
        },
        {
          threshold: 0.1,
          rootMargin: '50px',
        }
      );
  
      const elements = document.querySelectorAll(
        '.fade-in, .slide-in-right, .slide-in-left, .scale-in'
      );
      elements.forEach((el) => observer.observe(el));
  
      return () => {
        elements.forEach((el) => observer.unobserve(el));
      };
    }, []);
  };

const PacksCommunication = () => {
    const { setActivePageForHeader } = useContext(AppContext);

    useIntersectionObserver();

    useEffect(() => {
        // Indiquer explicitement qu'on n'est pas sur la home
        setActivePageForHeader("");
        // Nettoyage au démontage du composant
        return () => {
            setActivePageForHeader("");
        };
    }, [setActivePageForHeader]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <section className="packs-hero">
                <img 
                    src={heroImage}
                    alt="Femme consultant son téléphone" 
                    className="packs-hero-background scale-in"
                />
                <div className="packs-hero-content slide-in-right">
                    <h1>Nos packs communications <span className="text-xl">✦</span></h1>
                </div>
            </section>
            <div className="main-content">
                <AnimatedBackground />
                <div className="packs-content-wrapper">
                    <section className="prices-section">
                        <div className="prices-container">
                            <h2 className="prices-title fade-in">Votre image, votre succès, notre expertise</h2>
                            <div className="price-cards">
                                {/* Carte Standard */}
                                <div className="price-card fade-in">
                                    <div className="browser-dots">
                                        <div className="browser-dot dot-red"></div>
                                        <div className="browser-dot dot-yellow"></div>
                                        <div className="browser-dot dot-green"></div>
                                    </div>
                                    <h3 className="price-card-title">Standard</h3>
                                    <div className="price-value">199€</div>
                                    <ul className="pack-price-list">
                                        <li className="pack-price-item">
                                            <span className="pack-price-icon"><CheckedBox /></span>
                                            <span className="pack-price-text">Conception logo</span>
                                        </li>
                                        <li className="pack-price-item">
                                            <span className="pack-price-icon"><CheckedBox /></span>
                                            <span className="pack-price-text">Charte graphique</span>
                                        </li>
                                        <li className="pack-price-item">
                                            <span className="pack-price-icon"><CheckedBox /></span>
                                            <span className="pack-price-text">4 templates pour vos réseaux sociaux</span>
                                        </li>
                                        <li className="pack-price-item">
                                            <span className="pack-price-icon"><WhiteBoxWithCross /></span>
                                            <span className="pack-price-text">Création d'une page Google Business et accompagnement à la gestion de la page</span>
                                        </li>
                                        <li className="pack-price-item">
                                            <span className="pack-price-icon"><WhiteBoxWithCross /></span>
                                            <span className="pack-price-text">Mise à disposition d'un calendrier réseaux sociaux</span>
                                        </li>
                                        <li className="pack-price-item">
                                            <span className="pack-price-icon"><WhiteBoxWithCross /></span>
                                            <span className="pack-price-text">Campagne de publicité sur Facebook et Instagram</span>
                                        </li>
                                        <li className="pack-price-item">
                                            <span className="pack-price-icon"><WhiteBoxWithCross /></span>
                                            <span className="pack-price-text">Suivi avec un expert à votre écoute</span>
                                        </li>
                                    </ul>
                                </div>

                                {/* Carte Premium */}
                                <div className="price-card fade-in">
                                    <div className="browser-dots">
                                        <div className="browser-dot dot-red"></div>
                                        <div className="browser-dot dot-yellow"></div>
                                        <div className="browser-dot dot-green"></div>
                                    </div>
                                    <h3 className="price-card-title">Premium</h3>
                                    <div className="price-value">399€</div>
                                    <ul className="pack-price-list">
                                        <li className="pack-price-item">
                                            <span className="pack-price-icon"><CheckedBox /></span>
                                            <span className="pack-price-text">Conception logo</span>
                                        </li>
                                        <li className="pack-price-item">
                                            <span className="pack-price-icon"><CheckedBox /></span>
                                            <span className="pack-price-text">Charte graphique</span>
                                        </li>
                                        <li className="pack-price-item">
                                            <span className="pack-price-icon"><CheckedBox /></span>
                                            <span className="pack-price-text">4 templates pour vos réseaux sociaux</span>
                                        </li>
                                        <li className="pack-price-item">
                                            <span className="pack-price-icon"><CheckedBox /></span>
                                            <span className="pack-price-text">Création d'une page Google Business et accompagnement à la gestion de la page</span>
                                        </li>
                                        <li className="pack-price-item">
                                            <span className="pack-price-icon"><CheckedBox /></span>
                                            <span className="pack-price-text">Mise à disposition d'un calendrier réseaux sociaux</span>
                                        </li>
                                        <li className="pack-price-item">
                                            <span className="pack-price-icon"><WhiteBoxWithCross /></span>
                                            <span className="pack-price-text">Campagne de publicité sur Facebook et Instagram</span>
                                        </li>
                                        <li className="pack-price-item">
                                            <span className="pack-price-icon"><WhiteBoxWithCross /></span>
                                            <span className="pack-price-text">Suivi avec un expert à votre écoute</span>
                                        </li>
                                    </ul>
                                </div>

                                {/* Carte Gold */}
                                <div className="price-card fade-in">
                                    <div className="bestseller-badge">BEST-SELLER</div>
                                    <div className="browser-dots">
                                        <div className="browser-dot dot-red"></div>
                                        <div className="browser-dot dot-yellow"></div>
                                        <div className="browser-dot dot-green"></div>
                                    </div>
                                    <h3 className="price-card-title">Gold</h3>
                                    <div className="price-value">699€</div>
                                    <ul className="pack-price-list">
                                        <li className="pack-price-item">
                                            <span className="pack-price-icon"><CheckedBox /></span>
                                            <span className="pack-price-text">Conception logo</span>
                                        </li>
                                        <li className="pack-price-item">
                                            <span className="pack-price-icon"><CheckedBox /></span>
                                            <span className="pack-price-text">Charte graphique</span>
                                        </li>
                                        <li className="pack-price-item">
                                            <span className="pack-price-icon"><CheckedBox /></span>
                                            <span className="pack-price-text">4 templates pour vos réseaux sociaux</span>
                                        </li>
                                        <li className="pack-price-item">
                                            <span className="pack-price-icon"><CheckedBox /></span>
                                            <span className="pack-price-text">Création d'une page Google Business et accompagnement à la gestion de la page</span>
                                        </li>
                                        <li className="pack-price-item">
                                            <span className="pack-price-icon"><CheckedBox /></span>
                                            <span className="pack-price-text">Mise à disposition d'un calendrier réseaux sociaux</span>
                                        </li>
                                        <li className="pack-price-item">
                                            <span className="pack-price-icon"><CheckedBox /></span>
                                            <span className="pack-price-text">Campagne de publicité sur Facebook et Instagram</span>
                                        </li>
                                        <li className="pack-price-item">
                                            <span className="pack-price-icon"><CheckedBox /></span>
                                            <span className="pack-price-text">Suivi avec un expert à votre écoute</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div className="boost-button-container fade-in">
                    <a 
                        href="https://calendly.com/contact-planibeauty/inscription-site-planibeauty" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="boost-button"
                    >
                        JE BOOSTE MON BUSINESS
                    </a>
                </div>
                <div className="pack-info-sections">
                    <section className="pack-info-section section-1">
                        <div className="vertical-line line-left"></div>
                        <div className="vertical-line line-right"></div>
                        <div className="pack-info-image-container scale-in">
                            <img src={imageOne} alt="Communication business" className="pack-info-image" />
                            <span className="corner-star star-top-left">✦</span>
                        </div>
                        <div className="pack-info-content slide-in-right">
                            <div className="title-background"></div>
                            <h2 className="pack-info-title">La communication clé en main pour faire briller votre business.</h2>
                            <p className="pack-info-text">
                                Dans le secteur de la beauté, une image professionnelle et une communication efficace sont essentielles pour attirer et fidéliser les clients.
                            </p>
                            <p className="pack-info-text">
                                PlaniBeauty vous offre des packs de communication complets pour vous aider à vous démarquer et propulser votre business.
                            </p>
                        </div>
                    </section>

                    <section className="pack-info-section section-2">
                        <div className="horizontal-line"></div>
                        <div className="vertical-line line-left"></div>
                        <div className="vertical-line line-middle"></div>
                        <div className="vertical-line line-right"></div>
                        <div className="pack-info-image-container scale-in">
                            <img src={imageTwo} alt="Communication professionnelle" className="pack-info-image" />
                            <span className="corner-star star-top-right">✦</span>
                        </div>
                        <div className="pack-info-content slide-in-left">
                            <div className="title-background"></div>
                            <h2 className="pack-info-title">Pourquoi investir dans une communication professionnelle ?</h2>
                            <p className="pack-info-text">
                                Chez PlaniBeauty, nous mettons à votre disposition une gamme de services adaptés à vos besoins, pour construire une communication impactante et professionnelle.
                            </p>
                            <p className="pack-info-text">
                                Selon le pack choisi, bénéficiez de la création d'un logo unique et parfaitement aligné à votre identité, d'une charte graphique, de templates pour vos réseaux sociaux, d'une campagne publicitaire sur Facebook et Instagram, de la création et l'optimisation de votre page Google Business avec accompagnement, ainsi que d'un suivi personnalisé avec un expert en communication, toujours à votre écoute pour vous guider.
                            </p>
                            <p className="pack-info-text">
                                Nos packs communication offrent des options adaptées pour débuter ou renforcer votre image selon vos priorités et votre budget.
                            </p>
                        </div>
                    </section>

                    <section className="pack-info-section section-3">
                        <div className="horizontal-line"></div>
                        <div className="vertical-line line-left"></div>
                        <div className="vertical-line line-right"></div>
                        <div className="pack-info-image-container scale-in">
                            <img src={imageThree} alt="Bonne communication" className="pack-info-image" />
                            <span className="corner-star star-bottom-right">✦</span>
                        </div>
                        <div className="pack-info-content slide-in-right">
                            <div className="title-background"></div>
                            <h2 className="pack-info-title">Tout commence par une bonne communication.</h2>
                            <p className="pack-info-text">
                                L'image de votre entreprise reflète votre professionnalisme. Avec PlaniBeauty, vous avez un pack clé en main pour gérer efficacement votre communication et vous concentrer sur votre savoir-faire.
                            </p>
                            <p className="pack-info-text">
                                Faites briller votre business dès maintenant !
                            </p>
                        </div>
                    </section>
                </div>
                <div className="boost-button-container fade-in">
                    <a 
                        href="https://calendly.com/contact-planibeauty/inscription-site-planibeauty" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="boost-button"
                    >
                        JE BOOSTE MON BUSINESS
                    </a>
                </div>
            </div>
        </div>
    );
}

export default PacksCommunication;