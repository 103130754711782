import React, { useState, createContext } from 'react'

const initialValue = {
    isLoginModalOpen: false,
    isSignupModalOpen: false,
    setIsSignupModalOpen: null,
    setIsLoginModalOpen: null
}

export const HeaderContext = createContext(initialValue)

export const HeaderContextProvider = ({ children }) => {
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)
    const [isSignupModalOpen, setIsSignupModalOpen] = useState(false)

    const handleLoginClick = (e) => {
        e.preventDefault()
        setIsSignupModalOpen(false)
        setIsLoginModalOpen(true)
        //setIsNavCollapsed(true)
    }

    const handleSignupClick = (e) => {
        e.preventDefault()
        setIsLoginModalOpen(false)
        setIsSignupModalOpen(true)
        //setIsNavCollapsed(true)
    }

    return (
        <HeaderContext.Provider value={{
            isLoginModalOpen,
            isSignupModalOpen,
            setIsLoginModalOpen,
            setIsSignupModalOpen
        }}>
            {children}
        </HeaderContext.Provider>
    )
}