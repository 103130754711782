// src/contexts/MessageGalleryContext.js
import React, { createContext, useState, useContext } from 'react';
import ImageGallery from '../components/messaging/ImageGallery';

const MessageGalleryContext = createContext();

export const useMessageGallery = () => {
  const context = useContext(MessageGalleryContext);
  if (!context) {
    throw new Error('useMessageGallery must be used within a MessageGalleryProvider');
  }
  return context;
};

export const MessageGalleryProvider = ({ children }) => {
  const [galleryOpen, setGalleryOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [messageImages, setMessageImages] = useState([]);

  const openGallery = (images, index) => {
    setMessageImages(images);
    setSelectedImageIndex(index);
    setGalleryOpen(true);
  };

  return (
    <MessageGalleryContext.Provider value={{ openGallery, setGalleryOpen }}>
      {children}
      {galleryOpen && (
        <ImageGallery
          images={messageImages}
          initialIndex={selectedImageIndex}
          onClose={() => setGalleryOpen(false)}
        />
      )}
    </MessageGalleryContext.Provider>
  );
};