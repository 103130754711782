import React from 'react';
import './PlanibeautyPro.css';
import { useNavigate } from 'react-router-dom';

const PlanibeautyPro = () => {

    const navigate = useNavigate();

    return (
        <div className="plani-beauty-pro-container">
            <h1>Découvrez</h1>
            <h2>PlaniBeauty Pro</h2>
            <div className="button-group">
                <button className="button" onClick={() => navigate('/fonctionnalities')}>Nos fonctionnalités</button>
                <button className="button active" onClick={() => navigate('/abonnements')}>Nos tarifs</button>
                <button className="button" onClick={() => navigate('/packs-communication')}>Packs communication</button>
            </div>
        </div>
    );
};

export default PlanibeautyPro;
