import React, { useEffect, useContext, useState, useRef } from 'react'
import { Modal, Button } from "react-bootstrap";
import { FaPlus } from 'react-icons/fa';
import Swal from 'sweetalert2'
import serviceCategoriesApi from '../../../../api/service-categories.api';
import serviceSubcategoriesApi from '../../../../api/service-subcategories.api';
import { beauticiansSubCategoriesApi } from '../../../../api/beauticians_subcategories.api';
import { showErrorMessage, showSuccessMessage, showWarningMessage } from '../../../../utils/messageHelper';
import { BeauticianPortalContext } from '../../../../context/BeauticianPortalContext';
import AddSubCategoryModal from '../../../common/AddSubcategory/AddSubcategoryModal';

const CategorySet = ({ categoryId, subCategory, updateSubCategory }) => {
    const [localSubCategory, setLocalSubCategory] = useState(subCategory);

    const handleFieldChange = (field, value) => {
        setLocalSubCategory((prev) => {
            const updatedSubCategory = {
                ...prev,
                [field]: value,
            };
            return updatedSubCategory; // Retourner l'état mis à jour sans appeler `handleUpdateLocale` ici
        });
    };

    const handleImageUpload = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            const fileArray = Array.from(files);
            const imagePreviews = fileArray.map((file) => {
                const reader = new FileReader();
                return new Promise((resolve) => {
                    reader.onload = (e) => {
                        resolve({ id: Date.now() + Math.random(), src: e.target.result, file });
                    };
                    reader.readAsDataURL(file);
                });
            });

            Promise.all(imagePreviews).then((images) => {
                setLocalSubCategory((prev) => {
                    const updatedSubCategory = {
                        ...prev,
                        image_list: [...prev.image_list, ...images],
                    };
                    return updatedSubCategory; // Retourner l'état mis à jour
                });
            });
        }
    };

    const handleImageDelete = (imageId) => {
        setLocalSubCategory((prev) => {
            const updatedSubCategory = {
                ...prev,
                image_list: prev.image_list.filter((image) => image.id !== imageId),
            };
            return updatedSubCategory; // Retourner l'état mis à jour
        });
    };

    // Utilisez useEffect pour mettre à jour le parent après chaque changement d'état local
    useEffect(() => {
        if (localSubCategory !== subCategory) {
            updateSubCategory(categoryId, localSubCategory); // Appel à la mise à jour du parent après un changement
        }
    }, [localSubCategory]); // Dépendances à vérifier

    // Référence pour déclencher l'input file
    const fileInputRef = useRef();

    const triggerFileInput = () => {
        if (localSubCategory.checked) {
            fileInputRef.current.click();
        } else {
            showWarningMessage("Vous devez cocher sur le type de préstation pour ajouter des images!");
        }
    };

    return (
        <li className="flex flex-col gap-3">
            <div>
                <div className="flex items-center gap-2">
                    <input
                        type="checkbox"
                        id={`check${localSubCategory.id}`}
                        checked={localSubCategory.checked}
                        onChange={(e) => handleFieldChange("checked", e.target.checked)}
                    />
                    <label htmlFor={`check${localSubCategory.id}`} className="font-semibold">{localSubCategory.name || "Nom non défini"}</label>
                </div>
            </div>
            {localSubCategory.checked && (
                <>
                    <div className="ms-auto flex gap-2 items-center">
                        <span>Prix en €: </span>
                        <input
                            type="number"
                            value={localSubCategory.price}
                            min="1"
                            onChange={(e) => handleFieldChange("price", parseFloat(e.target.value) || '')}
                            className="w-24 px-2 py-1 border rounded"
                        />
                        <span>Durée en minutes: </span>
                        <input
                            type="number"
                            value={localSubCategory.duration}
                            min="1"
                            onChange={(e) => handleFieldChange("duration", parseInt(e.target.value, 10) || '')}
                            className="w-24 px-2 py-1 border rounded"
                        />
                    </div>
                    <div>
                        <textarea
                            placeholder="Ajouter une description"
                            className="w-full p-3 outline-[#bd193b] rounded border shadow resize-none"
                            rows={3}
                            value={localSubCategory.description}
                            onChange={(e) => handleFieldChange("description", e.target.value)}
                        />
                    </div>
                    <div>
                        <h3 className="font-semibold text-xl mb-3">Liste des images</h3>
                        <div>
                            {localSubCategory.image_list.length > 0 && <p>Nouvelles images</p>}
                            <div className="flex gap-2 flex-wrap mt-2">
                                {localSubCategory.image_list.map((image) => (
                                    <div
                                        key={image.id}
                                        className="relative w-24 h-24 border rounded overflow-hidden"
                                    >
                                        <img
                                            src={image.src}
                                            alt="preview"
                                            className="w-full h-full object-cover"
                                        />
                                        <button
                                            onClick={() => handleImageDelete(image.id)}
                                            className="absolute top-1 right-1 bg-red-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center"
                                        >
                                            &times;
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <button
                            onClick={triggerFileInput}
                            className="flex mt-3 items-center gap-2 px-3 py-2 bg-blue-600 text-white rounded shadow hover:bg-blue-500"
                        >
                            <FaPlus /> Ajouter des images
                        </button>
                        <input
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={handleImageUpload}
                            ref={fileInputRef}
                            style={{ display: "none" }} // Cache l'input file
                        />
                    </div>
                    <div className='mt-3'>
                        <div className='flex gap-2 items-center'>
                            <input
                                type='checkbox'
                                id={`check-multiple-appointment${localSubCategory.id}`}
                                checked={localSubCategory.is_multiple_appointment}
                                onChange={(e) => handleFieldChange("is_multiple_appointment", e.target.checked)}
                            />
                            <label htmlFor={`check-multiple-appointment${localSubCategory.id}`}>Préstations avec plusieurs rendez-vous?</label>
                        </div>
                        {localSubCategory.is_multiple_appointment &&
                            <div className='flex flex-col gap-2 mt-2'>
                                <label>Nombre de rendez-vous du préstations :</label>
                                <input
                                    type='number'
                                    value={localSubCategory.number_of_appointment}
                                    onChange={(e) => handleFieldChange("number_of_appointment", parseInt(e.target.value, 10) || 2)}
                                    className='px-3 py-2 bg-gray-50 border border-gray-400 rounded'
                                    min={2}
                                    placeholder='Nombre de rendez-vous'
                                />
                            </div>
                        }
                    </div>
                </>
            )}
        </li>
    );
};



const AddPrestationModal = ({ show, handleClose, selectedCategory, setReloadTrigger }) => {
    //on definit l'active Id (id du beautician connecté)
    const { profileData } = useContext(BeauticianPortalContext);
    const activeID = profileData.beautician_id;

    const [informationsACompleter, setInformationsACompleter] = useState([]);


    async function fetchCats() {
        try {
            const [categories, prestations] = await Promise.all([
                serviceCategoriesApi.getAllCategories(),
                beauticiansSubCategoriesApi.getByBauticianId(activeID, localStorage.getItem("token")),
            ]);

            // Vérification des données
            const safeCategories = Array.isArray(categories) ? categories : [];
            const safePrestations = Array.isArray(prestations) ? prestations : [];

            const categoriesInformations = safeCategories.map((category) => {
                // Filtrer pour la catégorie sélectionnée
                if (category.category_id !== selectedCategory.category_id) {
                    return null;
                }

                const subcatInfos = [];

                (Array.isArray(category.sub_categories) ? category.sub_categories : []).forEach((sub) => {
                    const prestation = safePrestations.find((obj) => obj.service_subcategory_id === sub.id);

                    // Ajouter uniquement les sous-catégories qui ne sont PAS encore des prestations
                    if (!prestation) {
                        subcatInfos.push({
                            checked: false,
                            id: sub.id,
                            name: sub.name,
                            price: 1,
                            duration: 1,
                            description: "",
                            image_list: [],
                            is_multiple_appointment: false,
                            number_of_appointment: 2,
                        });
                    }
                });

                return {
                    categorie_id: category.category_id,
                    categorie_name: category.category_name,
                    sub_categories: subcatInfos,
                };
            }).filter(Boolean); // Enlever les éléments null

            setInformationsACompleter(categoriesInformations);
        } catch (error) {
            showErrorMessage("Erreur lors du chargement des catégories");
        }
    }

    useEffect(() => {
        if (show) {
            fetchCats();
        }
    }, [show]);

    const updateSubCategory = (categoryId, updatedSubCategory) => {
        setInformationsACompleter((prevInfos) =>
            prevInfos.map((category) =>
                category.categorie_id === categoryId
                    ? {
                        ...category,
                        sub_categories: category.sub_categories.map((subCategory) =>
                            subCategory.id === updatedSubCategory.id
                                ? { ...updatedSubCategory }
                                : subCategory
                        ),
                    }
                    : category
            )
        );
    };

    const [modalAddSubcategoryVisible, setModalAddSubcategoryVisible] = useState(false);

    const handleOpenModal = () => {
        setModalAddSubcategoryVisible(true);
    };

    const handleAddSubCategory = async (categoryId, subCategoryName) => {
        const data = { category_id: categoryId, name: subCategoryName };
        const response = await serviceSubcategoriesApi.createServiceSubcategories(
            localStorage.getItem("token"),
            data
        );

        if (response && response.id) {
            setInformationsACompleter((prevInfos) =>
                prevInfos.map((category) =>
                    category.categorie_id === categoryId
                        ? {
                            ...category,
                            sub_categories: [
                                ...category.sub_categories,
                                {
                                    id: response.id,
                                    name: subCategoryName,
                                    checked: false,
                                    price: 1,
                                    duration: 1,
                                    description: "",
                                    image_list: [],
                                    is_multiple_appointment: false,
                                    number_of_appointment: 2,
                                },
                            ],
                        }
                        : category
                )
            );
            showSuccessMessage("Sous-catégorie enregistrée avec succès!");
        } else {
            throw new Error("Échec de la création de la sous-catégorie.");
        }
    };


    const saveOnePrestation = async (prestation) => {
        try {
            // Affiche une alerte de chargement
            Swal.fire({
                title: 'Enregistrement en cours...',
                text: 'Veuillez patienter',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading(); // Montre le spinner
                },
            });

            const token = localStorage.getItem("token");

            if (!token) {
                throw new Error("Token non trouvé, veuillez vous reconnecter.");
            }

            // Validation des données
            if (!prestation.id || !prestation.price || !prestation.duration) {
                throw new Error("Données invalides. Vérifiez les informations de la prestation.");
            }

            const data = {
                beautician_id: activeID,
                subcategory_id: prestation.id,
                price: Number(prestation.price),
                duration: Number(prestation.duration),
                description: prestation.description,
                is_multiple_appointment: prestation.is_multiple_appointment,
                number_of_appointment: prestation.is_multiple_appointment ? prestation.number_of_appointment : null,
            };

            const response = await beauticiansSubCategoriesApi.saveBeauticiansSubCategories(data, token);

            if (response.success) {
                if (response.data && response.data.subcategory_id) {
                    const newId = response.data.subcategory_id;

                    // Enregistrement des images
                    if (prestation.image_list.length > 0) {
                        const save_image = await beauticiansSubCategoriesApi.saveImageBeauticianSubcategory(
                            response.data.id,
                            token,
                            prestation.image_list
                        );

                        if (!save_image.success) {
                            throw new Error("Échec lors de l'enregistrement des images.");
                        }
                    }

                    // Alerte de succès
                    Swal.fire({
                        icon: 'success',
                        title: 'Succès',
                        text: 'Prestation enregistrée avec succès !',
                    });

                    return { success: true, message: "Prestation enregistrée avec succès." };
                }
            }

            throw new Error("Échec de la sauvegarde de la prestation.");
        } catch (error) {
            throw new Error(error);
        }
    };

    const handleSavePrestation = async () => {
        try {
            const results = await Promise.all(
                informationsACompleter.map((category) =>
                    Promise.all(
                        category.sub_categories.map(async (subcategory) => {
                            if (subcategory.checked) {
                                return await saveOnePrestation(subcategory);
                            }
                            return null;
                        })
                    )
                )
            );

            // Vérifiez si des erreurs se sont produites
            const errors = results.flat().filter((result) => result && !result.success);

            if (errors.length > 0) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Certaines prestations n\'ont pas été enregistrées',
                    text: `Erreurs détectées pour ${errors.length} prestations.`,
                });
            } else {
                Swal.fire({
                    icon: 'success',
                    title: 'Succès',
                    text: 'Toutes les prestations ont été enregistrées avec succès.',
                }).then(() => {
                    handleClose();
                    setReloadTrigger(prev => !prev);
                });
            }
        } catch (error) {
            console.error("Erreur inattendue dans handleSavePrestation:", error);

            Swal.fire({
                icon: 'error',
                title: 'Erreur',
                text: "Une erreur inattendue s'est produite.",
            });
        }
    };


    return (
        <Modal show={show} onHide={handleClose} size='lg'>
            <Modal.Body className='px-0 py-2 overflow-y-auto'>
                <h1 className='text-xl text-gray-800'>Ajouter Présations à la catégorie {selectedCategory.category_name}</h1>
                <p className='text-sm text-gray-800'>Pour ajouter les préstations, cocher le sous-categorie que vous voulez ajouter.</p>
                <div className="h-full overflow-y-auto">
                    {informationsACompleter.length > 0 &&
                        informationsACompleter.map((category) => (
                            <div key={category.categorie_id}>
                                <div className='ms-0 pe-3'>
                                    <div>
                                        <button
                                            onClick={() => setModalAddSubcategoryVisible(true)}
                                            className='ms-auto px-3 py-2 flex gap-2 items-center text-white bg-gray-600 rounded'
                                        >
                                            <FaPlus /> Créer une nouvelle sous-catégorie
                                        </button>
                                        <AddSubCategoryModal
                                            show={modalAddSubcategoryVisible}
                                            handleClose={() => setModalAddSubcategoryVisible(false)}
                                            handleSubmit={handleAddSubCategory}
                                            categoryName={selectedCategory.category_name}
                                            categoryId={selectedCategory.category_id}
                                        />
                                    </div>
                                    <ul className="ms-0 flex flex-col gap-4">
                                        <hr />
                                        {category.sub_categories.length > 0 ? (
                                            category.sub_categories.map((subcat) => (
                                                <React.Fragment key={subcat.id}>
                                                    <CategorySet
                                                        categoryId={category.categorie_id}
                                                        subCategory={subcat}
                                                        updateSubCategory={updateSubCategory}
                                                    />
                                                    <hr />
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <p>Pas de sous-catégories à ajouter.</p>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        ))}
                </div>
            </Modal.Body>
            <Modal.Footer className='p-0 pt-2'>
                <Button variant="secondary" onClick={handleClose}>
                    Fermer
                </Button>
                <Button variant="primary" className='flex gap-2 items-center' onClick={() => handleSavePrestation()}>
                    Sauvegarder
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddPrestationModal;