import React, { useEffect, useState, useContext } from 'react';  // Un seul import React avec tous les hooks
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet';
import './Fonctionnalités.css';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';  // Import du contexte
import FeaturesSlider from '../../components/featuresSlider/FeaturesSlider';


const Fonctionnalités = () => {
  const navigate = useNavigate();
  const [isMounted, setIsMounted] = useState(false);
  const { setActivePageForHeader } = useContext(AppContext);

  useEffect(() => {
    setIsMounted(true);
    window.scrollTo(0, 0);
    
    // Définir l'état du header
    document.body.setAttribute('data-page', 'search');
    setActivePageForHeader("search");

    return () => {
      setIsMounted(false);
      document.body.removeAttribute('data-page');
      setActivePageForHeader("");
    }
  }, [setActivePageForHeader]);
 
  return (
    <>
      <Helmet>
      <title>Nos fonctionnalités | PlaniBeauty</title>
      <meta name="description" content="Découvrez comment PlaniBeauty révolutionne la réservation de prestations beauté. Une plateforme simple et intuitive pour connecter clients et professionnels." />
      <link rel="canonical" href="https://www.planibeauty.fr/concept" />
      <meta property="og:title" content="Les fonctionnalités PlaniBeauty | Réservation beauté" />
      <meta property="og:description" content="PlaniBeauty connecte clients et professionnels de la beauté. Découvrez notre concept unique." />
      </Helmet>
      <div className={`Fonctionnalités ${isMounted ? "mounted" : ""}`}>
        <div className={`Fonctionnalités ${isMounted ? "mounted" : ""}`}>
          <FeaturesSlider />
        </div>

      </div>
    </>
  );
};

export default Fonctionnalités;