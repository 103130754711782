import { API_URL } from "../utils/constants";

const offreApi = {

    getAllOffer: async () => {
        const response = await fetch(`${API_URL}/offre/all`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        });
        return response.json();
    },

    getOfferById: async (id) => {
        const response = await fetch(`${API_URL}/offre/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        });
        return response.json();
    },

    createOffer: async (token, offerData) => {
        try {
            const response = await fetch(`${API_URL}/offre/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(offerData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de la création de l\'offre');
            }

            return await response.json();
        } catch (error) {
            console.error('Erreur dans create offer:', error);
            throw error;
        }
    },

    updateOffer: async (token, id, offerData) => {
        try {
            const response = await fetch(`${API_URL}/offre/${id}/update`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(offerData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de la mise à jour de l\'offre');
            }

            return await response.json();
        } catch (error) {
            console.error('Erreur dans update offer:', error);
            throw error;
        }
    },

    deleteOffer: async (token, id) => {
        try {
            const response = await fetch(`${API_URL}/offre/${id}/delete`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de la suppression de l\'offre');
            }

            return await response.json();
        } catch (error) {
            console.error('Erreur dans delete offer :', error);
            throw error;
        }
    }
}

export default offreApi;