import React, { useState, useRef, useContext, useEffect } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import { Modal, Table, Button } from "react-bootstrap";
import { FaEdit } from 'react-icons/fa';
import { FaPlus, FaTrash } from 'react-icons/fa6';
import Swal from 'sweetalert2';
import { beauticiansSubCategoriesApi } from '../../api/beauticians_subcategories.api';
import { showErrorMessage, showSuccessMessage } from '../../utils/messageHelper';
import { dashboardContext } from '../../pages/dashboard/Dashboard';
import { BASE_URL } from '../../utils/constants';
import { formatMoneyNumber } from '../../utils/functions';


const EditPrestation = ({ show, handleClose, prestation, setChangeTrigger }) => {

    const { activeID } = useContext(dashboardContext);

    const [localSubCategory, setLocalSubCategory] = useState({
        id: prestation.service_subcategory_id,
        name: prestation.service_subcategory_name,
        price: Number(prestation.beautician_subcategory_price),
        duration: Number(prestation.beautician_subcategory_duration),
        description: prestation.beautician_subcategory_description || "",
        image_list: [],
        image_list_saved: prestation.images,
        is_multiple_appointment: prestation.is_multiple_appointment || false,
        number_of_appointment: prestation.number_of_appointment || 2,
    });

    useEffect(() => {
        setLocalSubCategory({
            id: prestation.service_subcategory_id,
            name: prestation.service_subcategory_name,
            price: Number(prestation.beautician_subcategory_price),
            duration: Number(prestation.beautician_subcategory_duration),
            description: prestation.beautician_subcategory_description || "",
            image_list: [],
            image_list_saved: prestation.images,
            is_multiple_appointment: prestation.is_multiple_appointment || false,
            number_of_appointment: prestation.number_of_appointment || 2,
        });
    }, [prestation]);

    const handleFieldChange = (field, value) => {
        setLocalSubCategory((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleImageUpload = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            const fileArray = Array.from(files);
            const imagePreviews = fileArray.map((file) => {
                const reader = new FileReader();
                return new Promise((resolve) => {
                    reader.onload = (e) => {
                        resolve({ id: Date.now() + Math.random(), src: e.target.result, file });
                    };
                    reader.readAsDataURL(file);
                });
            });

            Promise.all(imagePreviews).then((images) => {
                setLocalSubCategory((prev) => ({
                    ...prev,
                    image_list: [...prev.image_list, ...images],
                }));
            });
        }
    };

    const handleImageDelete = (imageId) => {
        setLocalSubCategory((prev) => ({
            ...prev,
            image_list: prev.image_list.filter((image) => image.id !== imageId),
        }));
    };

    const handleSavedImageDelete = async (imageId) => {
        Swal.fire({
            title: "Voullez vous vraiment supprimer cette image?",
            showCancelButton: true,
            confirmButtonText: "Oui",
            cancelButtonText: "Non"
        }).then((result) => {
            if (result.isConfirmed) {
                const delete_image = beauticiansSubCategoriesApi.deletePortfolioImageById(imageId, localStorage.getItem("token"));
                if (delete_image) {
                    setLocalSubCategory((prev) => ({
                        ...prev,
                        image_list_saved: prev.image_list_saved.filter((image) => image.id !== imageId),
                    }));
                } else {
                    showErrorMessage("Impossible de supprimer l'image!");
                }
            }
        });
    };

    const getImageUrl = (profilePhoto) => {
        return `${BASE_URL}/images/beauticians_subcategories/${encodeURIComponent(profilePhoto)}`;
    };

    // Référence pour déclencher l'input file
    const fileInputRef = useRef();

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    const handleEditSubmit = async () => {

        try {
            Swal.fire({
                title: 'Enregistrement en cours...',
                text: 'Veuillez patienter',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading(); // Montre le spinner
                },
            });

            const token = localStorage.getItem("token");

            const data = {
                beautician_id: activeID,
                subcategory_id: localSubCategory.id,
                price: Number(localSubCategory.price),
                duration: Number(localSubCategory.duration),
                description: localSubCategory.description,
                is_multiple_appointment: localSubCategory.is_multiple_appointment,
                number_of_appointment: localSubCategory.is_multiple_appointment ? localSubCategory.number_of_appointment : null,
            };

            const response = await beauticiansSubCategoriesApi.saveBeauticiansSubCategories(data, token);

            if (response.success) {
                if (response.data && response.data.subcategory_id) {
                    // Mise à jour avec l'ID réel retourné
                    const newId = response.data.subcategory_id;

                    //on enregistre l'image
                    let new_image = null;
                    if (localSubCategory.image_list.length > 0) {
                        const save_image = await beauticiansSubCategoriesApi.saveImageBeauticianSubcategory(response.data.id, token, localSubCategory.image_list);
                        new_image = save_image.data;
                        if (!save_image) {
                            setTimeout(() => {
                                showErrorMessage("Échec lors de l'enregistrement des images!");
                            }, 200);
                        }
                    }

                    setLocalSubCategory((prev) => ({
                        ...prev,
                        id: newId,
                        image_list: [],
                        image_list_saved: new_image ? [...prev.image_list_saved, new_image] : [...prev.image_list_saved]
                    }));

                }
                setChangeTrigger(prev => !prev);
                setTimeout(() => {
                    showSuccessMessage("Prestation enregistrée avec succès!");
                    handleClose();
                }, 200);
            } else {
                setTimeout(() => {
                    showErrorMessage("Échec de la sauvegarde du prestation.");
                }, 200);
            }
        } catch (error) {
            setTimeout(() => {
                showErrorMessage(error || "Une erreur s'est produite lors de l'enregistrement.");
            }, 200);
        }
    };

    return <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className='p-0'>
            <Modal.Title>{prestation.service_subcategory_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-0 py-2 overflow-y-auto'>
            <div className="flex flex-col gap-3">
                <div className='flex gap-2 flex-wrap justify-between'>
                    <div className="flex flex-col gap-2">
                        <label htmlFor='prix'>Prix en €: </label>
                        <input
                            type="number"
                            id="prix"
                            value={localSubCategory.price}
                            min="1"
                            onChange={(e) => handleFieldChange("price", parseFloat(e.target.value) || '')}
                            className="w-24 px-2 py-1 border rounded"
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <label htmlFor='duration'>Durée en minutes: </label>
                        <input
                            type="number"
                            id="duration"
                            value={localSubCategory.duration}
                            min="1"
                            onChange={(e) => handleFieldChange("duration", parseInt(e.target.value, 10) || '')}
                            className="w-24 px-2 py-1 border rounded"
                        />
                    </div>
                </div>
                <div>
                    <textarea
                        placeholder="Ajouter une description"
                        className="w-full p-3 outline-[#bd193b] rounded border shadow resize-none"
                        rows={3}
                        value={localSubCategory.description}
                        onChange={(e) => handleFieldChange("description", e.target.value)}
                    />
                </div>
                <div>
                    <h3 className="font-semibold text-xl mb-3">Liste des images</h3>
                    <div className='mb-3'>
                        {localSubCategory.image_list_saved.length > 0 && <p>Images enregistrées</p>}
                        {localSubCategory.image_list_saved.length > 0 ?
                            <div className="flex gap-2 flex-wrap mt-2">
                                {localSubCategory.image_list_saved.map((image) => (
                                    <div
                                        key={image.id}
                                        className="relative w-24 h-24 border rounded overflow-hidden"
                                    >
                                        <img
                                            src={getImageUrl(image.photo_url)}
                                            alt="preview"
                                            className="w-full h-full object-cover"
                                        />
                                        <button
                                            onClick={() => handleSavedImageDelete(image.id)}
                                            className="absolute top-1 right-1 bg-red-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center"
                                        >
                                            &times;
                                        </button>
                                    </div>
                                ))}
                            </div>
                            :
                            <p>Il n'y a pas d'images enregistrées</p>}
                    </div>
                    <div>
                        {localSubCategory.image_list.length > 0 && <p>Nouvelles images</p>}
                        <div className="flex gap-2 flex-wrap mt-2">
                            {localSubCategory.image_list.map((image) => (
                                <div
                                    key={image.id}
                                    className="relative w-24 h-24 border rounded overflow-hidden"
                                >
                                    <img
                                        src={image.src}
                                        alt="preview"
                                        className="w-full h-full object-cover"
                                    />
                                    <button
                                        onClick={() => handleImageDelete(image.id)}
                                        className="absolute top-1 right-1 bg-red-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center"
                                    >
                                        &times;
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                    <button
                        onClick={triggerFileInput}
                        className="flex mt-3 items-center gap-2 px-3 py-2 bg-blue-600 text-white rounded shadow hover:bg-blue-500"
                    >
                        <FaPlus /> Ajouter des images
                    </button>
                    <input
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={handleImageUpload}
                        ref={fileInputRef}
                        style={{ display: "none" }} // Cache l'input file
                    />
                </div>
                <div className='mt-3 mb-3'>
                    <div className='flex gap-2 items-center'>
                        <input
                            type='checkbox'
                            id={`check-multiple-appointment${localSubCategory.id}`}
                            checked={localSubCategory.is_multiple_appointment}
                            onChange={(e) => handleFieldChange("is_multiple_appointment", e.target.checked)}
                        />
                        <label htmlFor={`check-multiple-appointment${localSubCategory.id}`}>Préstations avec plusieurs rendez-vous?</label>
                    </div>
                    {localSubCategory.is_multiple_appointment &&
                        <div className='flex flex-col gap-2 mt-2'>
                            <label>Nombre de rendez-vous du préstations :</label>
                            <input
                                type='number'
                                value={localSubCategory.number_of_appointment}
                                onChange={(e) => handleFieldChange("number_of_appointment", parseInt(e.target.value, 10) || 2)}
                                className='px-3 py-2 bg-gray-50 border border-gray-400 rounded'
                                min={2}
                                placeholder='Nombre de rendez-vous'
                            />
                        </div>
                    }
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer className='p-0'>
            <Button variant="secondary" onClick={handleClose}>
                Fermer
            </Button>
            <Button variant="primary" onClick={() => handleEditSubmit()}>
                Sauvegarder
            </Button>
        </Modal.Footer>
    </Modal>;
}


const SiteInfoPrestation = ({ setShowPrestations, prestations, setChangeTrigger }) => {

    const [editingPrestation, setEditingPrestation] = useState(null);

    const handleClose = () => setEditingPrestation(null);
    const handleShow = (prestation) => setEditingPrestation(prestation);

    const handleDeletePrestation = (id) => {
        Swal.fire({
            title: "Voulez vous vraiment supprimer cette préstation?",
            showCancelButton: true,
            confirmButtonText: "Oui, supprimer",
            confirmButtonColor: "#dc2626",
            cancelButtonText: `Non`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const delete_req = await beauticiansSubCategoriesApi.deleteBeauticianCategoryById(id, localStorage.getItem('token'));
                    if (delete_req.success) {
                        setChangeTrigger(prev => !prev);
                        showSuccessMessage('Préstation supprimée avec succès!');
                    } else {
                        showErrorMessage(delete_req.message);
                    }
                } catch (error) {
                    showErrorMessage(error);
                }
            }
        });
    }

    return <React.Fragment>
        <Accordion.Header>Les prestations</Accordion.Header>
        <Accordion.Body className="flex flex-col gap-3">
            {prestations.length > 0 ?
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Catégorie</th>
                            <th>Sous-catégorie</th>
                            <th>Prix</th>
                            <th>Duration</th>
                            <th>Rendez-vous multiple</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {prestations.map((item, index) => (
                            <tr key={index}>
                                <td>{item.category_name}</td>
                                <td>{item.service_subcategory_name}</td>
                                <td>{formatMoneyNumber(item.beautician_subcategory_price)}€</td>
                                <td>{item.beautician_subcategory_duration} min</td>
                                <td className='text-center'>{item.is_multiple_appointment ? 'Oui' : 'Non'}</td>
                                <td>
                                    <div className='flex gap-x-2'>
                                        <button
                                            className="flex items-center justify-center p-2 bg-transparent text-yellow-500 hover:text-yellow-600 focus:outline-none"
                                            aria-label="Edit"
                                            onClick={() => handleShow(item)}
                                        >
                                            <FaEdit className="w-5 h-5" />
                                        </button>
                                        <button
                                            onClick={() => handleDeletePrestation(item.beautician_subcategory_id)}
                                            className="flex items-center justify-center p-2 bg-transparent text-red-500 hover:text-red-600 focus:outline-none"
                                            aria-label="Delete"
                                        >
                                            <FaTrash className="w-5 h-5" />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                : <h3 className="text-center text-gray-800 text-xl">Aucune prestation à afficher</h3>}
            <div className="flex items-center justify-center">
                <button
                    onClick={() => setShowPrestations(true)}
                    className="px-3 py-2 flex gap-2 items-center text-white bg-blue-600 rounded shadow"
                >
                    <FaPlus />Ajouter préstations
                </button>
            </div>

            {/* Render EditPrestation modal only when there's a prestation being edited */}
            {editingPrestation && (
                <EditPrestation
                    show={Boolean(editingPrestation)}
                    handleClose={handleClose}
                    prestation={editingPrestation}
                    setChangeTrigger={setChangeTrigger}
                />
            )}
        </Accordion.Body>
    </React.Fragment>
}

export default SiteInfoPrestation;