import React, { useState } from 'react';
import { Search, X, Plus } from 'lucide-react';
import { useMessage } from '../../contexts/MessageContext';
import { generateColorFromString } from '../../utils/colorUtils';



const ConversationList = ({ 
    conversations = [], 
    setConversations,
    activeConversation, 
    setActiveConversation,
    onSelectConversation,
    onNewConversation
  }) => {
    const { unreadByConversation, markConversationAsRead, fetchUnreadCounts } = useMessage();
    const [searchTerm, setSearchTerm] = useState('');
    const [hoveredConversation, setHoveredConversation] = useState(null);
    const [isSearching, setIsSearching] = useState(false);

    const handleSearchFocus = () => {
        setIsSearching(true);
    };

    const handleSearchBlur = () => {
        if (!searchTerm) {
            setIsSearching(false);
        }
    };

    const safeConversations = Array.isArray(conversations) ? conversations : [];

    const filteredConversations = safeConversations.filter(conversation => 
        conversation && 
        conversation.name && 
        conversation.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleDeleteConversation = async (e, conversationId) => {
        e.stopPropagation();
        
        const isConfirmed = window.confirm('Voulez-vous vraiment supprimer cette conversation ?');
        if (!isConfirmed) return;

        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`/api/messages/conversations/${conversationId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la suppression');
            }

            setConversations(prevConversations => 
                prevConversations.filter(conv => conv.id !== conversationId)
            );

            if (activeConversation?.id === conversationId) {
                setActiveConversation(null);
            }
        } catch (error) {
            console.error('Erreur lors de la suppression :', error);
            alert('Erreur lors de la suppression de la conversation');
        }
    };


    // Fonction pour obtenir les initiales
    const getInitials = (name) => {
      if (!name) return '??';
      const words = name.split(' ');
      if (words.length >= 2) {
        return (words[0][0] + words[1][0]).toUpperCase();
      }
      return name.substring(0, 2).toUpperCase();
    };

    return (
        <div className="h-full flex flex-col">
            {/* Search Bar */}
            <div className="p-2.5 border-b border-gray-200">
                <div className="relative flex items-center">
                    <input
                        type="text"
                        placeholder={!isSearching ? "Rechercher une conversation..." : ""}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onFocus={handleSearchFocus}
                        onBlur={handleSearchBlur}
                        className="w-full pr-10 pl-4 py-2 bg-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    {(!isSearching || !searchTerm) && (
                        <Search 
                            className="absolute right-3 text-gray-400" 
                            size={20} 
                        />
                    )}
                </div>
            </div>

            {/* Conversations List */}
            <div className="flex-1 overflow-y-auto conversation-list custom-scrollbar">
            {filteredConversations.map((conversation) => (
                conversation && (
                    <div
                        key={conversation.id}
                        className={`conversation-item ${activeConversation?.id === conversation.id ? 'active' : ''}`}
                        onMouseEnter={() => setHoveredConversation(conversation.id)}
                        onMouseLeave={() => setHoveredConversation(null)}
                        onClick={() => {
                            // N'appeler onSelectConversation que si la conversation sélectionnée est différente
                            if (activeConversation?.id !== conversation.id) {
                                onSelectConversation(conversation);
                            }
                            if (unreadByConversation[conversation.id] > 0) {
                                markConversationAsRead(conversation.id);
                                fetchUnreadCounts();
                            }
                        }}
                    >
                        <div className="relative flex items-center gap-3 mt-4 mb-4">
                            <div 
                                className="min-w-[40px] w-10 h-10 rounded-full flex items-center justify-center text-white font-semibold text-base leading-none shrink-0 relative"
                                style={{ backgroundColor: generateColorFromString(`${localStorage.getItem('accountId')}-${conversation.id}`) }}
                            >
                                {getInitials(conversation.name)}
                                {unreadByConversation[conversation.id] > 0 && (
                                    <span className="unread-conversation-indicator" />
                                )}
                            </div>
                            
                            <div className="flex-1 min-w-0 mt-6">
                                <div className="flex justify-between items-start">
                                    <h3 className="font-medium text-sm truncate flex items-center gap-2">
                                        {conversation.online && (
                                            <div className="online-status" />
                                        )}
                                        {conversation.name}
                                    </h3>
                                    
                                    <div className="flex items-center gap-2">
                                        {/* Heure du dernier message */}
                                        <span className="text-xs text-gray-500 shrink-0">
                                            {conversation.last_message_time && 
                                                new Date(conversation.last_message_time).toLocaleTimeString([], {
                                                    hour: '2-digit', 
                                                    minute:'2-digit'
                                                })
                                            }
                                        </span>
                                        
                                        {/* Indicateur de messages non lus */}
                                        {unreadByConversation[conversation.id] > 0 && (
                                            <span className="shrink-0 bg-blue-500 text-white text-xs px-2 py-0.5 rounded-full font-medium">
                                                {unreadByConversation[conversation.id]}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <p className="text-sm text-gray-500 truncate">
                                    {conversation.last_message || 'Aucun message'}
                                </p>
                            </div>

                            {hoveredConversation === conversation.id && (
                                <button
                                    onClick={(e) => handleDeleteConversation(e, conversation.id)}
                                    className="delete-button"
                                    aria-label="Supprimer la conversation"
                                >
                                    <X size={16} className="text-gray-400" />
                                </button>
                            )}
                        </div>
                    </div>
                )
            ))}
            </div>
            {/* New Conversation Button - Fixed at bottom */}
            <div className="p-2.5 border-t border-gray-200 mt-auto">
                <button 
                    onClick={() => onNewConversation(searchTerm)}
                    className="w-full flex items-center justify-center gap-2 px-4 py-3 bg-gradient-to-r from-pink-400 to-rose-400 text-white rounded-full hover:from-pink-500 hover:to-rose-500 transition-all duration-300"
                >
                    <Plus size={20} />
                    Nouvelle conversation
                </button>
            </div>
        </div>
    );
};

export default ConversationList;