import React, { useEffect, useRef, useState } from 'react';
import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { AppContext } from '../../contexts/AppContext';
import { Link, useNavigate, useParams } from 'react-router-dom';
import BeauticianInfo from '../../components/beautician/BeauticianInfo';
import ServicesList from '../../components/beautician/ServicesList';
import About from '../../components/beautician/About';
import './BeauticianProfile.css';
import beauticianApi from '../../api/beautician.api';
import { beauticiansSubCategoriesApi } from '../../api/beauticians_subcategories.api';
import availabilityApi from "../../api/availability.api";
import Swal from 'sweetalert2';
import Loader from '../../components/loader/Loader';
import { getBeauticianIdBySlug, groupPrestationsByCategoryId } from '../../utils/functions';
import reviewApi from '../../api/review.api';
import { X, Star, Plus, Gift, Calendar } from 'lucide-react';
import ratingApi from '../../api/rating.api';
import { Modal } from 'react-bootstrap';
import BookingContext from '../../context/BookingContext';
import { sitesApi } from '../../api/sites.api';
import { HeaderContext } from '../../contexts/HeaderContext';

const formatDate = (date) => {
  const targetDate = new Date(date);
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  return targetDate.toLocaleDateString('fr-FR', options);
};

const StarRating = ({ rating }) => {
  return (
    <div className="star-rating">
      {[1, 2, 3, 4, 5].map((star) => (
        <Star
          key={star}
          size={16}
          className={`star ${star <= rating ? 'filled' : ''}`}
          fill={star <= rating ? '#bd193b' : 'none'}
          stroke={star <= rating ? '#bd193b' : '#868e96'}
        />
      ))}
    </div>
  );
};

const ReviewModal = ({ connectedUser, isOpen, setOpen, reviews, selectedReviewIndex = 0, showAddReviewPanel }) => {
  const { setIsLoginModalOpen } = useContext(HeaderContext);

  const handleAddReviewPanel = () => {
    setOpen(false)
    showAddReviewPanel(true)
  }

  const handleReviewRedirect = () => {
    setOpen(false)
    setIsLoginModalOpen(true)
  }

  return (
    <Modal
      show={isOpen}
      onHide={() => setOpen(false)}
      centered
    >
      <div className="modal-overlay">
        <div className="modal-content bg-white">
          <button className="modal-close" onClick={() => setOpen(false)}>
            <X size={24} />
          </button>
          <div className="modal-reviews">
            <div className="flex items-center justify-between">
              <h2>Avis clients</h2>
              {(connectedUser && connectedUser?.user_type === 'client') &&
                <button
                  onClick={handleAddReviewPanel}
                  className="mb-4 px-3 py-2 flex align-center gap-2 text-sm text-white bg-[#bd193b] rounded"
                >
                  <Plus />
                  Ajouter un avis
                </button>}
              {!connectedUser &&
                <button
                  onClick={handleReviewRedirect}
                  className="mb-4 px-3 py-2 flex align-center gap-2 text-sm text-white bg-[#bd193b] rounded cursor-pointer no-underline"
                >
                  <Plus />
                  Ajouter un avis
                </button>
              }
            </div>
            <div className="modal-reviews-list">
              {reviews.length <= 0 ?
                (<h3 className="text-center text-gray-500">Il n'y a pas encore d'avis à afficher</h3>) :
                reviews.map((review, index) => (
                  <div
                    key={index}
                    className={`modal-review ${index === selectedReviewIndex ? 'highlighted' : ''}`}
                    id={index === selectedReviewIndex ? 'selected-review' : ''}
                  >
                    <div className="modal-review-header">
                      <div className="modal-review-info">
                        <StarRating rating={review.rating} />
                        <span className="reviewer-name">{review.commentateur}</span>
                      </div>
                      <span className="review-date">{formatDate(review.created_at)}</span>
                    </div>
                    <p className="review-content">{review.comment}</p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const BeauticianProfile = () => {
  const { connectedUser } = useContext(AppContext);

  const navigate = useNavigate();
  const { slug } = useParams();
  const { setActivePageForHeader } = useContext(AppContext);
  const {giveAGift, setGiveAGift} = useContext(BookingContext);

  const [id, setId] = useState(null);
  const [error, setError] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [beautician, setBeautician] = useState(null);
  const [prestations, setPrestations] = useState([]);
  const [socialLinks, setSocialLinks] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reloadTrigger, setReloadTrigger] = useState(false);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [showAddReviewPanel, setShowAddReviewPanel] = useState(false);

  const sectionPrestation = useRef(null);
  const [structuredData, setStructuredData] = useState(null);

  const defaultDays = [
    { day: "Lundi", field: "monday" },
    { day: "Mardi", field: "tuesday" },
    { day: "Mercredi", field: "wednesday" },
    { day: "Jeudi", field: "thursday" },
    { day: "Vendredi", field: "friday" },
    { day: "Samedi", field: "saturday" },
    { day: "Dimanche", field: "sunday" },
  ];

  // Fonction pour générer les données structurées
  const generateStructuredData = (beautician, prestations, schedule) => {
    if (!beautician || !prestations || !schedule) return null;

    const services = prestations.flatMap(category =>
      category.items.map(service => ({
        "@type": "Service",
        "name": service.name,
        "description": service.description || `Service de ${service.name}`,
        "offers": {
          "@type": "Offer",
          "price": service.price,
          "priceCurrency": "EUR"
        }
      }))
    );

    const openingHours = schedule
      .filter(s => s.start_time !== "-")
      .map(s => ({
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": s.day,
        "opens": s.start_time,
        "closes": s.end_time
      }));

    setStructuredData({
      "@context": "https://schema.org",
      "@type": "BeautySalon",
      "@id": `https://www.planibeauty.fr/beauticians/${slug}`,
      "url": `https://www.planibeauty.fr/beauticians/${slug}`,
      "name": beautician.business_name,
      "image": beautician.profile_photo,
      "description": beautician.description || `Services beauté à ${beautician.city}`,
      "telephone": beautician.phone,
      "email": beautician.email,
      "address": {
        "@type": "PostalAddress",
        "streetAddress": beautician.address,
        "addressLocality": beautician.city,
        "postalCode": beautician.postal_code,
        "addressCountry": "FR"
      },
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": beautician.latitude,
        "longitude": beautician.longitude
      },
      "hasOfferCatalog": {
        "@type": "OfferCatalog",
        "name": `Services beauté - ${beautician.business_name}`,
        "itemListElement": services
      },
      "aggregateRating": beautician.average_rating ? {
        "@type": "AggregateRating",
        "ratingValue": beautician.average_rating,
        "reviewCount": beautician.reviews_count || 0,
        "bestRating": "5",
        "worstRating": "1"
      } : undefined,
      "openingHoursSpecification": openingHours,
      "priceRange": beautician.budget_category === "economic" ? "€" :
        beautician.budget_category === "moderate" ? "€€" : "€€€"
    });
  };

  const fetchAvailabilities = async (beauticianId) => {
    try {
      const response = await availabilityApi.getBeauticianAvailability(beauticianId);
      if (response && Array.isArray(response)) {
        const mergedSchedule = defaultDays.map((defaultDay) => {
          const existingData = response.find(
            (item) => item.day.toLowerCase() === defaultDay.field
          );
          return {
            ...defaultDay,
            start_time: existingData?.start_time || "-",
            break_start: existingData?.break_start || "-",
            break_end: existingData?.break_end || "-",
            end_time: existingData?.end_time || "-",
          };
        });
        setSchedule(mergedSchedule);
        return mergedSchedule;
      } else {
        const default_schedule = defaultDays.map((day) => ({
          ...day,
          start_time: "-",
          break_start: "-",
          break_end: "-",
          end_time: "-",
        }));
        setSchedule(default_schedule);
        return default_schedule;
      }
    } catch (error) {
      console.error('Erreur lors du chargement des disponibilités:', error);
      Swal.fire("Erreur", "Impossible de charger les disponibilités.", "error");
      return null;
    }
  };

  const getSocialLinks = async (beautician_id) => {
    try {
      const socialLinkResponse = await sitesApi.getSiteByBeauticianId(beautician_id)

      setSocialLinks(socialLinkResponse.data[0].network_type ? socialLinkResponse.data : [])
    }
    catch (error) {
      console.log("Error occured: ", error.message)
    }
  }

  const getBeautician = async (beauticianId) => {
    try {
      const response = await beauticianApi.getBeauticianById(beauticianId);
      if (response.success) {
        setBeautician(response.data);
        return response.data;
      } else {
        setError('Erreur lors du chargement des données');
        return null;
      }
    } catch (error) {
      console.error('Erreur lors du chargement du prestataire:', error);
      setError(error.message);
      return null;
    }
  };

  const fetchPrestations = async (beauticianId) => {
    try {
      const req_prestations = await beauticiansSubCategoriesApi.getByBauticianId(beauticianId);
      const group_by_cat = groupPrestationsByCategoryId(req_prestations);
      setPrestations(group_by_cat);
      return group_by_cat;
    } catch (error) {
      console.error('Erreur lors du chargement des prestations:', error);
      setError("Erreur lors du chargement des prestations");
      return null;
    }
  };

  const getReviews = async (beauticianId) => {
    try {
      const result = await reviewApi.getBeauticianReview(beauticianId);

      if (result) {
        // Utilisation de Promise.all pour attendre la fin des appels asynchrones
        const reviewsWithRatings = await Promise.all(result.map(async (r) => {
          const average_rating = await ratingApi.getRatingByCommentId(r.id);

          return { ...r, rating: average_rating.data.average_rating };
        }));

        // Mettre à jour l'état avec les commentaires et les notes
        setReviews(reviewsWithRatings);
      }
    } catch (error) {
      console.error('Erreur lors du chargement des commentaires:', error);
      setError("Erreur lors du chargement des prestations");
      return null;
    }
  };

  useEffect(() => {
    document.body.setAttribute('data-page', 'search');
    setActivePageForHeader("search");

    return () => {
      document.body.removeAttribute('data-page');
      setActivePageForHeader("");
    }
  }, [setActivePageForHeader]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const beauticianId = await getBeauticianIdBySlug(slug);
        if (!beauticianId) {
          navigate("/404");
          return;
        }

        setId(beauticianId);
        const [_beautician, _prestations, _availabilities] = await Promise.all([
          getReviews(beauticianId),
          getBeautician(beauticianId),
          fetchAvailabilities(beauticianId),
          getSocialLinks(beauticianId),
          fetchPrestations(beauticianId),
        ]);
        generateStructuredData(_beautician, _prestations, _availabilities);
      } catch (error) {
        console.error('Erreur lors du chargement des données:', error);
        setError("Une erreur s'est produite lors du chargement des données.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [slug, navigate, reloadTrigger]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const checkIfShouldScroll = () => {
      const hash = window.location.href.split("#")[1];
      if (hash === "prestation") {
        const element = document.getElementById(hash);
        element?.scrollIntoView({ behavior: "smooth" });
      }
    };

    setTimeout(checkIfShouldScroll, 1000);
  }, []);

  const scrollToSection = (ref) => {
    ref?.current?.scrollIntoView({ behavior: "smooth" });
  };

  if (isLoading) {
    return <Loader isLoading={isLoading} />;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  // Génération des meta tags pour les réseaux sociaux
  const GetMetaTags = () => {
    if (!beautician) return null;

    const title = `${beautician.business_name} - Services beauté à ${beautician.city} | PlaniBeauty`;
    const description = `Réservez vos prestations beauté avec ${beautician.business_name} à ${beautician.city}. ${prestations.map(cat => cat.category_name).join(', ')
      }. ${beautician.average_rating ? `⭐ Note: ${beautician.average_rating}/5` : ''}`;

    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={`https://www.planibeauty.fr/beauticians/${slug}`} />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={`https://www.planibeauty.fr/beauticians/${slug}`} />
        <meta property="og:image" content={beautician.profile_photo} />
        <meta property="business:contact_data:street_address" content={beautician.address} />
        <meta property="business:contact_data:locality" content={beautician.city} />
        <meta property="business:contact_data:postal_code" content={beautician.postal_code} />
        <meta property="business:contact_data:country_name" content="France" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={beautician.profile_photo} />

        {/* Données structurées */}
        <script type="application/ld+json">
          {structuredData && JSON.stringify(structuredData)}
        </script>
      </Helmet>
    );
  };

  return (
    <>
      {beautician && <GetMetaTags />}
      <div className="beautician-profile-page mb-5">
        {beautician ? (
          <>
            <section className="profile-section">
              <BeauticianInfo
                id={id}
                reviews={reviews}
                beautician={beautician}
                prestations={prestations}
                shedule={schedule}
                openReviewModal={setIsReviewModalOpen}
                scrollToSection={(ref) => scrollToSection(ref)}
                sectionRefs={{ sectionPrestation }}
                showAddReviewPanel={showAddReviewPanel}
                setShowAddReviewPanel={setShowAddReviewPanel}
                setReloadTrigger={setReloadTrigger}
              />
            </section>

            {prestations.length > 0 &&
            <div>
              <div className="mt-8 flex items-center gap-8 border-b border-b-[#bd193b] mx-16">
                <span className={`pb-2 flex items-center gap-2 text-[#bd193b] text-xl duration-300 cursor-pointer ${giveAGift ? "font-normal mb-1" : "font-bold border-b-8 border-b-[#bd193b]"}`}
                  onClick={() => setGiveAGift(false)}
                >
                  <Calendar size={30} />
                  Prendre RDV
                </span>
                {/*
                <span
                  onClick={() => setGiveAGift(true)}
                  className={`pb-2 flex items-center gap-2 text-[#bd193b] text-xl duration-300 cursor-pointer ${giveAGift ? "font-bold border-b-8 border-b-[#bd193b]" : "font-normal mb-1"}`}
                >
                  <Gift size={32} />
                  Offrir
                </span>*/}
              </div>
              <section className="ServicesList-section" ref={sectionPrestation} id="prestation">
                <ServicesList beauticianPrestations={prestations} />
              </section>
            </div>}

              <section
                className="to-hide"
              >
                <About
                  id="about"
                  socialLinks={socialLinks}
                  text={beautician.description}
                />
              </section>

            <ReviewModal
              reviews={reviews}
              isOpen={isReviewModalOpen}
              connectedUser={connectedUser}
              setOpen={setIsReviewModalOpen}
              showAddReviewPanel={setShowAddReviewPanel}
            />
          </>
        ) : (
          <div>Profil non trouvé</div>
        )}
      </div>
    </>
  );
};

export default BeauticianProfile;