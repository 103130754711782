import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer home-footer bg-light py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <h5>Planibeauty</h5>
            <p className="text-muted">
            Votre plateforme de mise en relation avec les meilleurs professionnels de beauté partout en France.
            </p>
          </div>
          <div className="col-md-2">
            <h5>Navigation</h5>
            <ul className="list-unstyled">
              {[
                { path: 'concept', label: 'Le concept' },
                { path: 'search', label: 'Rechercher' }
              ].map((link, index) => (
                <li key={link.path} style={{'--i': index}}>
                  <Link to={`/${link.path}`}>{link.label}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-md-2">
            <h5>À propos</h5>
            <ul className="list-unstyled">
              {[
                { path: 'contact', label: 'Contact' },
                { path: 'ConditionsGenerales', label: 'Conditions Générales' }
              ].map((link, index) => (
                <li key={link.path} style={{'--i': index}}>
                  <Link to={`/${link.path}`}>{link.label}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-md-4">
            <h5>Newsletter</h5>
            <p className="text-muted">Restez informé de nos dernières actualités</p>
            <form className="d-flex">
              <input 
                type="email" 
                className="form-control me-2 mb-3" 
                placeholder="Votre email"
              />
              <button type="submit" className="btn btn-primary">
                S'inscrire
              </button>
            </form>
          </div>
        </div>
        <hr className="my-4" />
        <div className="row">
          <div className="col-12 text-center">
            <p className="text-muted mb-0">
              © {currentYear} Planibeauty. Tous droits réservés.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;