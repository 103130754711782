// src/components/loginModal/LoginModal.js
import React, { useState, useContext } from 'react';
import './LoginModal.css';
import logo from '../../assets/images/8.png';
import userApi from '../../api/user.api';
import { AppContext } from '../../contexts/AppContext';
import Swal from 'sweetalert2';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaX } from 'react-icons/fa6';
import PasswordField from '../common/PasswordField';

const LoginModal = ({
  isOpen,
  onClose,
  onSignupClick,
  onLoginSuccess,
  redirectAfterLogin = true // Nouveau prop avec valeur par défaut
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { setIsLoggedIn } = useContext(AppContext);

  const location = useLocation();
  const navigate = useNavigate();


  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const response = await userApi.login({
        email: email.trim(),
        password: password
      });

      if (response.token) {
        localStorage.setItem('token', response.token);
        localStorage.setItem('userId', response.userId);
        localStorage.setItem('userType', response.userType);

        try {
          const userProfile = await userApi.getProfile(response.token);
          localStorage.setItem('firstName', userProfile.first_name);
          localStorage.setItem('lastName', userProfile.last_name);
          localStorage.setItem('email', userProfile.email);

          setEmail('');
          setPassword('');

          // Appeler le callback de succès si fourni
          if (onLoginSuccess) {
            onLoginSuccess();
          }


          // Ne rediriger que si redirectAfterLogin est true
          if (redirectAfterLogin) {
            const baseUrl = window.location.origin;
            if (['admin', 'operator'].includes(response.userType)) {
              window.location.href = location.state?.from?.pathname || `${baseUrl}/dashboard`;
            } else {
              onClose();
              if (response.userType === "client") {
                window.location.href = location.state?.from?.pathname || `${baseUrl}/client-dashboard`;
              } else if (response.userType === "beautician") {
                window.location.href = location.state?.from?.pathname || `${baseUrl}/beautician-dashboard`;
              } else {
                window.location.reload();
              }
            }
          } else {
            Swal.fire({
              title: 'Chargement...',
              text: 'Veuillez patienter',
              allowOutsideClick: false, // Empêche de fermer en cliquant à l'extérieur
              didOpen: () => {
                Swal.showLoading(); // Affiche l'animation de chargement
              }
            });

            // Ferme l'alerte après 1 seconde
            setTimeout(() => {
              Swal.close();
              setIsLoggedIn(true);
              onClose();
            }, 1000);
          }
        } catch (profileError) {
          console.error('Erreur lors de la récupération du profil:', profileError);
        }
      }
    } catch (error) {
      setError(error.message || 'Email ou mot de passe incorrect');
    } finally {
      setIsLoading(false);
    }
  };
  if (!isOpen) return null;

  const logoStyles = {
    width: '100px',
    height: 'auto',
    display: 'block',
    margin: '0 auto'
  };

  return (
    <div className="login-modal-overlay login-modal" onClick={(e) => {
      if (e.target === e.currentTarget) onClose();
    }}>
      <div className="modal-content">
        <button
          className="modal-close-btn"
          onClick={onClose}
          aria-label="Fermer"
        >
          <FaX size={20} />
        </button>

        <div style={{
          width: '100%',
          textAlign: 'center',
          marginBottom: '1rem',
          marginTop: '-1rem'
        }}>
          <img
            src={logo}
            alt="Logo"
            style={logoStyles}
          />
        </div>

        <h2 className="modal-title">Connexion</h2>

        <form onSubmit={handleSubmit} className="login-form">
          {error && (
            <div className="error-message" style={{
              color: 'red',
              textAlign: 'center',
              marginBottom: '1rem'
            }}>
              {error}
            </div>
          )}

          <div className="form-group">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Adresse email"
              required
              disabled={isLoading}
            />
          </div>

          <div className="form-group">
            <PasswordField
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Mot de passe"
              required
              disabled={isLoading}
              label=""
            />
          </div>


          <div className="form-links">
            <Link to="/forgot-password" className="forgot-password">
              Mot de passe oublié ?
            </Link>
          </div>

          <button
            type="submit"
            className="login-button"
            disabled={isLoading}
          >
            {isLoading ? 'Connexion en cours...' : 'Se connecter'}
          </button>

          <div className="signup-link">
            Pas encore membre? {' '} Je suis un {' '}
            <button
              type="button"
              onClick={onSignupClick}
              className="link-button"
              disabled={isLoading}
            >
              Client
            </button>
            {' '} | {''}
            <button
              type="button"
              onClick={() => {navigate('/ajouter-etablissement'); onClose();}}
              className="link-button"
              disabled={isLoading}
            >
              Pro
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginModal;