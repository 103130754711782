import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';
import { Search, X, MapPin, Loader2 } from 'lucide-react';
import { AppContext } from '../../contexts/AppContext';
import debounce from 'lodash/debounce';
import './SearchBar.css';
import { searchCities } from '../../data/searchUtils';  // Ajoutez cette ligne en haut du fichier

const DEBOUNCE_MS = 200;
const MIN_SEARCH_LENGTH = 2;
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

const searchCache = new Map();



const SearchBar = () => {
  const navigate = useNavigate();
  const { beauticians, serviceCategories } = useContext(AppContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isLoading, setIsLoading] = useState({
    service: false,
    location: false
  });
  const [error, setError] = useState(null);
  const wrapperRef = useRef(null);
  const inputRef = useRef(null);
  const [locationTerm, setLocationTerm] = useState('');
  const [showLocationSuggestions, setShowLocationSuggestions] = useState(false);


  const [serviceSelectedIndex, setServiceSelectedIndex] = useState(-1);
  const [locationSelectedIndex, setLocationSelectedIndex] = useState(-1);

  const [selectedService, setSelectedService] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [serviceSuggestions, setServiceSuggestions] = useState([]);
  const [locationSuggestions, setLocationSuggestions] = useState([]);
 
  // Fermeture suggestions au clic extérieur
  // Fermeture suggestions au clic extérieur (un pour chaque type)
useEffect(() => {
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowSuggestions(false);
      setShowLocationSuggestions(false);
    }
  };
  document.addEventListener('mousedown', handleClickOutside);
  return () => document.removeEventListener('mousedown', handleClickOutside);
}, []);

  // Fonction de recherche avec cache
  const performSearch = useCallback(async (query) => {
    const cacheKey = query.toLowerCase();
    const now = Date.now();

    // Vérifier le cache
    if (searchCache.has(cacheKey)) {
      const { data, timestamp } = searchCache.get(cacheKey);
      if (now - timestamp < CACHE_DURATION) {
        return data;
      }
      searchCache.delete(cacheKey);
    }

    try {
      const params = new URLSearchParams();
      params.append('query', query);

      // Ajouter la géolocalisation si disponible
      if ('geolocation' in navigator) {
        try {
          const position = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject, {
              timeout: 5000,
              maximumAge: 300000
            });
          });
          params.append('lat', position.coords.latitude);
          params.append('lng', position.coords.longitude);
        } catch (error) {
          console.log('Géolocalisation non disponible');
        }
      }

      const response = await fetch(`/api/geo/search?${params.toString()}`);
      if (!response.ok) throw new Error('Erreur réseau');

      const data = await response.json();
      
      // Mettre en cache
      searchCache.set(cacheKey, {
        data,
        timestamp: now
      });

      return data;
    } catch (error) {
      throw new Error('Impossible de récupérer les suggestions');
    }
  }, []);

  // Debounce de la recherche
  const debouncedSearch = useCallback(
    debounce(async (value, type) => {
      if (value.length < MIN_SEARCH_LENGTH) {
        if (type === 'service') {
          setServiceSuggestions([]);
          setShowSuggestions(false);
        } else {
          setLocationSuggestions([]);
          setShowLocationSuggestions(false);
        }
        return;
      }
  
      if (type === 'service') {
        setIsLoading(prev => ({ ...prev, service: true }));
        try {
          const results = await performSearch(value);
          const formattedSuggestions = [
            ...(results.prestations || []),
            ...(results.subCategories || []),
            ...(results.beauticians || [])
          ];
          setServiceSuggestions(formattedSuggestions);
          setShowSuggestions(true);
        } catch (error) {
          console.error('Erreur recherche service:', error);
          setServiceSuggestions([]);
        } finally {
          setIsLoading(prev => ({ ...prev, service: false }));
        }
      } else {
        setIsLoading(prev => ({ ...prev, location: true }));
        try {
          const results = await searchCities(value);
          setLocationSuggestions(results);
          setShowLocationSuggestions(true);
        } catch (error) {
          console.error('Erreur recherche ville:', error);
          setLocationSuggestions([]);
        } finally {
          setIsLoading(prev => ({ ...prev, location: false }));
        }
      }
    }, DEBOUNCE_MS),
    []
  );

  // Dans SearchBar.js, modifiez la fonction handleSearch
  const handleSearch = (searchValue, type, params = {}) => {
    const searchParams = new URLSearchParams();
    
    switch(type) {
        case 'prestation':
            if (params.categoryName) {  // C'est une sous-catégorie
                // Utiliser params.id directement pour la sous-catégorie
                searchParams.append('subcategoryId', params.id);
            } else {
                searchParams.append('categoryId', params.id);
            }
            break;
        case 'ville':
            if (params.coordinates) {
                searchParams.append('lat', params.coordinates[0]);
                searchParams.append('lng', params.coordinates[1]);
            }
            searchParams.append('city', searchValue);
            break;
        case 'business':
            searchParams.append('beautician', params.id);
            break;
        default:
            searchParams.append('q', searchValue);
    }

    navigate({
        pathname: '/search',
        search: searchParams.toString()
    });
  };

  const handleKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setServiceSelectedIndex(prev => 
          prev < serviceSuggestions.length - 1 ? prev + 1 : prev
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setServiceSelectedIndex(prev => prev > -1 ? prev - 1 : -1);
        break;
      case 'Enter':
        e.preventDefault();
        if (serviceSelectedIndex > -1) {
          handleServiceSelect(serviceSuggestions[serviceSelectedIndex]);
        }
        break;
      case 'Escape':
        e.preventDefault();
        setShowSuggestions(false);
        break;
      default:
        break;
    }
  };
  
  const handleLocationKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setLocationSelectedIndex(prev => 
          prev < locationSuggestions.length - 1 ? prev + 1 : prev
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setLocationSelectedIndex(prev => prev > -1 ? prev - 1 : -1);
        break;
      case 'Enter':
        e.preventDefault();
        if (locationSelectedIndex > -1) {
          handleLocationSelect(locationSuggestions[locationSelectedIndex]);
        }
        break;
      case 'Escape':
        e.preventDefault();
        setShowLocationSuggestions(false);
        break;
      default:
        break;
    }
  };
  

  const handleServiceSelect = (suggestion) => {
    setSelectedService(suggestion);
    setSearchTerm(suggestion.text);
    setShowSuggestions(false);
  };
  
  const handleLocationSelect = (suggestion) => {
    setSelectedLocation(suggestion);
    setLocationTerm(suggestion.text);
    setShowLocationSuggestions(false);
  };

  // À ajouter
  const handleSearchClick = () => {
    const searchParams = new URLSearchParams();
    
    if (selectedService) {
      if (selectedService.type === 'prestation') {
        if (selectedService.categoryName) {
          searchParams.append('subcategoryId', selectedService.id);
        } else {
          searchParams.append('categoryId', selectedService.id);
        }
      } else if (selectedService.type === 'business') {
        searchParams.append('beautician', selectedService.id);
      }
    }
  
    if (selectedLocation) {
      if (selectedLocation.coordinates) {
        searchParams.append('lat', selectedLocation.coordinates[0]);
        searchParams.append('lng', selectedLocation.coordinates[1]);
      }
      searchParams.append('city', selectedLocation.text);
    }
  
    navigate({
      pathname: '/search',
      search: searchParams.toString()
    });
  };

  const clearSearch = (type) => {
    if (type === 'service') {
      setSearchTerm('');
      setServiceSuggestions([]); // Modifié ici
      setShowSuggestions(false);
      inputRef.current?.focus();
    } else {
      setLocationTerm('');
      setShowLocationSuggestions(false);
    }
  };
  // Rendu du composant
  return (
    <div className="search-bar-wrapper" ref={wrapperRef}>
      <div className="search-bar-container">
        <div className="search-input-group">
          {/* Premier input pour services/établissements */}
          <div className="search-input-section">
            <Search size={20} />
            <input
              ref={inputRef}
              type="text"
              className="search-input"
              placeholder="un service, un professionnel..."
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                debouncedSearch(e.target.value, 'service');
                setShowSuggestions(true); // Ajouter cette ligne
              }}
              onFocus={() => {
                if (searchTerm.length >= MIN_SEARCH_LENGTH) {
                  setShowSuggestions(true);
                }
              }}
              onKeyDown={handleKeyDown}
            />
            {searchTerm && (
              <button
                className="clear-button"
                onClick={() => clearSearch('service')}
                aria-label="Effacer la recherche"
              >
                <X size={16} />
              </button>
            )}
          </div>

          {/* Deuxième input pour les villes */}
          <div className="search-input-section">
            <MapPin size={20} />
            <input
              type="text"
              className="search-input"
              placeholder="Saisissez une ville..."
              value={locationTerm}
              onChange={(e) => {
                setLocationTerm(e.target.value);
                debouncedSearch(e.target.value, 'location');
                setShowLocationSuggestions(true); // Ajouter cette ligne
              }}
              onFocus={() => {
                if (locationTerm.length >= MIN_SEARCH_LENGTH) {
                  setShowLocationSuggestions(true);
                }
              }}
              onKeyDown={handleLocationKeyDown}
            />
            {locationTerm && (
              <button
                className="clear-button"
                onClick={() => clearSearch('location')}
                aria-label="Effacer la recherche"
              >
                <X size={16} />
              </button>
            )}
          </div>

          {/* Bouton de recherche unique */}
          <button 
            className="search-button" 
            onClick={handleSearchClick}
          >
            <Search size={24} color="white"/>
          </button>
        </div>

        {/* Suggestions pour services */}
        {showSuggestions && searchTerm.length >= MIN_SEARCH_LENGTH && (
        <div className="suggestions-dropdown service-suggestions">
          {isLoading.service ? (
            <div className="suggestion-loading">
              <Loader2 className="animate-spin" size={20} />
              <span>Recherche en cours...</span>
            </div>
          ) : serviceSuggestions.length > 0 ? (
              serviceSuggestions.map((suggestion, index) => (
                <div
                  key={`${suggestion.type}-${index}`}
                  className={`suggestion-item ${serviceSelectedIndex === index ? 'selected' : ''}`}
                  onClick={() => handleServiceSelect(suggestion)}
                >
                  <div className="suggestion-content">
                    <span className="suggestion-text">{suggestion.text}</span>
                    {suggestion.subtext && (
                      <span className="suggestion-subtext">
                        {suggestion.subtext}
                      </span>
                    )}
                    {suggestion.distance && (
                      <span className="suggestion-distance">
                        <MapPin size={14} />
                        {Math.round(suggestion.distance/1000)}km
                      </span>
                    )}
                  </div>
                  <span className={`suggestion-type ${suggestion.type}`}>
                    {suggestion.typeLabel}
                  </span>
                </div>
              ))
            ) : (
              <div className="suggestion-empty">Aucun résultat trouvé</div>
            )}
          </div>
        )}

        {/* Suggestions pour villes */}
        {showLocationSuggestions && locationTerm.length >= MIN_SEARCH_LENGTH && (
        <div className="suggestions-dropdown location-suggestions">
          {isLoading.location ? (
            <div className="suggestion-loading">
              <Loader2 className="animate-spin" size={20} />
              <span>Recherche en cours...</span>
            </div>
          ) : locationSuggestions.length > 0 ? (
              locationSuggestions.map((suggestion, index) => (
                <div
                  key={`${suggestion.type}-${index}`}
                  className={`suggestion-item ${locationSelectedIndex === index ? 'selected' : ''}`}
                  onClick={() => handleLocationSelect(suggestion)}
                >
                  <div className="suggestion-content">
                    <span className="suggestion-text">{suggestion.text}</span>
                    {suggestion.subtext && (
                      <span className="suggestion-subtext">
                        {suggestion.subtext}
                      </span>
                    )}
                  </div>
                  <span className={`suggestion-type ${suggestion.type}`}>
                    {suggestion.typeLabel}
                  </span>
                </div>
              ))
            ) : (
              <div className="suggestion-empty">Aucune ville trouvée</div>
            )}
          </div>
        )}
      </div>
    </div>
);
};

export default SearchBar;