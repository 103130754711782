import React, { useState } from "react";
import "./SignupModal.css";
import logo from "../../assets/images/8.png";
import userApi from "../../api/user.api";
import { showSuccessMessage } from "../../utils/messageHelper";
import PasswordField from "../common/PasswordField";
import { BASE_URL } from "../../utils/constants";

const SignupModal = ({
  isOpen,
  onClose,
  onLoginClick,
  onSignupSuccess,
  redirectAfterSignup = true,
}) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    password: "",
    confirmPassword: "",
    agree_terms: false,
  });

  const [error, setError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [agreeTermsError, setAgreeTermsError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSignupSuccess = async (response) => {
    localStorage.setItem("token", response.token);
    localStorage.setItem("userId", response.userId);
    localStorage.setItem("userType", response.userType);

    if (onSignupSuccess) {
      onSignupSuccess();
    }

    if (redirectAfterSignup) {
      const baseUrl = window.location.origin;
      if (["admin", "operator"].includes(response.userType)) {
        window.location.href = `${baseUrl}/dashboard`;
      } else {
        onClose();
        window.location.reload();
      }
    } else {
      onClose();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "confirmPassword" || name === "password") {
      if (name === "confirmPassword" && value !== formData.password) {
        setPasswordError("Les mots de passe ne correspondent pas");
      } else if (
        name === "password" &&
        value !== formData.confirmPassword &&
        formData.confirmPassword
      ) {
        setPasswordError("Les mots de passe ne correspondent pas");
      } else {
        setPasswordError("");
      }
    }
  };

  const resetForm = () => {
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address: "",
      password: "",
      confirmPassword: "",
      agree_terms: false,
    });
    setError("");
    setPasswordError("");
    setAgreeTermsError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      setPasswordError("Les mots de passe ne correspondent pas");
      return;
    }

    if (formData.password.length < 6) {
      setPasswordError("Le mot de passe doit contenir au moins 6 caractères");
      return;
    }

    if(!formData.agree_terms) {
      setAgreeTermsError("Vous devez accepter les termes et conditions pour continuer");
      return;
    }

    setIsLoading(true);
    setError("");
    setAgreeTermsError("");

    try {
      const userData = {
        first_name: formData.firstName.trim(),
        last_name: formData.lastName.trim(),
        email: formData.email.trim().toLowerCase(),
        phone: formData.phone.trim(),
        password: formData.password,
        user_type: "client",
      };

      const response = await userApi.registerTemporaryUser(userData);

      if (response.success) {
        resetForm();
        setIsLoading(false);
        showSuccessMessage(
          response.message
        );
      } else {
        setError(
          response.message || "Une erreur est survenue lors de l'inscription"
        );
      }
    } catch (error) {
      setError(
        error.message || "Une erreur est survenue lors de l'inscription"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    resetForm();
    if (onClose) {
      onClose();
    }
  };

  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay signup-modal" onClick={handleClose}>
      <div className="modal-content signup-content" onClick={handleContentClick}>
        {isLoading && (
          <div className="loading-overlay">
            <div className="loading-spinner"></div>
          </div>
        )}

        <button
          className="modal-close"
          onClick={handleClose}
          type="button"
          aria-label="Fermer"
          disabled={isLoading}
        >
          ×
        </button>

        <div className="modal-logo">
          <img src={logo} alt="Logo" />
        </div>

        <h2 className="modal-title">Créer un compte</h2>

        {error && (
          <div className="error-message global-error">{error}</div>
        )}

        <form onSubmit={handleSubmit} className="signup-form">
          {/* Autres champs inchangés */}
          <div className="form-row">
            <div className="form-group">
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                placeholder="Nom"
                required
                disabled={isLoading}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                placeholder="Prénom"
                required
                disabled={isLoading}
              />
            </div>
          </div>

          <div className="form-group">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Adresse email"
              required
              disabled={isLoading}
            />
          </div>

          <div className="form-group">
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Téléphone"
              required
              disabled={isLoading}
              pattern="[0-9]{10}"
              title="Veuillez entrer un numéro de téléphone valide (10 chiffres)"
            />
          </div>

          {/* Champs de mot de passe */}
          <PasswordField
            label="Mot de passe"
            value={formData.password}
            onChange={(e) =>
              handleChange({ target: { name: "password", value: e.target.value } })
            }
            className="form-group"
          />

          <PasswordField
            label="Confirmer le mot de passe"
            value={formData.confirmPassword}
            onChange={(e) =>
              handleChange({
                target: { name: "confirmPassword", value: e.target.value },
              })
            }
            className="form-group"
          />

          {passwordError && (
            <div className="error-message">{passwordError}</div>
          )}

          <div className="flex gap-3 items-center">
              <input
                type="checkbox"
                name="agree_terms"
                id="agree_terms"
                checked={formData.agree_terms}
                onChange={(e) =>
                  handleChange({
                    target: { name: "agree_terms", value: e.target.checked },
                  })
                }
                disabled={isLoading}
              />
            <label htmlFor="agree_terms">
              J'accepte les{" "}
              <a href={`${process.env.NODE_ENV === 'production' ? BASE_URL : 'http://localhost:3000'}/conditionsGenerales`} rel="noreferrer" target="_blank">
                termes et conditions d'utilisation
              </a>
            </label>
          </div>

          {agreeTermsError && (
            <div className="error-message">{agreeTermsError}</div>
          )}

          <button
            type="submit"
            className="signup-button"
            disabled={isLoading}
          >
            {isLoading ? "Inscription en cours..." : "S'inscrire"}
          </button>

          <div className="login-link">
            Déjà membre ?{" "}
            <button
              type="button"
              onClick={onLoginClick}
              className="link-button"
              disabled={isLoading}
            >
              Se connecter
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignupModal;