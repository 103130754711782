import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import './Layout.css';

const Layout = ({ children }) => {
  const location = useLocation();
  const isMessagingPage = location.pathname === '/messaging';
  const isClientDashboard = location.pathname.startsWith('/client-dashboard');

  return (
    <div className="layout">
      <Header />
      <main className="main-content">
        {children}
      </main>
      {!isMessagingPage && !isClientDashboard && <Footer />}
    </div>
  );
};

export default Layout;