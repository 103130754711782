import React from 'react';
import { Modal} from 'react-bootstrap';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { X } from 'lucide-react';
import { STRIPE_PUBLISHABLE_KEY } from '../../utils/constants';
import './EstablishmentForm.css';
import CreateEtabForm from './CreateEtabForm';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const CreateEtabModal = ({ selectedType, showCreateEtabModal, setShowCreateEtabModal }) => {

    const handleClose = () => setShowCreateEtabModal(false);

    return (
        <Modal
            show={showCreateEtabModal}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            className="establishment-modal"
            centered
        >
            <div className="form-wrapper">
                <button className="close-button" onClick={() => setShowCreateEtabModal(false)}>
                    <X size={24} />
                </button>

                <Elements stripe={stripePromise}>
                    <CreateEtabForm selectedType={selectedType} setShowCreateEtabModal={setShowCreateEtabModal} />
                </Elements>
            </div>
        </Modal>
    );
};

export default CreateEtabModal;