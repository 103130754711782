import { API_URL } from "../utils/constants";

const testeurApi = {
    getAll: async (token) => {
        try {
            const response = await fetch(`${API_URL}/testeur/all`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la récupération des testeurs');
        }
    },
    create: async(token, data) => {
        try {
            const response = await fetch(`${API_URL}/testeur/create`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la création du testeur');
        }
    },
    delete: async(token, id) => {
        try {
            const response = await fetch(`${API_URL}/testeur/${id}/delete`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la suppression du testeur');
        }
    },

    is_user_testeur: async (token, user_id) => {
        try {
            const response = await fetch(`${API_URL}/testeur/user/${user_id}/is-testeur`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            let res = await response.json();
            return res.is_testeur;
        } catch (error) {
            throw new Error('Erreur lors de la verification des testeurs');
        }
    }
}

export default testeurApi;